import React, { useState, useEffect } from 'react';
import queryString from 'query-string';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import ServiceMachineStatistics from '../ServiceMachineStatistics';
import MachineRepairsStatistics from '../MachineRepairsStatistics';
import DieselStatistics from '../DieselStatistics';
import { pushToHistory } from '../../utils/base';
import { useTranslation } from 'react-i18next';
import * as style from './index.module.css';
import classNames from 'classnames'

const Statistics = ({ machineId }) => {
  const { t } = useTranslation()

  const parsedQuery = queryString.parse(location.search)
  const [activeTab, setActiveTab] = useState(parsedQuery.secondaryTab || 'machine_statistics')

  useEffect(() => {
    pushToHistory({ tab: 'statistics', secondaryTab: activeTab })
  }, [activeTab])

  const filters = [
    {
      label: t("active"),
      attribute: "active",
      select: true,
      include_all: true,
      options: [["1", t("active")], ["0", t("inactive")]]
    }
  ]

  return (
    <Tabs
      onSelect={(k) => {
        setActiveTab(k)
        pushToHistory({ secondaryTab: k })
      }}
      defaultActiveKey={activeTab}
      id="machine-statistics-tabs"
    >
      <Tab
        eventKey="machine_statistics"
        title={<span className={classNames(activeTab === 'machine_statistics' ? style['tab-selected'] : style['tab-unselected'])}>{t('machine_statistics')}</span>}
        mountOnEnter={true}
      >
        <ServiceMachineStatistics filters={filters} machineId={machineId} tab="statistics" />
      </Tab>
      <Tab
        eventKey="repairs_statistics"
        title={<span className={classNames(activeTab === 'repairs_statistics' ? style['tab-selected'] : style['tab-unselected'])}>{t('repairs_statistics')}</span>}
        mountOnEnter={true}
      >
        <MachineRepairsStatistics machineId={machineId} />
      </Tab>
      <Tab
        eventKey="diesel_statistics"
        title={<span className={classNames(activeTab === 'diesel_statistics' ? style['tab-selected'] : style['tab-unselected'])}>{t('diesel_statistics')}</span>}
        mountOnEnter={true}
      >
        <DieselStatistics machineId={machineId} />
      </Tab>
    </Tabs>
  )
};

export default Statistics;
