import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {axiosInstance, routes} from '../../utils/api_base';
import { Chart } from "react-google-charts";
import * as style from './index.module.css';

const CostStructureChart = ({order}) => {
  const { t } = useTranslation()
  const [orderData, setOrderData] = useState([]);
  const getData = (orderId) => {
    axiosInstance.get(routes.orderCostStructure(orderId))
      .then(response => {
        setOrderData(response.data)
      })
      .catch(error => {
        console.log('error', error)
      })
  }

  useEffect(() => {
    getData(order.id);
  }, []);

  const chartData = orderData.map(item => {
    return [item.name, {v: item.value, f: `${item.value?.toFixed(2)} ${item.currency}`}]
  });

  chartData.unshift(['Cost category', 'Amount of money'])

  const legendHtml = () => {
    return(
      <table className="table align-middle table-row-dashed fs-6 gy-5 dataTable">
        <tbody className="fw-semibold text-gray-600">
        {orderData.filter(item => item.value !== 0).map((item, index) => (
          <tr key={index}>
            <td>
              <div style={{ backgroundColor: item.color }} className={style['color-block']}>
              </div>
            </td>
            <td>
              <span className="legend-symbol bg-light me-2"/>
              {item.name}
            </td>
            <td>
              { `${item.value?.toFixed(2)} ${item.currency}` }
            </td>
          </tr>
        ))}
        </tbody>
      </table>
    )
  }

  const options = {
    title: t('costs_structure'),
    colors: orderData.map(item => item.color),
    legend: 'none',
  };
  return (
    <div>
      <Chart
        chartType="PieChart"
        data={chartData}
        options={options}
        width={"100%"}
        height={"400px"}
      />
      {legendHtml()}
    </div>
  );
};

export default CostStructureChart;
