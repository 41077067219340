import { QuestionIcon } from '../../Icons';
import React, { useEffect, useState } from 'react';
import TableButton from '../../Table/TableButton';
import { ExportIcon } from '../../../components/Icons';
import { axiosInstance, routes } from '../../../utils/api_base';
import { useTranslation } from 'react-i18next';

const DieselBook = ({ businessPartnerId }) => {
  const { t } = useTranslation()
  const [books, setBooks] = useState([])

  useEffect(() => {
    getBooks()
  }, []);

  const getBooks = async () => {
    let params = { business_partner_id: businessPartnerId }
    const res = await axiosInstance.get(routes.dieselBooks(), { params })
    setBooks(res?.data?.data)
  }

  const CustomizingHtml = () => {
    return (
      <>
        <a href='/customizings#kunden'>
          {t('diesel_book_setting')}
          <span
            data-bs-toggle="tooltip"
            data-bs-custom-class="tooltip-inverse"
            data-bs-placement="top"
            title={t('diesel_book_setting_title')}
            className='btn btn-sm p-2'
          >
            <QuestionIcon className='text-warning fs-2' />
          </span>
        </a>
      </>
    )
  }

  return (
    <>
      {CustomizingHtml()}
      <div className="card">
        <div className='card-body'>
          {books && books.length > 0 ?
            books.map((book) => (
              <TableButton
                key={book}
                href={`/business_partners/${businessPartnerId}/diesel_book.pdf?year=${book}`}
                type="button"
                target='_blank'
                className="btn-light-warning me-3 mb-1"
                icon={<ExportIcon />}
                title={`${t('diesel_book')} ${book}`}
              />
            )
            ) : (
              <div className='fs-3 text-gray-500'>{t('no_orders')}</div>
            )}
        </div>
      </div>
    </>
  )
}

export default DieselBook;
