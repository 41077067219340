import React, { useEffect, useState } from 'react';
import {axiosInstance, routes} from '../../utils/api_base';
import { Chart } from "react-google-charts";

const ContributionMarginChart = ({order}) => {
  const [orderData, setOrderData] = useState({});
  const getData = (orderId) => {
    axiosInstance.get(routes.orderContributionMargin(orderId))
      .then(response => {
        setOrderData(response.data)
      })
      .catch(error => {
        console.log('error', error)
      })
  }

  useEffect(() => {
    getData(order.id);
  }, []);


  const options = {
    title: "Contribution margin",
    width: 400,
    height: 400,
    min: orderData?.min,
    max: orderData?.max,
    redFrom: orderData?.min,
    redTo: 0,
    yellowFrom: 0,
    yellowTo: 10,
    greenFrom: 10,
    greenTo: orderData?.max,
    minorTicks: 5,
  };

  const chartData = [
    ["Label", "Value"],
    ["Rendite", orderData?.value],
  ]

  return (
    <div>
      <Chart
        chartType="Gauge"
        width="100%"
        height="100%"
        data={chartData}
        options={options}
      />
    </div>
  );
};

export default ContributionMarginChart;
