import React from 'react';
import * as actionTypes from '../constants/actions';

export const initialState = {
  currentUser: {},
  currentCustomizing: {},
  statuses: [],
  isFetching: false,
  isSaving: false,
  locale: null,
};

export default function commentsReducer(state = initialState, action = null) {
  const { type, locale } = action;

  switch (type) {
    case actionTypes.UPDATE_CURRENT_CUSTOMIZING: {
      return {
        ...state,
        currentCustomizing: {
          ...state.currentCustomizing,
          ...action.payload,
        },
      };
    }

    case actionTypes.SET_IS_FETCHING: {
      return state.merge({
        isFetching: true,
      });
    }

    case actionTypes.SET_IS_SAVING: {
      return state.merge({
        isSaving: true,
      });
    }

    case actionTypes.SET_LOCALE: {
      return state.merge({
        locale,
      });
    }

    default: {
      return state;
    }
  }
}
