import React, { useState, useCallback } from 'react'
import { Form, Field } from 'react-final-form'
import { CheckSquareIcon, CrossSquareIcon, EditNotepadIcon } from '../Icons';
import * as style from './index.module.css';
import classNames from 'classnames';
import AsyncSelectAdapter from '../Form/AsyncSelectAdapter';
import debounce from "lodash/debounce";

// TODO: Add close callback after submiting and editableAfterSubmit is false
const EditableSelectInput = ({
  id,
  isMulti,
  attribute,
  initialValue,
  handleSubmit,
  placeholder,
  getEntities,
  fieldStyle = "",
  editableAfterSubmit = true,
  returnObject,
  isClearable = true,
  editEnabled = false,
  closeSelectModal = () => { },
}) => {
  const [editable, setEditable] = useState(editEnabled);
  const [isHovered, setIsHovered] = useState(false);
  const [optionsEmpty, setOptionsEmpty] = useState(false);

  const onSubmit = async values => {
    setEditable(false)
    if (returnObject) { return handleSubmit(id, { [attribute]: values[attribute] }) }

    const selected = isMulti
      ? values[attribute]?.map(option => option.value) || []
      : values[attribute] ? { [attribute]: values[attribute].value } : null;

    return handleSubmit(id, selected)
  }

  const loadOptions = useCallback(
    debounce((inputText, callback) => {
      getEntities(inputText, id).then((options) => {
        setOptionsEmpty(options.length === 0);
        callback(options)
      });
    }, 1000), [])

  const inputHtml = () => {
    return (
      <Form
        onSubmit={onSubmit}
        initialValues={{ [attribute]: initialValue || '' }}
        render={({ handleSubmit, submitting, pristine }) => (
          <form
            onSubmit={() => {
              closeSelectModal(false)
              handleSubmit()
            }}
          >
            <div className={style.form}>
              <Field
                name={attribute}
                component={AsyncSelectAdapter}
                loadOptions={loadOptions}
                placeholder={placeholder || attribute}
                isMulti={isMulti}
                isClearable={isClearable}
                className={fieldStyle}
              />
              <div className={style['form-buttons']}>
                <button type="submit" disabled={submitting || pristine}>
                  <CheckSquareIcon className={classNames("fs-3 text-success", { "text-gray-500": submitting || pristine })} />
                </button>
                <button
                  type="button"
                  onClick={() => {
                    setEditable(false)
                    closeSelectModal(false)
                  }}
                  disabled={submitting}
                >
                  <CrossSquareIcon className="fs-3 text-danger" />
                </button>
              </div>
            </div>
          </form>
        )}
      />
    )
  }

  const viewHtml = () => {
    const initialText =
      Array.isArray(initialValue) && initialValue.length > 0 ?
        initialValue.map((item) => item.label).join(', ') :
        initialValue?.label || 'N/A';

    return (
      <div
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        onClick={() => setEditable(true)}
      >
        {initialText}
        {
          isHovered && <EditNotepadIcon />
        }
      </div >
    )
  }

  return (
    <>
      {editable ? inputHtml() : viewHtml()}
    </>
  )
}

export default EditableSelectInput
