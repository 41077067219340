import React, { useMemo } from 'react';
import { axiosInstance, routes } from '../../utils/api_base';
import { useTranslation } from 'react-i18next';
import { pushToHistory } from '../../utils/base';
import BaseTable from '../../components/Table';
import TableButton from '../../components/Table/TableButton';
import { isEmpty } from 'lodash';

const Table = ({ filters, tableToolbar }) => {
  const { t, i18n } = useTranslation()

  const getVendors = async (pagination, sorting, globalFilter, customFilters) => {
    const { pageIndex, pageSize } = pagination
    const sortQuery = sorting.map((attr) => {
      return attr.desc ? `${attr.id} desc` : attr.id
    })
    let params = { page: pageIndex + 1, limit: pageSize, sort: sortQuery[0] }
    if (globalFilter) {
      params.query = globalFilter
    }
    if (!isEmpty(customFilters)) {
      params = { ...params, ...customFilters }
    }
    const res = await axiosInstance.get(routes.vendors(), { params: params })
    pushToHistory(params)

    return (
      { rows: res.data.data, pageCount: res.data.meta.total_pages, rowCount: res.data.meta.total_count }
    )
  }

  const codeHtml = (row) => {
    return (
      <TableButton
        target="_blank"
        href={row?.original?.attributes?.link}
        className="btn-light-warning me-3"
        title={row?.original?.attributes?.client_specific_number}
      />
    )
  }

  const columns = useMemo(
    () => [
      {
        id: 'code',
        header: t('business_partners.supplier_number'),
        accessorFn: row => row?.attributes?.client_specific_number,
        size: 70,
        minSize: 70,
        enableSorting: true,
        cell: ({ row }) => {
          return codeHtml(row)
        },
      },
      {
        id: 'name',
        accessorFn: row => row?.attributes?.name,
        header: t('shared.name'),
        cell: row => row.getValue(),
      },
      {
        id: 'city',
        accessorFn: row => row?.attributes?.city,
        header: t('shared.city'),
        cell: row => row.getValue(),
      },
      {
        id: 'contact',
        accessorFn: row => row?.attributes?.contact_name,
        header: t('shared.contact_person'),
        cell: row => row.getValue(),
      },
    ],
    [i18n.language]
  )

  return (
    <BaseTable
      columns={columns}
      columnVisibility={{}}
      getDataFn={getVendors}
      tableToolbar={tableToolbar}
      filters={filters}
    />
  )
}

export default Table
