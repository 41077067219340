import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import TableButton from '../../Table/TableButton';
import { PlusIcon, ExportIcon } from '../../Icons';
import Table from './Table';
import { axiosInstance, routes } from '../../../utils/api_base';
import Toastr from '../../../components/Toastr';

const ContactsTable = ({ businessPartnerId }) => {
  const { t } = useTranslation()
  const [indexTrigger, setIndexTrigger] = useState(false)

  const tableToolbar = () => {
    return (
      <>
        <TableButton
          type="button"
          className="btn-light-warning me-3"
          href={`/business_partners/${businessPartnerId}/vcards.vcf`}
          icon={<ExportIcon />}
          title={t('shared.export')}
        />
        <TableButton
          className="btn-warning"
          onClick={() => createContact()}
          icon={<PlusIcon />}
          title={t('shared.add_to')}
        />
      </>
    )
  }

  const createContact = () => {
    axiosInstance.post(routes.contacts(), { business_partner_id: businessPartnerId })
      .then(resp => {
        Toastr({ message: t('success'), options: { showDuration: 2000 } })
        setIndexTrigger(Math.floor(Date.now() / 1000)) // Dirty hack to refresh the table
      })
      .catch(error => {
        Toastr({
          type: 'error',
          message: error.response?.data?.error || t('critical_error')
        })
      })
  }

  return (
    <Table tableToolbar={tableToolbar} businessPartnerId={businessPartnerId} indexTrigger={indexTrigger} />
  )
}

export default ContactsTable;
