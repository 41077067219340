import React, { useEffect, useCallback, useState } from "react";
import { Tooltip } from 'bootstrap'
import moment from 'moment'
import { useTranslation } from 'react-i18next';
import AsyncSelectAdapter from '../../Form/AsyncSelectAdapter';
import ErrorField from '../../Form/ErrorField';
import { Form, Field, FormSpy } from 'react-final-form'
import debounce from 'lodash/debounce';
import { axiosInstance, routes } from '../../../utils/api_base';
import DateRangePickerAdapter from '../../Form/DateRangePickerAdapter';
import TableButton from "../../Table/TableButton";

const StaffOrderForm = ({ staffOrder, staffOrdersRef, onSave }) => {
    const { t, i18n } = useTranslation()

    const [combinations, setCombinations] = useState([])
    const [selectedCombination, setSelectedCombination] = useState(null)

    const defaultCombination = selectedCombination ? { value: selectedCombination.id, label: selectedCombination.attributes.long_name } : t('combination')
    const defaultDriver = selectedCombination ? { value: selectedCombination.attributes.staff_id?.toString(), label: selectedCombination.attributes?.staff_long_name } : t('driver')
    const defaultVehicle = selectedCombination ? { value: selectedCombination.attributes.fahrzeug_id.toString(), label: selectedCombination.attributes.machine_long_name } : t('machine')
    const defaultGeraet1 = selectedCombination ? { value: selectedCombination.attributes.geraet1_id?.toString(), label: selectedCombination.attributes?.geraet_1_long_name } : t('geraet_1')
    const defaultGeraet2 = selectedCombination ? { value: selectedCombination.attributes.geraet2_id?.toString(), label: selectedCombination.attributes?.geraet_2_long_name } : t('geraet_2')

    useEffect(() => {
        const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]')
        const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new Tooltip(tooltipTriggerEl))
    }, []);

    const getCombinations = (inputText) => {
        let params = { page: 1, limit: 20, active: true, query: inputText }

        return axiosInstance.get(routes.combinations(), { params: params })
            .then((response) => {
                setCombinations(response.data.data)

                return response.data.data.map((s) => ({
                    value: s.id,
                    label: s.attributes.long_name,
                }));
            })
            .catch((error) => {
                console.log('ERROR', error)
            });
    };

    const getStaffs = (inputText) => {
        let params = { page: 1, limit: 20, active: true, query: inputText, show_in_dispo: true, without_admin_and_farmer: true }

        return axiosInstance.get(routes.staffs(), { params: params })
            .then((response) => {
                return response.data.data.map((s) => ({
                    value: s.id,
                    label: s.attributes.long_name,
                }));
            })
            .catch((error) => {
                console.log('ERROR', error)
            });
    };

    const getMachines = (inputText, fahrzeug = false, geraet = false) => {
        let params = { page: 1, limit: 20, active: true, serialize: 'short', query: inputText, show_in_dispo: true, fahrzeug, geraet }

        return axiosInstance.get(routes.machines(), { params: params })
            .then((response) => {
                return response.data.data.map((v) => ({
                    value: v.id,
                    label: v.attributes.long_name,
                }));
            })
            .catch((error) => {
                console.log('ERROR', error)
            });
    };

    const getServices = (inputText) => {
        let params = { page: 1, limit: 20, active: true, serialize: 'short', query: inputText }
        return axiosInstance.get(routes.services(), { params: params })
            .then((response) => {
                return response.data.data.map((service) => ({
                    value: service.id,
                    label: service.attributes.name,
                }));
            })
            .catch((error) => {
                console.log('ERROR', error)
            });
    };

    const onCombinationChange = (value) => {
        if (value) {
            setSelectedCombination(combinations.find(c => c.id === value.value))
        } else {
            setSelectedCombination(null)
        }
    }

    const loadCombinationOptions = useCallback(
        debounce((inputText, callback) => {
            getCombinations(inputText).then((options) => callback(options));
        }, 1000), [])

    const loadStaffOptions = useCallback(
        debounce((inputText, callback) => {
            getStaffs(inputText).then((options) => callback(options));
        }, 1000), [])

    const loadMachinesOptions = useCallback(
        debounce((inputText, callback) => {
            getMachines(inputText, true).then((options) => callback(options));
        }, 1000), [])

    const loadDevicesOptions = useCallback(
        debounce((inputText, callback) => {
            getMachines(inputText, false, true).then((options) => callback(options));
        }, 1000), [])

    const loadServiceOptions = useCallback(
        debounce((inputText, callback) => {
            getServices(inputText).then((options) => callback(options));
        }, 1000), [])

    console.log(staffOrder)

    const initialValues = {
        combination: staffOrder.combination || defaultCombination,
        staff: staffOrder.staff || defaultDriver,
        machine: staffOrder.machine || defaultVehicle,
        geraet_1: staffOrder.geraet_1 || defaultGeraet1,
        geraet_2: staffOrder.geraet_2 || defaultGeraet2,
        service: staffOrder.service,
        period: staffOrder.period || { from: moment(), to: moment() }
    }

    const validate = (values) => {
        const errors = {};

        // Staff muss vorhanden sein
        if (!values.staff || values.staff === defaultDriver) {
            errors.staff = 'Staff muss vorhanden sein';
        }

        // Machine muss ausgewählt sein
        if (!values.machine || values.machine === defaultVehicle) {
            errors.machine = 'Maschine muss ausgewählt sein';
        }

        // ServiceId muss vorhanden sein
        if (!values.service) {
            errors.service = 'Dienstleistung muss vorhanden sein';
        }

        // Datum muss gewählt sein
        if (!values.period || !values.period.from || !values.period.to) {
            errors.period = 'Datum muss gewählt sein';
        }

        return errors;
    }

    return (
        <Form
            onSubmit={() => { }}
            initialValues={initialValues}
            validate={validate}
            render={({ handleSubmit, submitting, pristine, values, errors }) => (
                <form onSubmit={handleSubmit} className={"container"}>
                    <div className="d-flex flex-stack py-5 border-bottom border-gray-300 border-bottom-dashed container">
                        <div className="col-12">
                            <div className="row">
                                <div className={"col-4 fv-row"}>
                                    <label className="fs-5 fw-semibold mb-2">{t('combination')}</label>
                                    <Field
                                        name='combination'
                                        component={AsyncSelectAdapter}
                                        loadOptions={loadCombinationOptions}
                                        placeholder={t('combination')}
                                        isClearable={true}
                                        onChange={onCombinationChange}
                                    >
                                    </Field>
                                    <ErrorField name="combination" />
                                </div>
                                <div className={"col-4 fv-row"}>
                                    <label className="required fs-5 fw-semibold mb-2">{t('driver')}</label>
                                    <Field
                                        name='staff'
                                        component={AsyncSelectAdapter}
                                        loadOptions={loadStaffOptions}
                                        placeholder={t('driver')}
                                        isClearable={true}
                                    >
                                    </Field>
                                    <ErrorField name="staff" />
                                </div>
                            </div>
                            <div className={"row mt-2"}>
                                <div className={"col-4 fv-row"}>
                                    <label className="required fs-5 fw-semibold mb-2">{t('machine')}</label>
                                    <Field
                                        name='machine'
                                        component={AsyncSelectAdapter}
                                        loadOptions={loadMachinesOptions}
                                        placeholder={t('machine')}
                                        isClearable={true}
                                    >
                                    </Field>
                                    <ErrorField name="machine" />
                                </div>
                                <div className={"col-4 fv-row"}>
                                    <label className="fs-5 fw-semibold mb-2">{t('device1')}</label>
                                    <Field
                                        name='geraet_1'
                                        component={AsyncSelectAdapter}
                                        loadOptions={loadDevicesOptions}
                                        placeholder={t('device1')}
                                        isClearable={true}
                                    >
                                    </Field>
                                    <ErrorField name="geraet_1" />
                                </div>
                                <div className={"col-4 fv-row"}>
                                    <label className="fs-5 fw-semibold mb-2">{t('device2')}</label>
                                    <Field
                                        name='geraet_2'
                                        component={AsyncSelectAdapter}
                                        loadOptions={loadDevicesOptions}
                                        placeholder={t('device2')}
                                        isClearable={true}
                                    >
                                    </Field>
                                    <ErrorField name="geraet_2" />
                                </div>
                            </div>
                            <div className="row">
                                <div className={"col-6 fv-row"}>
                                    <label className="fs-5 fw-semibold mb-2">{t('period')}</label>
                                    <Field
                                        name='period'
                                        component={DateRangePickerAdapter}
                                        isClearable={true}
                                    />
                                </div>
                                <div className={"col-6 fv-row"}>
                                    <label className="required fs-5 fw-semibold mb-2">{t('service')}</label>
                                    <Field
                                        name='service'
                                        component={AsyncSelectAdapter}
                                        loadOptions={loadServiceOptions}
                                        placeholder={t('service')}
                                        isClearable={true}
                                    >
                                    </Field>
                                    <ErrorField name="service"/>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <TableButton
                                        className="btn btn-primary fw-semibold px-6"
                                        title={t('create')}
                                        onClick={() => onSave()}
                                        disabled={submitting || pristine}
                                    />
                                    <TableButton
                                        className="btn btn-light btn-active-light-warning fw-semibold me-2 px-6"
                                        type="button"
                                        onClick={() => onSave()}
                                        title={t('cancel')}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <FormSpy
                        subscription={{ values: true }}
                        onChange={change => {
                            if (change.values) {
                                const index = staffOrdersRef.current.map(item => item.id).indexOf(staffOrder.id)

                                staffOrdersRef.current[index] = { ...staffOrder, ...change.values }
                            }
                        }}
                    />
                </form>
            )}
        />
    )

}

export default StaffOrderForm
