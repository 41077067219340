import React from 'react';
import Table from './Table';

const ServiceMachineStatistics = ({ serviceId, companyId, filters, machineId, tab }) => {
  const tableToolbar = () => {
    return (<></>)
  }

  return (
    <Table
      tabName={tab}
      serviceId={serviceId}
      machineId={machineId}
      companyId={companyId}
      tableToolbar={tableToolbar}
      filters={filters}
    />
  )
}

export default ServiceMachineStatistics;
