import React, { useEffect, useRef } from 'react';
import { includes } from 'lodash';
import { MarkerWithLabel } from '@googlemaps/markerwithlabel';
import { useTranslation } from 'react-i18next';

const Map = ({
  fields = [],
  geometryAreas =[],
  visibleFields = [],
  selectedFieldIds,
  geometryCreationType,
  points = [],
  drawing,
  targetFigure,
  showAllFields,
  setSelectedId,
  selectedId,
  editMode,
  selectedPoint,
  drawingPoint,
  newField,
  onRightClick = (filed) => null,
  onClick = (field) => null,
  onPointClick = (point) => null,
  onGeometryClick = (geometry) => null,
  onUpdatePoint,
  hiddenPoint,
  onAddPoint,
  onUpdatePolygon,
  editGeometryMode,
  selectedFigureId,
  editPointMode,
  showMeasureArea = false,
  showMeasureDistance = false,
  drawingArea,
  drawingLine,
  drawPolygonEnabled = false}) => {

  const { t } = useTranslation();
  const mapRef = useRef(null);
  const boundsRef = useRef(new window.google.maps.LatLngBounds());
  const googleMapRef = useRef(null);
  const markersRef = useRef([])
  const polygonRef = useRef(null)
  const listenerRef = useRef(null);
  const distanceInfoWindow = useRef(null);
  const viewPoligonsRef = useRef([])
  const viewGeometryRef = useRef([])
  const centerGeometryMarkersRef = useRef([])
  const centerFieldMarkersRef = useRef([])
  const selectedField = useRef(null)
  const selectedFigure = useRef(null)
  const pointsRef = useRef([])
  const emptyListenerRef = useRef(null);

  const newMarkerWithLabel = (point) => {
    return new MarkerWithLabel({
      position: { lat: point.latitude, lng: point.longitude },
      clickable: true,
      draggable: editPointMode && selectedPoint?.id == point.id,
      map: googleMapRef.current,
      labelContent: point.name,
      labelAnchor: new google.maps.Point(0, -60),
      labelClass: "badge badge-secondary bg-successs",
      labelStyle: { opacity: 1.0 },
    });
  }

  const addPointOnMap = (point) => {
    const marker = newMarkerWithLabel(point)

    google.maps.event.addListener(marker, 'click', () => {
      onPointClick(point);
    });

    google.maps.event.addListener(marker, 'dragend', () => {
      const newPosition = marker.getPosition();
      onUpdatePoint(point, newPosition);
    });

    pointsRef.current.push(marker);
  }

  const addPoints = () => {
    pointsRef.current.forEach(marker => marker.setMap(null));
    pointsRef.current = [];

    points.forEach((point) => {
      addPointOnMap(point)
    });
  };

  const removePointFromMap = (point) => {
    const markerIndex = pointsRef.current.findIndex(marker =>
      marker.position.lat() === point.latitude &&
      marker.position.lng() === point.longitude
    );

    if (markerIndex !== -1) {
      pointsRef.current[markerIndex].setMap(null);
      pointsRef.current.splice(markerIndex, 1);
    }
  }

  useEffect(() => {
    hiddenPoint && removePointFromMap(hiddenPoint)
  }, [hiddenPoint])

  useEffect(() => {
    if (drawingPoint) {
      const mapClickListener = googleMapRef.current.addListener('click', (event) => {
        const newMarkerPosition = event.latLng;

        const newMarker = new MarkerWithLabel({
          position: newMarkerPosition,
          clickable: true,
          map: googleMapRef.current,
          labelContent: "New Point",
          labelAnchor: new google.maps.Point(-15, -50),
          labelClass: "badge badge-secondary",
          labelStyle: { opacity: 1.0 },
        });

        google.maps.event.addListener(newMarker, 'dragend', () => {
          const updatedPosition = newMarker.getPosition();
          onUpdatePoint({ id: null, name: "New Point" }, updatedPosition);
        });

        pointsRef.current.push(newMarker);

        onAddPoint({ latitude: newMarkerPosition.lat(), longitude: newMarkerPosition.lng() });
      });

      return () => {
        google.maps.event.removeListener(mapClickListener);
      };
    }
  }, [drawingPoint]);

  useEffect(() => {
    if (googleMapRef.current) { addPoints() }
  }, [points, editPointMode]);

  const strokeColor = (fieldData) => {
    if (selectedId == fieldData.id) return '#f9ed11' // yellow

    if (selectedId == fieldData.id || includes(selectedFieldIds, fieldData.id)) {
      return '#ff0000';
    }
    return fieldData.used_in_order ? '#e85454' : fieldData.disposed_in_order ? '#E87954' : '#0066ff';
  };

  useEffect(() => {
    !drawing && clearPoligons()
  }, [drawing])

  useEffect(() => {
    clearPoligons()

    if (listenerRef.current) {
      google.maps.event.removeListener(listenerRef.current);
      listenerRef.current = null;
    }
    if (distanceInfoWindow.current) { distanceInfoWindow.current.close() }
    if (!drawPolygonEnabled && !showMeasureDistance && !showMeasureArea && !drawingArea && !drawingLine) return
    if (!googleMapRef.current) return

    listenerRef.current = googleMapRef.current.addListener('click', (event) => {
      addMarker(event.latLng);
    });

  }, [drawPolygonEnabled, showMeasureDistance, showMeasureArea, drawingArea, drawingLine]);

  useEffect(() => {
    if (!selectedField.current) return;
    selectedField.current.polygon.setEditable(editMode);
    if (editMode) {
      const polygonPath = selectedField.current.polygon.getPath();

      listenerRef.current = google.maps.event.addListener(polygonPath, 'set_at', () => {
        const newPath = polygonPath.getArray().map(latLng => ({ lat: latLng.lat(), lng: latLng.lng() }));
        onUpdatePolygon(newPath);
      });

      listenerRef.current = google.maps.event.addListener(polygonPath, 'insert_at', () => {
        const newPath = polygonPath.getArray().map(latLng => ({ lat: latLng.lat(), lng: latLng.lng() }));
        onUpdatePolygon(newPath);
      });

    } else {
      listenerRef.current && google.maps.event.removeListener(listenerRef.current);
    }

  }, [editMode]);

  useEffect(() => {
    !editGeometryMode && showGeometryOnMap()

    if (!selectedFigure.current) return;
    selectedFigure.current.figure.setEditable(editGeometryMode);

    if (editGeometryMode) {
      const figurePath = selectedFigure.current.figure.getPath();

      listenerRef.current = google.maps.event.addListener(figurePath, 'set_at', () => {
        if (geometryCreationType){
          const newPath = figurePath.getArray().map(latLng => ({ lat: latLng.lat(), lng: latLng.lng() }));
          onUpdatePolygon(newPath);
        } else {
          onUpdatePolygon(figurePath.Eg);
        }
      });

      listenerRef.current = google.maps.event.addListener(figurePath, 'insert_at', () => {
        if (geometryCreationType) {
          const newPath = figurePath.getArray().map(latLng => ({ lat: latLng.lat(), lng: latLng.lng() }));
          onUpdatePolygon(newPath);
        } else {
          onUpdatePolygon(figurePath.Eg);
        }
      });

    } else {
      listenerRef.current && google.maps.event.removeListener(listenerRef.current);
    }

  }, [editGeometryMode]);

  const showFieldsOnMap = () => {
    const fieldsToShow = fieldsData()
    fieldsToShow.forEach(field => {
      field.polygon.setMap(googleMapRef.current);
    });
  };

  const showGeometryOnMap = () => {
    const geometryToShow = geometryData()

    geometryToShow.forEach(geometry => {
      geometry.figure.setMap(googleMapRef.current);
    });
  };

  const geojsonToPath = (geojson) => {
    const path = [];
    if (geojson) {
      const coordinates = Array.isArray(geojson.coordinates) ? geojson.coordinates : geojson;

      coordinates.forEach((ext) => {
        const points = Array.isArray(ext[0]) ? ext : [ext];
        points.forEach((coordinate) => {
          const latLng = new window.google.maps.LatLng(coordinate[1], coordinate[0]);
          path.push(latLng);
        });
      });
    }
    return path;
  };

  const drawPolygon = () => {
    if (polygonRef.current) { polygonRef.current.setMap(null) }

    const paths = markersRef.current.map(marker => marker.getPosition());
    onUpdatePolygon(paths)

    if (drawPolygonEnabled || showMeasureArea || drawingArea) {
      polygonRef.current = new window.google.maps.Polygon({
        paths,
        strokeColor: "#FF0000",
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: "#FF0000",
        fillOpacity: 0.35,
      });

      if (showMeasureArea && polygonRef.current.getPath()) {
        const area = window.google.maps.geometry.spherical.computeArea(polygonRef.current.getPath());
        showInfo("area", area, paths[0]);
      }
    }

    if (showMeasureDistance || drawingLine) {
      polygonRef.current = new window.google.maps.Polyline({
        path: paths,
        geodesic: true,
        strokeColor: "#0000FF",
        strokeOpacity: 0.8,
        strokeWeight: 2
      });

      if (showMeasureDistance) {
        const distance = window.google.maps.geometry.spherical.computeLength(polygonRef.current.getPath());
        showInfo("distance", distance, paths[0]);
      }
    }

    polygonRef.current.setMap(googleMapRef.current);
  };

  const showInfo = (type, value, position) => {
    if (distanceInfoWindow.current) {
      distanceInfoWindow.current.close();
    }

    const formattedValue = type === "distance"
      ? `${(value / 1000).toFixed(2)} km`
      : `${(Math.round(value / 10000 * 100) / 100).toString().replace('.', ',')} ${t('measurement_unit.ha')}`;

    const infoWindowContent = `
    <div style="padding: 10px; text-align: center; font-family: Arial, sans-serif; font-size: 14px;">
      <strong>${t(type)}:</strong> ${formattedValue}
    </div>`;

    distanceInfoWindow.current = new window.google.maps.InfoWindow({
      content: infoWindowContent,
      position: position,
    });

    distanceInfoWindow.current.open(googleMapRef.current);
  };

  const initMap = () => {
    const mapElement = mapRef.current
    const mapOptions = {
      tilt: 0,
      mapTypeId: window.google.maps.MapTypeId.HYBRID,
      gestureHandling: 'greedy',
      center: { lat: 52.3611591, lng: 7.46929 },
      zoom: 15,
    };

    googleMapRef.current = new window.google.maps.Map(mapElement, mapOptions);
  };

  const addMarker = (location) => {
    const marker = new window.google.maps.Marker({
      position: location,
      map: googleMapRef.current,
      title: 'Neuer Punkt',
      draggable: true,

      icon: {
        path: window.google.maps.SymbolPath.CIRCLE,
        scale: 4,
        fillColor: "#FF0000",
        fillOpacity: 1,
        strokeWeight: 0,
      }
    });

    markersRef.current.push(marker);

    drawPolygon()
    marker.addListener('rightclick', () => {
      marker.setMap(null);
      markersRef.current = markersRef.current.filter((m) => m !== marker);
      drawPolygon()
    });
    marker.addListener('dragend', () => {
      drawPolygon()
    });
  };

  useEffect(() => {
    initMap()
  }, [])

  useEffect(() => {
    clearPoligons()
  }, [newField])

  useEffect(() => {
    showGeometryOnMap()
  }, [geometryAreas])

  useEffect(() => {
    showFieldsOnMap()
    if (emptyListenerRef.current) {
      google.maps.event.removeListener(emptyListenerRef.current);
      emptyListenerRef.current = null;
    }

    if (!editMode) {
      emptyListenerRef.current = googleMapRef.current.addListener('click', (event) => {
        const clickedField = viewPoligonsRef.current.find(polygon => {
          return window.google.maps.geometry.poly.containsLocation(event.latLng, polygon);
        });

        !clickedField && setSelectedId && setSelectedId(false)
      });
    }
  }, [fields, visibleFields, showAllFields, selectedId, selectedFieldIds, drawingArea, drawingLine]);

  const zoomToField = () => {
    if (selectedField.current && selectedField.current.polygon.getPath()) {
      const bounds = new window.google.maps.LatLngBounds();
      const polygonPath = selectedField.current.polygon.getPath();

      polygonPath.forEach((latLng) => {
        bounds.extend(latLng);
      });

      boundsRef.current = bounds;
      googleMapRef.current.fitBounds(boundsRef.current);
    }
  }

  const getPolylineMidpoint = (path) => {
    const midIndex = Math.floor(path.length / 2);

    if (path.length % 2 === 0) {
      const point1 = path[midIndex - 1];
      const point2 = path[midIndex];
      return new window.google.maps.LatLng(
        (point1.lat() + point2.lat()) / 2,
        (point1.lng() + point2.lng()) / 2
      );
    }

    return path[midIndex];
  };

  const getPolygonCentroid = (path) => {
    let latSum = 0;
    let lngSum = 0;

    path.forEach(point => {
      latSum += point.lat();
      lngSum += point.lng();
    });

    const lat = latSum / path.length;
    const lng = lngSum / path.length;

    return new window.google.maps.LatLng(lat, lng);
  };

  const fieldsData = () => {
    let foundField = fields.find(field => field.id === visibleFields[0])

    if (!foundField && newField) {
      foundField = fields.find(field => field.id == newField)
    }

    let toShow = showAllFields ? fields : foundField ? [foundField] : []

    viewPoligonsRef.current.forEach(polygon => polygon?.setMap(null));
    centerFieldMarkersRef.current.forEach(marker => marker?.setMap(null));

    return toShow.map((fieldData) => {
      const path = geojsonToPath(fieldData.poly);
      const polygon = new window.google.maps.Polygon({
        strokeColor: strokeColor(fieldData),
        strokeWeight: 1,
        fillColor: fieldData.used_in_order ? '#74E854' : fieldData.disposed_in_order ? '#E87954' : '#0066ff',
        fillOpacity: includes(visibleFields, fieldData.id) ? 0.6 : 0.3,
        paths: path,
        editable: false,
        id: fieldData.id,
        name: fieldData.name_with_hectare,
        latitude: fieldData.latitude,
        longitude: fieldData.longitude,
        map: googleMapRef.current
      })

      viewPoligonsRef.current.push(polygon);
      // Marker with label positioned at the center of the field
      const markerPosition = new window.google.maps.LatLng(fieldData.latitude, fieldData.longitude);
      const marker = new MarkerWithLabel({
        position: markerPosition,
        map: googleMapRef.current,
        labelContent: fieldData.name_with_hectare,
        icon: {
          url: 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7',
        },
        labelClass: "badge badge-secondary",
        labelStyle: { opacity: 1.0 },
      });

      centerFieldMarkersRef.current.push(marker);
      onRightClick && polygon.addListener('rightclick', () => { onRightClick(fieldData) });

      if (drawingArea || drawingLine) {
        polygon.addListener('click', (event) => { addMarker(event.latLng); });
      }else {
        polygon.addListener('click', () => { onClick(fieldData) });
      }

      if (fieldData.id == selectedId) {
        selectedField.current = { polygon, marker, ...fieldData }
        zoomToField()
      }

      return { polygon, marker, ...fieldData };
    });
  }

  const geometryData = () => {
    viewGeometryRef.current.forEach(figure => figure?.setMap(null));
    centerGeometryMarkersRef.current.forEach(marker => marker?.setMap(null));

    return geometryAreas.map((geometryData) => {
      const path = geometryCreationType
        ? geojsonToPath(geometryData.geometry)
        : geometryData.geometry_points

      const GeometryClass = geometryData.geometry_type != 'Polyline'
        ? window.google.maps.Polygon
        : window.google.maps.Polyline;

      const figure = new GeometryClass({
        strokeColor: '#f9ed11',
        strokeWeight: 4,
        fillColor: '#f9ed11',
        fillOpacity: 0.3,
        path: path,
        editable: false,
        id: geometryData.id,
        name: geometryData.name,
      });

      viewGeometryRef.current.push(figure);
      // const markerPosition = new window.google.maps.LatLng(path[0].lat(), path[0].lng());
      const markerPosition = geometryData.geometry_type == "Polygon"
        ? getPolygonCentroid(path)
        : getPolylineMidpoint(path);
      const marker = new MarkerWithLabel({
        position: markerPosition,
        map: googleMapRef.current,
        labelContent: geometryData.name || geometryData.geometry_type,
        icon: {
          url: 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7',
        },
        labelClass: "badge badge-secondary bg-warning",
        labelStyle: { opacity: 1.0 },
      });

      marker.addListener('click', () => { onGeometryClick(geometryData) });
      centerGeometryMarkersRef.current.push(marker)

      if (drawingArea || drawingLine) {
        figure.addListener('click', (event) => { addMarker(event.latLng) });
      } else {
        figure.addListener('click', () => { onGeometryClick(geometryData) });
      }

      if (geometryData.id == selectedFigureId || geometryData == targetFigure) {
        selectedFigure.current = { figure, marker, ...geometryData }
      }

      return { figure, marker, ...geometryData };
    });
  }

  const clearPoligons = () => {
    markersRef.current.map(marker => {
      marker.setMap(null);
    })
    markersRef.current = []
    if (polygonRef.current) {
      polygonRef.current.setMap(null);
    }
  };

  return (
    <>
      {(drawing || editGeometryMode || drawingArea || drawingLine || editMode || drawingPoint || editPointMode || showMeasureDistance || showMeasureArea) && (
        <span class="badge badge-warning mt-5 justify-content-center w-100">{t('fields_page.in_draw_mode')}</span>
      )}
      <div ref={mapRef} style={{ height: '70vh', width: '100%', marginTop: "2rem" }}> </div>
    </>
  );
};

export default Map;
