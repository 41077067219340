import React from 'react';
import classNames from 'classnames';

const Pagination = ({ table, currentPage }) => {
  const maxNumberOfPages = 5
  const totalPages = table.getPageCount()

  const pagesToShow = () => {
    let array = [currentPage]
    let min = currentPage
    let max = currentPage
    for (let i = 1; i < maxNumberOfPages; i++) {
      if (array.length >= totalPages) { break }

      if (i & 1) {
        if ((min - 1) > 0) {
          min = min - 1
          array.unshift(min)
        } else {
          max = max + 1
          array.push(max)
        }
      }
      else {
        if (max < totalPages) {
          max = max + 1
          array.push(max)
        } else {
          min = min - 1
          array.unshift(min)
        }
      }
    }
    return array
  }

  const pagesHtml = () => {
    const pages = pagesToShow()
    return (
      <>
        {
          pages.map((pageNumber, index) => {
            return (
              <li key={index} className={classNames("dt-paging-button page-item", { active: pageNumber === currentPage })}>
                <button
                  onClick={() => table.setPageIndex(pageNumber - 1)}
                  className={classNames("page-link", { "bg-warning": pageNumber === currentPage })}
                  aria-controls="kt_ecommerce_sales_table"
                  aria-current="page" data-dt-idx="0"
                  tabIndex="0"
                >
                  {pageNumber}
                </button>
              </li>
            )
          })
        }
      </>
    )
  }

  return (
    <div className="dt-paging">
      <nav>
        <ul className="pagination">
          <li className={classNames("dt-paging-button page-item", { disabled: !table.getCanPreviousPage() })}>
            <button
              onClick={() => table.setPageIndex(0)}
              disabled={!table.getCanPreviousPage()}
              className="page-link first"
              aria-controls="kt_ecommerce_sales_table"
              aria-disabled="true" aria-label="First"
              data-dt-idx="first" tabIndex="-1">
              «
            </button>
          </li>
          <li className={classNames("dt-paging-button page-item", { disabled: !table.getCanPreviousPage() })}>
            <button
              onClick={() => table.previousPage()}
              disabled={!table.getCanPreviousPage()}
              className="page-link previous"
              aria-controls="kt_ecommerce_sales_table"
              aria-disabled="true" aria-label="Previous"
              data-dt-idx="previous" tabIndex="-1">
              ‹
            </button>
          </li>
          {pagesHtml()}
          <li className={classNames("dt-paging-button page-item", { disabled: !table.getCanNextPage() })}>
            <button
              onClick={() => table.nextPage()}
              disabled={!table.getCanNextPage()}
              href="#" className="page-link next"
              aria-controls="kt_ecommerce_sales_table"
              aria-label="Next" data-dt-idx="next"
              tabIndex="0">›
            </button>
          </li>
          <li className={classNames("dt-paging-button page-item", { disabled: !table.getCanNextPage() })}>
            <button
              onClick={() => table.setPageIndex(totalPages)}
              disabled={!table.getCanNextPage()}
              href="#" className="page-link last"
              aria-controls="kt_ecommerce_sales_table"
              aria-label="Last" data-dt-idx="last"
              tabIndex="0"
            >
              »
            </button>
          </li>
        </ul>
      </nav>
    </div>
  )
}

export default Pagination
