import React, { useEffect, useState } from 'react'
import Toastr from '../../components/Toastr';
import { axiosInstance, routes } from '../../utils/api_base';
import { useTranslation } from 'react-i18next';
import EditableTextInput from '../../components/Table/EditableTextInput';
import { QuestionIcon } from '../Icons';
import { Tooltip } from 'bootstrap'
import ToggleSwitch from '../ToggleSwitch';

const CustomizingModal = ({ onClose }) => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState(true);
  const appStore = ReactOnRails.getStore("appStore");
  const { currentCustomizing } = appStore.getState()?.appStore || {};
  const [customizingDetails, setCustomizingDetails] = useState();

  useEffect(() => {
    const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]')
    const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new Tooltip(tooltipTriggerEl))
  }, [loading])

  useEffect(() => {
    currentCustomizing && setLoading(false)
    setCustomizingDetails(
      {
        book_diesel_normative_at_travel: currentCustomizing.book_diesel_normative_at_travel,
        use_machine_group_for_service_feature: currentCustomizing.use_machine_group_for_service_feature,
        travel_diesel: currentCustomizing.travel_diesel
      }
    )
  }, [currentCustomizing]);

  const updateCustomizing = (id, attrs) => {
    const params = {
      customizing: {
        book_diesel_normative_at_travel: attrs.book_diesel_normative_at_travel,
        use_machine_group_for_service_feature: attrs.use_machine_group_for_service_feature,
        travel_diesel: attrs.travel_diesel?.replace(',', '.')
      }
    }

    axiosInstance.put(routes.customizing(currentCustomizing?.id), params)
      .then(resp => {
        Toastr({ message: t('success'), options: { showDuration: 2000 } })
        setCustomizingDetails((prevDetails) => ({
          ...prevDetails,
          ...attrs,
        }));
      })
      .catch(error => {
        Toastr({
          type: 'error',
          message: error.response?.data?.error || t('critical_error')
        })
      })
  }

  const toggleHtml = (attribute) => {
    const isActive = customizingDetails[attribute]
    const label = isActive ? t('true') : t('false')

    return (
      <ToggleSwitch
        id={attribute}
        handleToggle={() => updateCustomizing(currentCustomizing.id, { [attribute]: !isActive })}
        attribute={attribute}
        active={isActive}
        label={label}
      />
    )
  }

  return (
    <>
      {loading ? (
        <div className="text-center d-flex py-5 h-300px align-items-center justify-content-center">
          <div className="spinner-border" role="status"></div>
        </div>
      ) : (
        <div className="modal-body mh-600px overflow-auto">
          <div className="py-2 fs-6">
            <div className='d-flex flex-stack'>
              <div className="fw-bold">
                {t('customizing_modal.travel_time_booked_diesel_normative_question')}
              </div>
              <div className="text-gray-600 d-flex align-items-center">
                {toggleHtml('book_diesel_normative_at_travel')}
              </div>
            </div>
            { customizingDetails.book_diesel_normative_at_travel && (
              <>
                <div className="separator my-3"></div>
                <div className='d-flex flex-stack'>
                  <div className="fw-bold">
                    {t('customizing_modal.diesel_consumption_of_vehicle_during_journeys')}
                  </div>
                  <div className="text-gray-600 d-flex align-items-center">
                    <EditableTextInput
                      id={customizingDetails.id}
                      attribute="travel_diesel"
                      initialValue={customizingDetails.travel_diesel || '-'}
                      handleSubmit={updateCustomizing}
                      />
                    <span className="small ms-3">
                      {t('liters_per_hour')}
                    </span>
                  </div>
                </div>
              </>
            )}
            <div className="separator my-3"></div>
            <div className='d-flex flex-stack'>
              <div className="fw-bold">
                {t('customizing_modal.self_defined_machine_groups_as_condition_price_surcharges')}
                <span
                  data-bs-toggle="tooltip"
                  data-bs-custom-class="tooltip-inverse"
                  data-bs-placement="top"
                  data-bs-html="true"
                  title={t('customizing_modal.self_defined_machine_groups_as_condition_price_surcharges_title')}
                  className="btn p-0 text-hover-warning">
                  <QuestionIcon className={"fs-4"} />
                </span>
              </div>
              <div className="text-gray-600 d-flex align-items-center">
                {toggleHtml('use_machine_group_for_service_feature')}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default CustomizingModal;
