import React from 'react';
import Table from './Table';

const DieselStatistics = ({ machineId }) => {
  const tableToolbar = () => {
    return (<></>)
  }

  return (
    <Table tableToolbar={tableToolbar} machineId={machineId} />
  )
}

export default DieselStatistics;
