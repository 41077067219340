import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { axiosInstance, routes } from '../../utils/api_base';
import Select from '../Select';
import Toastr from '../Toastr';
import DatePicker from '../DatePicker';
import { Form, Field } from 'react-final-form';
import TableButton from '../Table/TableButton';
import moment from "moment";

const Create = ({ onClose }) => {
  const date = moment().toDate();
  const { t } = useTranslation();
  const [errors, setErrors] = useState([]);
  const [businessPartners, setBusinessPartners] = useState([]);

  useEffect(() => {
    getBusinessPartners({ pageIndex: 0, pageSize: 10 }).then((result) => setBusinessPartners(result.rows))
  }, []);

  const getBusinessPartners = async (pagination, sorting) => {
    const { pageIndex, pageSize } = pagination
    const params = { active: true, customer: true, page: pageIndex + 1, limit: pageSize }
    const res = await axiosInstance.get(routes.businessPartners(), { params: params })

    return (
      { rows: res.data.data, pageCount: res.data.meta.total_pages, rowCount: res.data.meta.total_count }
    )
  };

  function handleSubmit(values) {
    const data = {
      delivery_slip: {
        business_partner_id: values.businessPartnerId,
        date: moment.parseZone(values.date[0]).format('YYYY-MM-DD')
      }
    };

    axiosInstance
      .post(routes.deliverySlips(), data)
      .then(response => {
        Toastr({
          title: 'Success!',
          message: response.data.message,
          options: { closeButton: true }
        })

        onClose()
        window.location.href = response.data.redirect_url
      })
      .catch(error => {
        setErrors(error.response.data.error);
      })
  };

  const invalidInput = (message) => {
    return (
      <div className="d-block invalid-feedback">
        {message}
      </div>
    )
  };

  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={{
        businessPartnerId: businessPartners[0]?.attributes.id,
        date: date
      }}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <div className='container'>
            <div className='p-6'>
              <div className="row mb-3">
                <div className="col">
                  <label className='required'>{t('shared.customer')}</label>
                  <Field
                    name="businessPartnerId"
                    render={({ input }) => (
                      <Select
                        options={
                          businessPartners.map((entity) => ({
                            id: entity.id,
                            text: entity.attributes.long_name
                          }))
                        }
                        onChange={input.onChange}
                        url={routes.businessPartners()}
                        params={{ active: true, customer: true }}
                        formatData={(data) => data.map(item => ({
                          id: item.id, text: item.attributes.long_name
                        }))}
                        className={errors["business_partner_id"] ? "border-danger" : ""}
                      />
                    )}
                  />
                  {errors["business_partner_id"] ? invalidInput(errors["business_partner_id"]) : null}
                </div>
              </div>
              <div className="row mb-3">
                <div className="col">
                  <label className='required'>{t('shared.date')}</label>
                  <Field
                    name="date"
                    render={({ input }) => (
                      <DatePicker
                        className={errors["date"] ? "border-danger" : ""}
                        options={{
                          defaultDate: date,
                          onChange: input.onChange
                        }}
                      />
                    )}
                  />
                  {errors["date"] ? invalidInput(errors["date"]) : null}
                </div>
              </div>
              <div className="modal-footer">
                <TableButton
                  className="btn btn-light btn-active-light-primary fw-semibold me-2 px-6"
                  type="button"
                  onClick={onClose}
                  title={t('cancel')}
                />
                <TableButton
                  className="btn btn-primary fw-semibold px-6"
                  type="submit"
                  title={t('create')}
                />
              </div>
            </div>
          </div>
        </form>
      )}
    />
  );
};

export default Create;
