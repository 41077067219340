import React from 'react';
import { useTranslation } from 'react-i18next';
import TableButton from '../../components/Table/TableButton';
import { ExportIcon } from '../../components/Icons';
import Table from './Table';

const RentalMachines = ({ filters }) => {
  const { t } = useTranslation()

  const tableToolbar = () => {
    return (
      <>
        <TableButton type="button" className="btn-light-warning me-3" icon={<ExportIcon />} title={t('shared.export')} />
      </>
    )
  }

  return (
    <>
      <Table filters={filters} tableToolbar={tableToolbar} />
    </>
  )
}

export default RentalMachines
