import React from 'react';
import Table from './Table';
import SettingsPanel from './SettingsPanel';
import { useTranslation } from 'react-i18next';
import TableButton from '../../components/Table/TableButton';
import { ExportIcon } from '../../components/Icons';
import { axiosInstance, routes } from '../../utils/api_base';

const index = ({ businessPartner, paymentTerms }) => {
  const { t } = useTranslation()

  const createExport = () => {
    axiosInstance.post(routes.exports(), {
      type: 'BookingResultDriverInputsExport',
      export_format: 'xlsx',
      additional_params: {
        business_partner_id: businessPartner.id
      }
    })
      .then(response => console.log('RESPONSE', response))
      .catch(error => console.log('ERROR', error))
  }

  const tableToolbar = () => {
    return (
      <TableButton type="button" className="btn-light-warning me-3" onClick={() => createExport()} icon={<ExportIcon />} title={t('shared.export')} />
    )
  }

  return (
    <>
      <SettingsPanel businessPartner={businessPartner} paymentTerms={paymentTerms}/>
      <Table tableToolbar={tableToolbar} businessPartnerId={businessPartner.id} />
    </>
  )
}

export default index;
