import React, { useMemo } from 'react';
import BaseTable from '../Table';
import { isEmpty } from 'lodash';
import { axiosInstance, routes } from '../../utils/api_base';
import { pushToHistory } from '../../utils/base';
import { useTranslation } from 'react-i18next';

const Table = ({ tableToolbar, serviceId, companyId, filters, machineId, tabName }) => {
  const { t, i18n } = useTranslation()

  const nameHeader = serviceId && serviceId.length > 0 ? t("machines") : t("shared.services");

  const getServiceMachineStatistics = async (pagination, sorting, globalFilter, customFilters) => {
    const { pageIndex, pageSize } = pagination
    const sortQuery = sorting.map((attr) => {
      return attr.desc ? `${attr.id} desc` : attr.id
    })
    let params = {
      page: pageIndex + 1,
      limit: pageSize,
      sort: sortQuery[0],
      service_id: serviceId,
      company_id: companyId,
      machine_id: machineId,
      tab: tabName
    }
    if (globalFilter) {
      params.query = globalFilter
    }
    if (!isEmpty(customFilters)) {
      params = { ...params, ...customFilters }
    }
    const res = await axiosInstance.get(routes.serviceMachineStatistics(), { params: params })
    pushToHistory(params)

    return (
      {
        rows: res.data.data,
        pageCount: res.data.meta.total_pages,
        rowCount: res.data.meta.total_count,
        meta: res.data.meta
      }
    )
  }

  const formatQuantityProvided = (quantityProvided) => {
    return (
      <>
        {quantityProvided.map((quantity, index) => (
          <React.Fragment key={index}>
            <small>
              <u>{quantity[0]}:</u> {quantity[1]} {quantity[2]}
            </small>
            <br />
          </React.Fragment>
        ))}
      </>
    )
  };

  const columns = useMemo(
    () => [
      {
        id: 'full_name',
        accessorFn: row => row?.attributes?.full_name,
        header: nameHeader,
        enableSorting: true,
        size: 300,
        minSize: 300,
        cell: ({ row }) => {
          const attributes = row?.original?.attributes
          return <><a key={attributes.full_name} href={attributes.link}>{attributes.full_name}</a><br /></>
        },
        footer: t('total')
      },
      {
        id: 'quantity_invoiced',
        accessorFn: row => row?.attributes?.quantity_invoiced,
        header: t('quantity_billed'),
        enableSorting: true,
        cell: row => row.getValue()
      },
      {
        id: 'quantity_provided',
        accessorFn: row => formatQuantityProvided(row?.attributes?.quantity_provided),
        header: t('quantity_done'),
        enableSorting: false,
        cell: row => row.getValue()
      },
      {
        id: 'hours',
        accessorFn: row => row?.attributes?.hours.toFixed(2),
        header: t('actual_hours'),
        enableSorting: true,
        cell: row => row.getValue(),
        footer: data => data?.table?.options?.meta?.total_hours
      },
      {
        id: 'revenue',
        accessorFn: row => row?.attributes?.revenue,
        header: t('sales_volume'),
        enableSorting: true,
        cell: row => row.getValue(),
        footer: data => data?.table?.options?.meta?.total_revenue
      },
      {
        id: 'costs',
        accessorFn: row => row?.attributes?.costs,
        header: t('costs'),
        enableSorting: true,
        cell: row => row.getValue(),
        footer: data => data?.table?.options?.meta?.total_costs
      },
      {
        id: 'profit_loss_value',
        accessorFn: row => row?.attributes?.profit_loss_value,
        header: t('profit_loss'),
        enableSorting: true,
        cell: row => row.getValue(),
        footer: data => data?.table?.options?.meta?.total_profit_loss_value
      },
      {
        id: 'stats_chart',
        header: '',
        size: 10,
        minSize: 10,
        enableSorting: true,
        cell: ({ row }) => {
          const attributes = row?.original?.attributes
          return (
            <>
              <a key={attributes.id} href={attributes.stats_chart_link}>
                <i className='fas fa-chart-pie' style={{ color: attributes.stats_chart_color }}></i>
              </a><br />
            </>
          )
        },
        footer: data => <svg width='16' height='16' style={{ verticalAlign: 'middle', display: 'block', margin: '0 auto' }}>
          <circle cx='8' cy='8' r='8' fill={data?.table?.options?.meta?.totals_chart_color} />
        </svg>
      },
    ],
    [i18n.language]
  )

  return (
    <BaseTable
      getDataFn={getServiceMachineStatistics}
      columns={columns}
      tableToolbar={tableToolbar}
      enableFooter={true}
      filters={filters}
    />
  )
}

export default Table;
