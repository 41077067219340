import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import TableButton from '../Table/TableButton';
import { PlusIcon } from '../Icons';
import Modal from '../Modal';
import Create from './Create';
import Table from '../DeliverySlips/Table';

const DeliverySlips = () => {
  const { t } = useTranslation()
  const [modalOpen, setModalOpen] = useState(false)

  const tableToolbar = () => {
    return (
      <>
        <TableButton
          className="btn-warning"
          onClick={() => setModalOpen(true)}
          icon={<PlusIcon />}
          title={t('delivery_slips_table.create_delivery_slip')}
        />
      </>
    )
  }

  return (
    <>
      <Modal className="modal fade show" open={modalOpen} onClose={() => setModalOpen(false)} title={t('delivery_slips_table.new_delivery_slip')}>
        <Create onClose={() => setModalOpen(false)} />
      </Modal>
      <Table tableToolbar={tableToolbar} />
    </>
  )
}

export default DeliverySlips
