import React from "react";
import { useTranslation } from 'react-i18next';
// import { axiosInstance, routes } from '../../../utils/api_base';

const OverviewDetails = ({ staff, updateStaff }) => {
  const { t } = useTranslation()

  return (
    <>
      <div className="d-flex flex-stack fs-4 py-3">
        <div className="fw-bold rotate collapsible" data-bs-toggle="collapse" href="#overview-details" role="button">
          {t('additional_details').toUpperCase()}
          <span className="ms-2 rotate-180">
            <i className="ki-duotone ki-down fs-3"></i>
          </span>
        </div>
      </div>
      <div className="separator my-3"></div>
      <div id="overview-details" className="collapse show">
        <div className="d-flex row">
          <div className="col-12 col-lg-6">
            <div className="card mb-5 mb-xl-8">
              <div className="card-body pt-2">
                <div className="py-2 fs-6">
                  TODO
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-6">
            <div className="card mb-5 mb-xl-8">
              <div className="card-body pt-2">
                <div className="py-2 fs-6">
                  TODO
                </div>
              </div>
            </div>
          </div>
        </div >
      </div>
    </>
  );
}

export default OverviewDetails;
