import React from 'react';
import Legend from './Legend';
import OrderBookingsTable from './OrderBookingsTable';
import StaffBookingsTable from './StaffBookingsTable';

const FlatScreen = ({ client_id }) => {
  return (
    <>
      <StaffBookingsTable client_id={client_id} />
      <OrderBookingsTable client_id={client_id} />
      <Legend />
    </>
  );
};

export default FlatScreen;
