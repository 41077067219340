import React, { useEffect, useState } from 'react'
import Toastr from '../../components/Toastr';
import { axiosInstance, routes } from '../../utils/api_base';
import { useTranslation } from 'react-i18next';
import EditableTextInput from '../../components/Table/EditableTextInput';
import QuestionMark from '../Icons/QuestionMark';
import { Tooltip } from 'bootstrap'
import ToggleSwitch from '../ToggleSwitch';
import Button from "../Button";

const CustomizingModal = () => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState(true);
  const appStore = ReactOnRails.getStore("appStore");
  const { currentCustomizing } = appStore.getState()?.appStore || {};
  const [customizingDetails, setCustomizingDetails] = useState();

  function getCurrencySymbol(currencyCode) {
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: currencyCode
    });
    const formatted = formatter.formatToParts(123);
    const symbolPart = formatted.find(part => part.type === 'currency');
    return symbolPart ? symbolPart.value : null;
  }

  useEffect(() => {
    const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]')
    const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new Tooltip(tooltipTriggerEl))
  }, [loading])

  useEffect(() => {
    currentCustomizing && setLoading(false)
    setCustomizingDetails(
      {
        sales_force: currentCustomizing.sales_force,
        show_bank_at_staff: currentCustomizing.show_bank_at_staff,
        show_drivers_licenses_at_staffs: currentCustomizing.show_drivers_licenses_at_staffs,
        show_qualifications_at_staffs: currentCustomizing.show_qualifications_at_staffs,
        target_time: currentCustomizing.target_time?.toFixed(2),
        only_consider_positive_overtime: currentCustomizing.only_consider_positive_overtime,
        use_cost_bonus_payment: currentCustomizing.use_cost_bonus_payment,
        cost_bonus_payment_amount_cents: (currentCustomizing.cost_bonus_payment_amount_cents / 100).toFixed(2),
        cost_bonus_payment_amount_currency: currentCustomizing.cost_bonus_payment_amount_currency,
        use_vacation_days_in_payrolls: currentCustomizing.use_vacation_days_in_payrolls,
        vacation_days: currentCustomizing.vacation_days,
        show_bookings_at_multi_bookings: currentCustomizing.show_bookings_at_multi_bookings,
        allow_rebooking_of_staff: currentCustomizing.allow_rebooking_of_staff,
        show_button_to_exchange_staff_order_at_day: currentCustomizing.show_button_to_exchange_staff_order_at_day
      }
    )
  }, [currentCustomizing]);

  const updateCustomizing = (id, attrs) => {
    if (attrs.target_time) {
      attrs.target_time = Number(attrs.target_time).toFixed(2)
    }

    const params = {
      customizing: {
        sales_force: attrs.sales_force,
        show_bank_at_staff: attrs.show_bank_at_staff,
        show_drivers_licenses_at_staffs: attrs.show_drivers_licenses_at_staffs,
        show_qualifications_at_staffs: attrs.show_qualifications_at_staffs,
        target_time: attrs.target_time,
        only_consider_positive_overtime: attrs.only_consider_positive_overtime,
        use_cost_bonus_payment: attrs.use_cost_bonus_payment,
        cost_bonus_payment_amount_cents: attrs.cost_bonus_payment_amount_cents,
        use_vacation_days_in_payrolls: attrs.use_vacation_days_in_payrolls,
        vacation_days: attrs.vacation_days,
        show_bookings_at_multi_bookings: attrs.show_bookings_at_multi_bookings,
        allow_rebooking_of_staff: attrs.allow_rebooking_of_staff,
        show_button_to_exchange_staff_order_at_day: attrs.show_button_to_exchange_staff_order_at_day,
      }
    }

    axiosInstance.put(routes.customizing(currentCustomizing?.id), params)
      .then(resp => {
        Toastr({ message: t('success'), options: { showDuration: 2000 } })
        setCustomizingDetails((prevDetails) => ({
          ...prevDetails,
          ...attrs,
        }));
      })
      .catch(error => {
        Toastr({
          type: 'error',
          message: error.response?.data?.error || t('critical_error')
        })
      })
  }

  const toggleHtml = (attribute) => {
    const isActive = customizingDetails[attribute]
    let label = isActive ? t('true') : t('false')

    if (attribute == "use_vacation_days_in_payrolls") {
      label = isActive ? t('customizing_modal.manually') : t('customizing_modal.automatic_increase_per_month')
    }

    return (
      <ToggleSwitch
        id={attribute}
        handleToggle={() => updateCustomizing(currentCustomizing.id, { [attribute]: !isActive })}
        attribute={attribute}
        active={isActive}
        label={label}
      />
    )
  }

  return (
    <>
      {loading ? (
        <div className="text-center d-flex py-5 h-300px align-items-center justify-content-center">
          <div className="spinner-border" role="status"></div>
        </div>
      ) : (
        <div className="modal-body mh-600px overflow-auto">
          <div className="py-2 fs-6">
            <div className="fw-bold">
              {t('master_data')}
            </div>
            <div className="separator my-3"></div>
            <div className='d-flex flex-stack py-3'>
              {t('customizing_modal.sales_representatives_defined_and_assigned_to_customers')}
              <div className="text-gray-600 d-flex align-items-center">
                {toggleHtml('sales_force')}
              </div>
            </div>
            <div className="separator my-3"></div>
            <div className='d-flex flex-stack py-3'>
              {t('customizing_modal.bank_data_stored_each_employee')}
              <div className="text-gray-600 d-flex align-items-center">
                {toggleHtml('show_bank_at_staff')}
              </div>
            </div>
            <div className="separator my-3"></div>
            <div className='d-flex flex-stack py-3'>
              {t('customizing_modal.driving_licenses_classes_maintained')}
              <div className="text-gray-600 d-flex align-items-center">
                {toggleHtml('show_drivers_licenses_at_staffs')}
              </div>
            </div>
            <div className="separator my-3"></div>
            <div className='d-flex flex-stack py-3'>
              {t('customizing_modal.qualifications_stored')}
              <div className="text-gray-600 d-flex align-items-center">
                {toggleHtml('show_qualifications_at_staffs')}
              </div>
            </div>
            <div className="separator my-3"></div>
            <div className="fw-bold mt-6 pt-6">
              {t('payroll')}
            </div>
            <div className="separator my-3"></div>
            <div className='d-flex flex-stack py-3'>
              {t('customizing_modal.target_hours_of_employee_per_day')}
              <div className="text-gray-600 d-flex justify-content-between">
                <span className='me-4'>
                  {t('customizing_modal.working_time_in_hours')}
                </span>
                <EditableTextInput
                  id={customizingDetails.id}
                  attribute="target_time"
                  initialValue={customizingDetails.target_time || '-'}
                  handleSubmit={updateCustomizing}
                />
              </div>
            </div>
            <div className="separator my-3"></div>
            <div className='d-flex flex-stack py-3'>
              <div className="mt-5">
                {t('customizing_modal.overtime_regulations_take_positive_overtime')}
                <span
                  data-bs-toggle="tooltip"
                  data-bs-custom-class="tooltip-inverse"
                  data-bs-placement="top"
                  title={t('customizing_modal.overtime_regulations_take_positive_overtime_title')}
                  className='btn btn-sm p-2'
                >
                  <QuestionMark />
                </span>
              </div>
              <div className="text-gray-600 d-flex align-items-center">
                {toggleHtml('only_consider_positive_overtime')}
              </div>
            </div>
            <div className="separator my-3"></div>
            <div className='d-flex flex-stack py-3'>
              <div className="mt-5">
                {t('customizing_modal.standard_special_supplement_per_month')}
                <span
                  data-bs-toggle="tooltip"
                  data-bs-custom-class="tooltip-inverse"
                  data-bs-placement="top"
                  title={t('customizing_modal.standard_special_supplement_per_month_title')}
                  className='btn btn-sm p-2'
                >
                  <QuestionMark />
                </span>
              </div>
              <div>
                <div className='d-flex text-gray-600 flex-stack py-3'>
                  {t('customizing_modal.use_standard_special_surcharge_per_month')}
                  <div className="d-flex align-items-center ms-2">
                    {toggleHtml('use_cost_bonus_payment')}
                  </div>
                </div>
                {customizingDetails.use_cost_bonus_payment &&
                  <div className="text-gray-600 d-flex justify-content-start">
                    <span className='me-4 mt-4'>
                      {t('customizing_modal.bonus_payment_amount')}
                    </span>
                    <EditableTextInput
                      className='mt-4'
                      id={customizingDetails.id}
                      attribute="cost_bonus_payment_amount_cents"
                      initialValue={customizingDetails.cost_bonus_payment_amount_cents || '-'}
                      handleSubmit={updateCustomizing}
                    />
                    <span className='ms-2 mt-4'>
                      {getCurrencySymbol(customizingDetails.cost_bonus_payment_amount_currency)}
                    </span>
                  </div>
                }
              </div>
            </div>
            <div className="separator my-3"></div>
            <div className='d-flex flex-stack py-3'>
              {t('customizing_modal.wage_supplements')}
              <Button>
                <a className="text-white" href={"/remuneration/wage_benefits"}>{t('customizing_modal.edit_wage_supplements')}</a>
              </Button>
            </div>
            <div className="separator my-3"></div>
            <div className='d-flex flex-stack py-3'>
              <div className="mt-5 w-50 me-4">
                {t('customizing_modal.employee_vacations_day_text')}
                <span
                  data-bs-toggle="tooltip"
                  data-bs-custom-class="tooltip-inverse"
                  data-bs-placement="top"
                  title={t('customizing_modal.employee_vacations_day_text_title')}
                  className='btn btn-sm p-2'
                >
                  <QuestionMark />
                </span>
              </div>
              <div>
                <div className='d-flex text-gray-600 flex-stack py-3'>
                  <div className="d-flex align-items-center ms-2">
                    {toggleHtml('use_vacation_days_in_payrolls')}
                  </div>
                </div>
                {customizingDetails.use_vacation_days_in_payrolls &&
                  <div className="text-gray-600 d-flex justify-content-start">
                    <span className='me-2 mt-4'>
                      {t('customizing_modal.standard_vacation')}:
                    </span>
                    <EditableTextInput
                      className='mt-4'
                      id={customizingDetails.id}
                      attribute="vacation_days"
                      initialValue={customizingDetails.vacation_days || '-'}
                      handleSubmit={updateCustomizing}
                    />
                    <span className='ms-2 mt-4'>
                      {t('customizing_modal.days_a_year')}
                    </span>
                  </div>
                }
              </div>
            </div>
            <div className="separator my-3"></div>
            <div className="fw-bold mt-6 pt-6">
              {t('costs')}
            </div>
            <div className="separator my-3"></div>
            <div className='d-flex flex-stack py-3'>
              <div>
                {t('customizing_modal.calculated_costs_per_hour')}
                <span
                  data-bs-toggle="tooltip"
                  data-bs-custom-class="tooltip-inverse"
                  data-bs-placement="top"
                  title={t('customizing_modal.calculated_costs_per_hour_text')}
                  className='btn btn-sm p-2'
                >
                  <QuestionMark />
                </span>
              </div>
              <Button>
                <a className="text-white" href={"/hr_costs"}>{t('customizing_modal.calculated_imputed_costs')}</a>
              </Button>
            </div>
            <div className="separator my-3"></div>
            <div className="fw-bold mt-6 pt-6">
              {t('working_times')}
            </div>
            <div className="separator my-3"></div>
            <div className='d-flex flex-stack py-3'>
              {t('customizing_modal.button_displayed_directly_question')}
              <div className="text-gray-600 d-flex align-items-center">
                {toggleHtml('show_bookings_at_multi_bookings')}
              </div>
            </div>
            <div className="separator my-3"></div>
            <div className='d-flex flex-stack py-3'>
              <div className="mt-5">
                {t('customizing_modal.working_hours_rebooked_question')}
                <span
                  data-bs-toggle="tooltip"
                  data-bs-custom-class="tooltip-inverse"
                  data-bs-placement="top"
                  title={t('customizing_modal.working_hours_rebooked_question_title')}
                  className='btn btn-sm p-2'
                >
                  <QuestionMark />
                </span>
              </div>
              <div className="text-gray-600 d-flex align-items-center">
                {toggleHtml('allow_rebooking_of_staff')}
              </div>
            </div>
            <div className="separator my-3"></div>
            <div className='d-flex flex-stack py-3'>
              {t('customizing_modal.transfer_order_driver_question')}
              <div className="text-gray-600 d-flex align-items-center">
                {toggleHtml('show_button_to_exchange_staff_order_at_day')}
              </div>
            </div>
            <div className="separator my-3"></div>
          </div>
        </div>
      )}
    </>
  )
}

export default CustomizingModal;
