import React, { useMemo } from 'react';
import BaseTable from '../../components/Table';
import { isEmpty } from 'lodash';
import { axiosInstance, routes } from '../../utils/api_base';
import { pushToHistory } from '../../utils/base';
import { useTranslation } from 'react-i18next';
import TableButton from '../../components/Table/TableButton';

const Table = ({ tableToolbar, filters }) => {
  const { t, i18n } = useTranslation()

  const getArticleGroups = async (pagination, sorting, globalFilter, customFilters) => {
    const { pageIndex, pageSize } = pagination
    const sortQuery = sorting.map((attr) => {
      return attr.desc ? `${attr.id} desc` : attr.id
    })
    let params = { page: pageIndex + 1, limit: pageSize, sort: sortQuery[0] }
    if (globalFilter) {
      params.query = globalFilter
    }
    if (!isEmpty(customFilters)) {
      params = { ...params, ...customFilters }
    }
    const res = await axiosInstance.get(routes.articles(), { params: params })
    pushToHistory(params)

    return (
      { rows: res.data.data, pageCount: res.data.meta.total_pages, rowCount: res.data.meta.total_count }
    )
  }

  const articleRow = (row) => {
    return (
      <TableButton
        type="button"
        target="_blank"
        href={row?.original?.attributes?.link}
        title={row?.original?.attributes?.client_specific_number}
        className="btn-light-warning"
      />
    )
  }

  const columns = useMemo(
    () => [
      {
        id: 'client_specific_number',
        size: 20,
        minSize: 20,
        header: t('shared.article_number'),
        accessorFn: row => row?.attributes?.client_specific_number,
        cell: ({ row }) => articleRow(row)
      },
      {
        id: 'factory_number',
        header: t('shared.manufacturer_number'),
        accessorFn: row => row?.attributes?.factory_number,
        cell: row => row.getValue()
      },
      {
        id: 'name',
        header: t('name'),
        accessorFn: row => row?.attributes?.name,
        cell: row => row.getValue()
      },
      {
        id: 'group_name',
        header: t('article_group'),
        accessorFn: row => row?.attributes?.group_name,
        cell: row => row.getValue()
      },
      {
        id: 'stock',
        header: t('stock'),
        accessorFn: row => row?.attributes?.stock,
        cell: row => row.getValue()
      }
    ],
    [i18n.language]
  )

  return (
    <BaseTable
      columns={columns}
      filters={filters}
      getDataFn={getArticleGroups}
      initialSortDirection={'asc'}
      tableToolbar={tableToolbar}
    />
  )
}

export default Table;
