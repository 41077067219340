import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { axiosInstance, routes } from '../../utils/api_base';
import BaseTable from '../../components/Table';
import { GeolocationIcon } from '../Icons';

const FieldTable = ({ businessPartnerId, trigger, setSelectedField }) => {
  const { t, i18n } = useTranslation();

  const getFields = async (pagination, sorting, globalFilter) => {
    const { pageIndex, pageSize } = pagination
    const sortQuery = sorting.map((attr) => {
      return attr.desc ? `${attr.id} desc` : attr.id
    })
    const params = { page: pageIndex + 1, limit: 10, sort: sortQuery[0], business_partner_id: businessPartnerId }

    if (globalFilter) {
      params.query = globalFilter
    }

    const res = await axiosInstance.get(routes.fields(), { params: { ...params } })
    return (
      { rows: res.data.data, pageCount: res.data.meta.total_pages, rowCount: res.data.meta.total_count }
    )
  }

  const tableToolbar = () => {
    return (
      <></>
    )
  }

  const fieldHtml = (row) => {
    return (
      <div
        className='d-flex cursor-pointer'
        onClick={() => setSelectedField(row.original.attributes)}
      >
        <GeolocationIcon className='fs-1 text-warning me-1' />
        {row.original.attributes?.name}
      </div>
    )
  }

  const hectareHtml = (row) => {
    return (
      <span>
        {row.original.attributes.hectare?.toFixed(4)}
      </span>
    )
  }

  const fieldColumns = useMemo(
    () => [
      {
        id: 'name',
        header: t('field'),
        cell: ({ row }) => fieldHtml(row)

      },
      {
        id: 'hectare',
        accessorFn: row => row?.attributes?.hectare,
        header: t('measurement_unit.ha'),
        size: 20,
        minSize: 20,
        cell: ({ row }) => hectareHtml(row)
      },
    ],
    [i18n.language]
  )

  return (
    <BaseTable
      columns={fieldColumns}
      getDataFn={getFields}
      tableToolbar={tableToolbar}
      trigger={trigger}
      tableName={'Fields'}
    />
  )
}

export default FieldTable;
