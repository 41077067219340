import React from "react";
import { ArrowLeftIcon, ArrowRightIcon } from "../../components/Icons";

const TimeSwitch = ({ onClickPrev, onClickNext, onClickToday }) => {

    return (
        <div>
            <div className="btn-group">
                <label className="btn btn-outline btn-active-warning btn-sm">
                    <button className="btn-check" onClick={onClickPrev} />
                    <ArrowLeftIcon />
                </label>

                <label className="btn btn-outline btn-active-warning btn-sm">
                    <button className="btn-check" onClick={onClickNext} />
                    <ArrowRightIcon />
                </label>
            </div>
            <button className='btn btn-sm btn-outline ms-3' onClick={onClickToday}>Heute</button>
        </div>
    )
}

export default TimeSwitch
