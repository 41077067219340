import React, { useEffect } from "react";
import { Tooltip } from 'bootstrap'
import { useTranslation } from 'react-i18next';
import moment from 'moment'

const ShowStaffOrder = ({staffOrder, onEdit}) => {
    const {i18n} = useTranslation()

    useEffect(() => {
        const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]')
        const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new Tooltip(tooltipTriggerEl))
    }, []);

    moment.locale(i18n.language)

    function trim(text, length = 10) {
        return text.length > 10 ? text.substring(0, length) + "..." : text;
    }

    return <a className="list-group-item list-group-item-action" onClick={() => onEdit()}>
        <div className="container">
            <div className={"row"}>
                <div className="col-6">
                    <div className="d-flex align-items-center">
                        <a href="#"
                            className="d-flex align-items-center fs-5 fw-bold text-gray-900 text-hover-warning">{staffOrder.staff.label}</a>
                    </div>
                    <div className="d-flex align-items-center">
                        <div
                            className="badge badge-light fs-8 fw-semibold badge-warning border-0">{trim(staffOrder.machine.label)}</div>
                        {staffOrder.geraet_1?.label && <button
                            className="badge badge-light fs-8 fw-semibold ms-2 border-0">{trim(staffOrder.geraet_1.label)}</button>}

                        {staffOrder.geraet_2?.label &&
                            <button data-bs-toggle="tooltip" data-bs-custom-class="tooltip-inverse"
                                    data-bs-placement="bottom"
                                    title={staffOrder.geraet_2.label}
                                    className="badge badge-light fs-8 fw-semibold ms-2 border-0">{trim(staffOrder.geraet_2.label)}</button>}

                    </div>
                </div>
                <div className="col-6 align-content-end">
                    <div className="d-flex text-end flex-end">
                        {staffOrder.period.from.format('lll')} - {staffOrder.period.to.format('lll')}
                    </div>
                    <div className="d-flex text-end flex-end">

                        <button data-bs-toggle="tooltip" data-bs-custom-class="tooltip" data-bs-placement="bottom"
                                title={staffOrder.service.label}
                                className="badge badge-secondary fs-8 fw-semibold ms-2 border-0">{trim(
                            staffOrder.service.label, 20)}</button>
                    </div>
                </div>
            </div>
        </div>
    </a>
}

export default ShowStaffOrder
