import React, { useState } from "react";
import { useTranslation } from 'react-i18next';
import WorkingHoursTable from "./WorkingHoursTable";
import SummaryTable from "./SummaryTable";
import Modal from "../../Modal";
import TableButton from "../../Table/TableButton";
import { PlusIcon, CircleArrowIcon } from '../../Icons';
import Button from "../../Button";
import CreateWorkingHours from "./CreateWorkingHours";
import CreateAbsence from "./CreateAbsence";
import ExchangeDriver from "./ExchangeDriver";
import * as style from './index.module.css';

const WorkingTimes = ({ staff, day }) => {
  const currentDayStartTime = new Date(`${day}T06:00:00`);
  const { t } = useTranslation();
  const [absenceModalOpen, setAbsenceModalOpen] = useState(false)
  const [exchangeDriverModalOpen, setExchangeDriverModalOpen] = useState(false)
  const [workingHoursModalOpen, setworkingHoursModalOpen] = useState(false)
  const [lastBookingTime, setLastBookingTime] = useState(currentDayStartTime)
  const [trigger, setTrigger] = useState(false);

  const TableToolbar = () => {
    return (
      <>
        <TableButton
          className="btn-warning me-4"
          onClick={() => setworkingHoursModalOpen(true)}
          icon={<PlusIcon />}
          title={t('new_working_hours')}
        />
        <TableButton
          className="btn-warning me-4"
          onClick={() => setExchangeDriverModalOpen(true)}
          icon={<CircleArrowIcon />}
          title={t('exchange_driver_order')}
        />
        <TableButton
          className="btn-warning"
          onClick={() => setAbsenceModalOpen(true)}
          icon={<PlusIcon />}
          title={t('enter_absence')}
        />
      </>
    )
  }

  return (
    <>
      <SummaryTable
        tableToolbar={() => { <></> }}
        staff={staff}
        day={day}
        trigger={trigger}
        setTrigger={setTrigger}
      />
      <div className="mt-6">
        <Modal className="modal-lg" open={absenceModalOpen} onClose={() => setAbsenceModalOpen(false)} title={t('enter_absence')}>
          <CreateAbsence onClose={() => setAbsenceModalOpen(false)} staff={staff} setTrigger={setTrigger} />
        </Modal>
        <Modal className="modal-lg" open={exchangeDriverModalOpen} onClose={() => setExchangeDriverModalOpen(false)} title={t('exchange_driver_order')}>
          <ExchangeDriver onClose={() => setExchangeDriverModalOpen(false)} staff={staff} day={day} setTrigger={setTrigger} />
        </Modal>
        <Modal className="modal-xl" open={workingHoursModalOpen} onClose={() => setworkingHoursModalOpen(false)} title={t('new_working_hours')}>
          <CreateWorkingHours onClose={() => setworkingHoursModalOpen(false)} staff_id={staff.id} day={day} lastBookingTime={lastBookingTime} setTrigger={setTrigger} />
        </Modal>
        <WorkingHoursTable
          tableToolbar={TableToolbar}
          staff={staff}
          day={day}
          trigger={trigger}
          setTrigger={setTrigger}
          setLastBookingTime={setLastBookingTime}
        />
      </div>
      <div className="d-flex">
        <Button>
          <a
            href={`/bookings?staff_id=${staff.id}&occurrence_date_from=${day}&occurrence_date_to=${day}`}
            className={style['working-hours-link']}
          >{t('bookings')}</a>
        </Button>
        <Button>
          <a
            href={`/positions/staff?staff=${staff.id}&day=${day}`}
            className={style['working-hours-link']}
          >{t('route')}</a>
        </Button>
        <Button>
          <a
            href={`/chronicles/staff_per_day?staff=${staff.id}&day=${day}`}
            className={style['working-hours-link']}
          >{t('chronicle')}</a>
        </Button>
        <Button>
          <a
            href={`/booking_results?staff_id=${staff.id}&day=${day}`}
            className={style['working-hours-link']}
          >{t('driver_inputs')}</a>
        </Button>
      </div>
    </>
  );
};

export default WorkingTimes;
