import React from 'react';
import { useTranslation } from 'react-i18next';

const Summary = ({ machineData }) => {
  const { t } = useTranslation()
  const appStore = ReactOnRails.getStore("appStore");
  const { currentCustomizing } = appStore.getState()?.appStore || {};

  return (
    <div className="card card-flush">
      <div className="card-body">
        <table className="table align-middle table-row-dashed fs-6 gy-5 dataTable">
          <tbody className="fw-semibold text-gray-600">
            {
              currentCustomizing.operating_hours &&
                <tr>
                  <td>
                    {t("machine_repairs.operating_hours")}
                  </td>
                  <td>
                    {
                      machineData.attributes.operating_hours &&
                        <>
                          {machineData.attributes.operating_hours}
                          <a href={machineData.attributes.machines_history_link} className="ms-4">
                            {t("machine_repairs.history")}
                          </a>
                        </>
                    }
                  </td>
                </tr>
            }
            <tr>
              <td>
                {t("shared.plan_repair_costs")}
              </td>
              <td>
                {machineData.attributes.plan_repair_costs}
              </td>
            </tr>
            <tr>
              <td>
                {t("shared.actual_repair_hours")}
              </td>
              <td>
                {machineData.attributes.cost_repair_time > 0 && machineData.attributes.actual_repair_hours}
              </td>
            </tr>
            <tr>
              <td>
                {t("shared.actual_costs_repair_hours")}
              </td>
              <td>
                {machineData.attributes.cost_repair_time > 0 && machineData.attributes.actual_costs_repair_hours}
              </td>
            </tr>
            <tr>
              <td>
                {t("machine_repairs.cost_spare_parts")}
              </td>
              <td>
                {
                  parseInt(machineData.attributes.cost_of_spare_parts) > 0 &&
                    machineData.attributes.cost_of_spare_parts
                }
              </td>
            </tr>
            <tr>
              <td className="fw-bold text-black">
                {t("machine_repairs.actual_costs_total")}
              </td>
              <td className="fw-bold text-black">
                {
                  (machineData.attributes.cost_repair_time > 0 ||
                    parseInt(machineData.attributes.cost_of_spare_parts) > 0) &&
                    machineData.attributes.actual_cost_sum
                }
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default Summary;
