import React, { useState, useEffect } from 'react';
import { axiosInstance, routes } from '../../utils/api_base';
import Summary from './Summary';
import SpareParts from './SpareParts';
import RepairTimesPerDay from './RepairTimesPerDay';
import RepairTimesPerStaff from './RepairTimesPerStaff';

const MachineRepairsStatistics = ({ machineId }) => {
  const [machineData, setMachineData] = useState()

  useEffect(() => {
    fetchMachineData()
  }, []);

  const fetchMachineData = async () => {
    const params = { machine_id: machineId }

    try {
      const res = await axiosInstance.get(routes.machineRepairsStatistics(), { params })
      setMachineData(res.data.data)
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <div className="row">
      <div className="col-6">
        {machineData && <Summary machineData={machineData} />}
      </div>
      <div className="col-6">
        {
          machineData && machineData.attributes.spare_parts.length > 0 &&
            <SpareParts machineData={machineData} />
        }
      </div>
      <div className="col-12 mt-4">
        {machineData && <RepairTimesPerDay machineData={machineData} />}
      </div>
      <div className="col-12 mt-4">
        {machineData && <RepairTimesPerStaff machineData={machineData} />}
      </div>
    </div>
  )
}

export default MachineRepairsStatistics;
