import React, { useMemo } from 'react';
import { axiosInstance, routes } from '../../utils/api_base';
import { useTranslation } from 'react-i18next';
import { pushToHistory } from '../../utils/base';
import BaseTable from '../Table';
import TableButton from '../Table/TableButton';
import { isEmpty } from 'lodash';

const Table = ({ tableToolbar }) => {
  const { t, i18n } = useTranslation()

  const getDeliverySlips = async (pagination, sorting, globalFilter, customFilters) => {
    const { pageIndex, pageSize } = pagination
    const sortQuery = sorting.map((attr) => {
      return attr.desc ? `${attr.id} desc` : attr.id
    })
    let params = { page: pageIndex + 1, limit: pageSize, sort: sortQuery[0] }
    if (globalFilter) {
      params.query = globalFilter
    }
    if (!isEmpty(customFilters)) {
      params = { ...params, ...customFilters }
    }
    const res = await axiosInstance.get(routes.deliverySlips(), { params: params })
    pushToHistory(params)

    return (
      { rows: res.data.data, pageCount: res.data.meta.total_pages, rowCount: res.data.meta.total_count }
    )
  }

  const codeHtml = (row) => {
    return (
      <TableButton
        target="_blank"
        href={row?.original?.attributes?.link}
        className="btn-light-warning me-3"
        title={row?.original?.attributes?.name}
      />
    )
  }

  const articlesHtml = (row) => {
    const deliverySlipFeatures = row.original.attributes.delivery_slip_features || []
    return (
      <>
        {
          deliverySlipFeatures.map((dsf, index) => {
            return (
              <p key={index}>
                {dsf.attributes?.long_name}
              </p>
            )
          })
        }
      </>
    )
  }

  const columns = useMemo(
    () => [
      {
        id: 'code',
        header: t('shared.delivery_slip'),
        accessorFn: row => row?.attributes?.client_specific_number,
        size: 70,
        minSize: 70,
        enableSorting: true,
        cell: ({ row }) => {
          return codeHtml(row)
        },
      },
      {
        id: 'customer',
        accessorFn: row => row?.attributes?.business_partner_name,
        header: t('shared.customer'),
        cell: row => row.getValue(),
      },
      {
        id: 'article',
        header: t('shared.article'),
        cell: ({ row }) => {
          return articlesHtml(row)
        },
      },
      {
        id: 'date',
        accessorFn: row => row?.attributes?.delivery_slip_date,
        header: t('shared.date'),
        cell: row => row.getValue(),
      },
    ],
    [i18n.language]
  )

  return (
    <BaseTable
      columns={columns}
      columnVisibility={{}}
      getDataFn={getDeliverySlips}
      tableToolbar={tableToolbar}
    />
  )
}

export default Table
