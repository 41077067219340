import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Dropdown from 'react-bootstrap/Dropdown';


const Actions = ({ payroll, reopenPayroll, closePayroll }) => {
  const { t } = useTranslation()

  return (
    <div>
      {payroll?.payroll_is_closed ? (
        <div className='d-flex'>
          <button onClick={reopenPayroll} className="btn btn-sm btn-danger me-2">
            {t('reopen_payroll')}
          </button>
          <Dropdown as={ButtonGroup} className="d-flex align-items-center">
            <Button variant="success" className="btn-sm">
              <i className="bi bi-file-pdf" ></i>
              {t("create_pdf")}
            </Button>
            <Dropdown.Toggle
              split
              variant="success"
              id="dropdown-custom-2"
              className="btn-sm"
              style={{ borderLeft: '1px solid #ddd' }}
            />
            <Dropdown.Menu>
              <Dropdown.Item href={payroll?.summary_link}>{t("summary")}</Dropdown.Item>
              <Dropdown.Item href={payroll?.detailed_working_hours_link}>{t("detailed_working_hours")}</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      ) : (
        <div>
          {payroll?.has_bookings_inprocess ? (
            <span className='text-danger'>{t('inprocess_bookings_exists')}</span>
          ) : (
            <button onClick={closePayroll} className="btn btn-sm btn-danger">{t('close_payroll')}</button>
          )}
        </div>
      )}
    </div>
  );
};

export default Actions;
