import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import DetailsPanel from './DetailsPanel';
import queryString from 'query-string';
import MasterData from './MasterData';
import WorkingTimes from './WorkingTimes';
import classNames from 'classnames'
import * as style from './index.module.css';
import { pushToHistory } from '../../utils/base';
import StaffAsyncSelector from './StaffAsyncSelector';
import DatePicker from '../DatePicker';
import TableButton from '../Table/TableButton';
import { GearIcon } from '../Icons';
import PersonalData from './PersonalData';
import { axiosInstance, routes } from '../../utils/api_base';
import Toastr from '../Toastr';
import Payroll from './Payroll';
import CustomizingModal from './CustomizingModal';
import Modal from '../../components/Modal';

const Staff = ({ id, customizing, currentStaff }) => {
  const parsedQuery = queryString.parse(location.search)
  const { t } = useTranslation()
  const initialTab = parsedQuery.staff_page || 'master_data';
  const [activeTab, setActiveTab] = useState(initialTab);
  const urlParams = new URLSearchParams(window.location.search);
  const date = urlParams.get('day') ?? new Date().toISOString().split('T')[0];
  const [selectedDate, setSelectedDate] = useState({ date: date })
  const [staff, setStaff] = useState({ id: id })
  const [settingsModalOpen, setSettingsModalOpen] = useState(false)

  useEffect(() => {
    const parsedQuery = queryString.parse(location.search);
    const newTab = parsedQuery.staff_page || 'master_data';

    setActiveTab(newTab);
  }, [location.search]);


  const setTab = (tab) => {
    const queryParams = { staff_page: tab, day: selectedDate.date }

    pushToHistory(queryParams);
    setActiveTab(tab)
  };

  const onChangeDatePicker = (selectedDates, dateStr) => {
    setSelectedDate({ date: dateStr })
    pushToHistory({ day: dateStr, staff_page: activeTab })
  }

  const DateSelector = () => {
    return (
      <div className='ms-6'>
        <DatePicker
          className="cursor-pointer text-left form-control-sm"
          options={{
            defaultDate: date,
            dateFormat: 'Y-m-d',
            onChange: onChangeDatePicker
          }}
        />
      </div>
    )
  }

  const getStaff = (id) => {
    axiosInstance.get(routes.staff(id))
      .then((response) => {
        setStaff(prevState => ({
          ...prevState,
          ...response.data.data.attributes
        }))
      })
      .catch((error) => {
        console.log('ERROR', error)
      });
  }

  useEffect(() => {
    getStaff(id)
  }, [id])

  const updateStaff = (id, params) => {
    const data = {
      staff: {
        cost_center_id: params.cost_center_id,
        active: params.active,
        show_in_dispo: params.show_in_dispo,
        company_id: params.company_id,
        location_id: params.location_id,
        role: params.selected?.role === null ? 0 : params.selected?.role
      },
      user: {
        id: params.user_id,
        entry_date: params.entry_date,
        termination_date: params.termination_date,
        termination_reason: params.termination_reason,
        health_insurance: params.health_insurance,
        tax_number: params.tax_number,
        social_security_number: params.social_security_number,
        disabled: params.disabled,
        specific_target_time: params.specific_target_time,
        use_wage_benefit: params.use_wage_benefit,
        vacation_days: params.vacation_days,
        bic: params.bic,
        iban: params.iban,
        temporary_staff: params.temporary_staff,
        payroll_not_needed: params.payroll_not_needed,
        school_education_id: params.school_education_id,
        apprenticeship_id: params.apprenticeship_id,
        tax_class: params.tax_class,
        child_relief_factor: params.child_relief_factor,
        email: params.email,
        password: params.password,
        street: params.street,
        postal_code: params.postal_code,
        city: params.city,
        birthday: params.birthday,
        position: params.position,
        birth_location: params.birth_location,
        education: params.education,
        country_id: params.country_id,
        comment: params.comment,
        fon_private: params.fon_private,
        mobile_private: params.mobile_private,
        fon_company: params.fon_company,
        mobile_company: params.mobile_company,
        fax_company: params.fax_company,
        invoice_email: params.invoice_email,
        motorcycle_a: params.motorcycle_a,
        motorbike_a1: params.motorbike_a1,
        motorbike_m: params.motorbike_m,
        passenger_car_b: params.passenger_car_b,
        passenger_car_be: params.passenger_car_be,
        passenger_car_s: params.passenger_car_s,
        special_class_t: params.special_class_t,
        special_class_l: params.special_class_l,
        pest_management: params.pest_management,
        chopper: params.chopper,
        combine_harvester: params.combine_harvester,
        truck_c: params.truck_c,
        truck_ce: params.truck_ce,
        truck_c1: params.truck_c1,
        truck_c1e: params.truck_c1e,
        bus_d: params.bus_d,
        bus_de: params.bus_de,
        bus_d1: params.bus_d1,
        bus_d1e: params.bus_d1e,
        truck_end: params.truck_end,
        driver_licence_controlled_at: params.driver_licence_controlled_at,
        driver_licence_controlled_by_id: params.driver_licence_controlled_by_id,
      }
    }
    axiosInstance.put(routes.staff(id), data)
      .then(resp => {
        Toastr({ message: t('success'), options: { showDuration: 2000 } })
        setStaff((prevDetails) => ({
          ...prevDetails,
          ...resp.data.data.attributes
        }));
      })
      .catch(error => {
        Toastr({
          type: 'error',
          message: error.response?.data?.error || t('critical_error')
        })
      })
  }

  return (
    <div className="app-content flex-column flex-row-fluid">
      <div className="app-toolbar pb-5">
        <div className="app-container container-xxl d-flex flex-stack">
          <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3">
            <h1 className="page-heading d-flex text-gray-900 fw-bold fs-3 flex-column justify-content-center my-0">
            </h1>
          </div>
          <div className="d-flex align-items-center gap-2 gap-lg-3">
            <div className="d-flex">
              <StaffAsyncSelector staffLongName={staff.long_name} activeTab={activeTab} day={selectedDate.date} />
              {activeTab === "working_times" && <DateSelector />}
              <TableButton
                className="btn-light-primary py-0 ms-4"
                icon={<GearIcon />}
                onClick={() => setSettingsModalOpen(true)}
              />
            </div>
          </div>
          <Modal className="modal fade show w-750px mw-750px" open={settingsModalOpen} onClose={() => setSettingsModalOpen(false)} title={t('customizing_modal.settings_for_staffs')}>
            <CustomizingModal onClose={() => setModalOpen(false)} />
          </Modal>
        </div>
      </div>
      <div className='d-flex'>
        <div className={classNames("position-fixed", style['detail-panel-position'])}>
          <DetailsPanel staff={staff} updateStaff={updateStaff}></DetailsPanel>
        </div>
        <div className={classNames("", style['tabs-position'])}>
          <Tabs
            onSelect={(k) => setTab(k)}
            activeKey={activeTab}
            id="staff-tabs"
            className="mb-3"
          >
            <Tab
              eventKey="master_data"
              title={<span className={classNames(activeTab === 'master_data' ? style['tab-selected'] : style['tab-unselected'])}>{t('master_data')}</span>}
              mountOnEnter={true}
            >
              <MasterData
                staff={staff}
                updateStaff={updateStaff}
                customizing={customizing}
                currentStaff={currentStaff}
              />
            </Tab>
            <Tab
              eventKey="working_times"
              title={<span className={classNames(activeTab === 'working_times' ? style['tab-selected'] : style['tab-unselected'])}>{t('working_hours')}</span>}
              mountOnEnter={true}
            >
              <WorkingTimes
                staff={staff}
                staffLongName={staff.long_name}
                activeTab={activeTab}
                day={selectedDate.date}
              />
            </Tab>
            <Tab
              eventKey="personal_data"
              title={<span className={classNames(activeTab === 'personal_data' ? style['tab-selected'] : style['tab-unselected'])}>{t('staff_data')}</span>}
              mountOnEnter={true}
            >
              <PersonalData
                staff={staff}
                activeTab={activeTab}
                customizing={customizing}
                updateStaff={updateStaff}
                setStaff={setStaff}
              />
            </Tab>
            <Tab
              eventKey="payroll"
              title={<span className={classNames(activeTab === 'payroll' ? style['tab-selected'] : style['tab-unselected'])}>{t('payroll')}</span>}
              mountOnEnter={true}
            >
              <Payroll staff={staff} />
            </Tab>
          </Tabs>
        </div>
      </div>
    </div>
  )
}

export default Staff;
