// extracted by mini-css-extract-plugin
var _1 = "Et2OqwAFsFs8QcMc4_77";
var _2 = "cwo2WJsB8mby4N1BE4qY";
var _3 = "K7grDmg2Sy_0fcmsnMIw";
var _4 = "dn5m8CXclr1xvxBpA4gN";
var _5 = "jeHEdNl8yDw_wO3YY0Tv";
var _6 = "oiEdaMZsL5kf3sllwtfj";
var _7 = "C0AHggp1n87DSC33EDli";
var _8 = "Vf8EgloTRo8dDgENXSEd";
var _9 = "g1nFWfDxdGjWitc_X3wE";
var _a = "MrPReGeKZ2vwunhjB6S_";
export { _1 as "last-gps-point", _2 as "other-time", _3 as "pause-time", _4 as "repair-time", _5 as "setting-up-time", _6 as "square-bullet", _7 as "travel-time", _8 as "waiting-time", _9 as "washing-time", _a as "working-time" }
