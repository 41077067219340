import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import TableButton from '../Table/TableButton';
import { PlusIcon } from '../Icons';
import Modal from '../Modal';
import CreateDocument from '../Staffs/CreateDocument';
import DocumentsTable from '../Staffs/DocumentsTable';
import CreateComment from './CreateComment';
import CommentsTable from './CommentsTable';
import OverviewDetails from './OverviewDetails';

const Overview = ({ tab, machine }) => {
  const { t } = useTranslation()
  const [documentsModalOpen, setDocumentsModalOpen] = useState(false);
  const [documentsTrigger, setDocumentsTrigger] = useState(false);
  const [commentsModalOpen, setCommentsModalOpen] = useState(false);
  const [commentsTrigger, setCommentsTrigger] = useState(false);

  const documentsTableToolbar = () => {
    return (
      <TableButton
        className="btn-primary"
        onClick={() => setDocumentsModalOpen(true)}
        icon={<PlusIcon />}
        title={t('new')}
      />
    )
  }

  const commentsTableToolbar = () => {
    return (
      <TableButton
        className="btn-primary"
        onClick={() => setCommentsModalOpen(true)}
        icon={<PlusIcon />}
        title={t('new')}
      />
    )
  }

  return (
    <div className="tab-pane">
      <OverviewDetails />
      <Modal className="modal-lg" open={documentsModalOpen} onClose={() => setDocumentsModalOpen(false)} title={t('documents')}>
        <CreateDocument onClose={() => setDocumentsModalOpen(false)} setTrigger={setDocumentsTrigger} resourceId={machine.id} />
      </Modal>
      <DocumentsTable resourceId={machine.id} tableToolbar={documentsTableToolbar} setTrigger={setDocumentsTrigger} trigger={documentsTrigger} />

      <Modal className="modal-lg" open={commentsModalOpen} onClose={() => setCommentsModalOpen(false)} title={t('comments')}>
        <CreateComment onClose={() => setCommentsModalOpen(false)} setTrigger={setCommentsModalOpen} resourceId={machine.id} />
      </Modal>
      <CommentsTable resourceId={machine.id} tableToolbar={commentsTableToolbar} setTrigger={setCommentsTrigger} trigger={commentsTrigger} />
    </div>
  );
};

export default Overview;
