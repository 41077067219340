import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Toastr from '../../Toastr';
import { axiosInstance, routes } from '../../../utils/api_base';
import { Form, Field } from 'react-final-form'
import TableButton from '../../Table/TableButton';
import { FORM_ERROR } from 'final-form'
import * as style from './index.module.css';
import TimePickerAdapter from '../../Form/TimePickerAdapter';
import AsyncSelectAdapter from '../../Form/AsyncSelectAdapter';
import ErrorField from '../../Form/ErrorField';

const CreateWorkingHours = ({ onClose, day, staff_id, lastBookingTime, setTrigger }) => {
  const { t } = useTranslation();
  const [currentPayroll, setCurrentPayroll] = useState({})

  const onSubmit = async values => {
    let hour;
    let minute;

    if (typeof values.day_start_time === 'string') {
      [hour, minute] = values.day_start_time.split(':').map(Number);
    } else {
      hour = values.day_start_time.getHours();
      minute = values.day_start_time.getMinutes();
    }

    const data = {
      break_time: values.break_time || "",
      cost_other_time: values.cost_other_time || "",
      cost_repair_time: values.cost_repair_time || "",
      cost_setting_up_time: values.cost_setting_up_time || "",
      cost_travel_time: values.cost_travel_time || "",
      cost_wait_time: values.cost_wait_time || "",
      cost_wash_time: values.cost_wash_time || "",
      cost_work_time: values.cost_work_time || "",
      machine_id: values.machine?.value || "",
      staff_order_id: values.staff_order?.value || "",
      hour: hour,
      minute: minute,
      payroll_id: values.payroll?.value,
      date: day,
      staff_id: staff_id,
      booking_type: "new_booking"
    };

    return axiosInstance.post(routes.bookings(), data)
      .then(response => {
        Toastr({
          title: 'Success!',
          message: response.data.message,
          options: { closeButton: true }
        })
        onClose()
        setTrigger(Math.floor(Date.now() / 1000)) // Dirty hack to refresh the table
      })
      .catch(error => {
        const errorData = error.response.data

        if (typeof errorData === 'object' && errorData !== null) {
          console.log("ErrorData:", errorData)
          return errorData.error;
        } else {
          return { [FORM_ERROR]: error.message }
        }
      })
  }

  const getStaffOrders = (inputText) => {
    let params = {
      page: 1,
      limit: 20,
      staff_id: staff_id,
      day: day,
      apply_status: "true",
      query: inputText,
    }

    return axiosInstance.get(routes.staffOrders(), { params: params })
      .then((response) => {
        return response.data.data.map((staffOrder) => ({
          value: staffOrder.id,
          label: staffOrder.attributes.name,
        }));
      })
      .catch((error) => {
        console.log('ERROR', error)
      });
  };

  const getMachines = (inputText) => {
    let params = {
      page: 1,
      limit: 20,
      sort: 'name',
      serialize: 'short',
      active: 'true',
      fahrzeug: 'true',
      query: inputText
    }

    return axiosInstance.get(routes.machines(), { params: params })
      .then((response) => {
        return response.data.data.map((machine) => ({
          value: machine.id,
          label: machine.attributes.long_name,
        }));
      })
      .catch((error) => {
        console.log('ERROR', error)
      });
  };

  const getPayrolls = (inputText) => {
    let params = {
      staff_id: staff_id,
      day: day,
      working_hour_payrolls: "true",
      query: inputText,
    }

    return axiosInstance.get(routes.remunerationPayrolls(), { params: params })
      .then((response) => {
        const payrolls = response.data.data
        setCurrentPayroll({ value: payrolls[0].attributes.id, name: payrolls[0].attributes.name })

        return payrolls.map((payroll) => ({
          value: payroll.id,
          label: payroll.attributes.name,
        }));
      })
      .catch((error) => {
        console.log('ERROR', error)
      });
  };

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={{
        day_start_time: new Date(lastBookingTime),
      }}
      render={({ submitError, handleSubmit, submitting, pristine }) => (
        <form className='mt-4' onSubmit={handleSubmit}>
          <div className='container'>
            <div className="w-100 mb-6 ms-14">
              <div className="d-flex align-items-baseline justify-content-start mt-6">
                <div style={{ marginRight: "78px" }}>
                  <label className='me-4'>{t('performance_start_time')}</label>
                  <div className={style["form-time-picker"]}>
                    <Field
                      name='day_start_time'
                      component={TimePickerAdapter}
                      clearIcon={false}
                      disableClock={true}
                      required={true}
                    >
                    </Field>
                  </div>
                </div>
                <div className='d-flex align-items-baseline'>
                  <label className='me-4'>{t('payroll')}</label>
                  <Field
                    name='payroll'
                    component={AsyncSelectAdapter}
                    loadOptions={getPayrolls}
                    placeholder={currentPayroll.name}
                    isClearable={true}
                  >
                  </Field>
                </div>
              </div>
            </div>
            <div className='d-flex justify-content-evenly'>
              <div className='w-25'>
                <div className="row">
                  <h6>{t('order_times').toUpperCase()}</h6>
                </div>
                <div className={`${style['working-hours-select']} mb-3`}>
                  <Field
                    name='staff_order'
                    component={AsyncSelectAdapter}
                    loadOptions={getStaffOrders}
                    placeholder={t('order')}
                    isClearable={true}
                  >
                  </Field>
                  <ErrorField name="staff_order_id" />
                </div>
                <div className="row mb-3">
                  <div className="col d-flex justify-content-between align-items-center" style={{ backgroundColor: "#99FF99" }}>
                    <label>{t('invoiced_working_time')}</label>
                    <Field
                      name="cost_work_time"
                      component="input"
                      type="text"
                      className={`form-control h-75 w-25 ${style['field-input']}`}
                      placeholder={t('measurement_unit.hours')}
                      autoFocus
                    />
                    <ErrorField name="cost_work_time" />
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col d-flex justify-content-between align-items-center" style={{ backgroundColor: "#FEF5CD" }}>
                    <label>{t('booking_codes.cost_setting_up_time')}</label>
                    <Field
                      name="cost_setting_up_time"
                      component="input"
                      type="text"
                      className={`form-control h-75 w-25 ${style['field-input']}`}
                      placeholder={t('measurement_unit.hours')}
                      autoFocus
                    />
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col d-flex justify-content-between align-items-center" style={{ backgroundColor: "#99FFFF" }}>
                    <label>{t('booking_codes.cost_travel_time')}</label>
                    <Field
                      name="cost_travel_time"
                      component="input"
                      type="text"
                      className={`form-control h-75 w-25 ${style['field-input']}`}
                      placeholder={t('measurement_unit.hours')}
                      autoFocus
                    />
                  </div>
                </div>
              </div>
              <div className='w-25'>
                <div className="row">
                  <h6>{t('order_times_yard_times').toUpperCase()}</h6>
                </div>
                <div className={`${style['working-hours-select']} mb-3`}>
                  <Field
                    name='machine'
                    component={AsyncSelectAdapter}
                    loadOptions={getMachines}
                    placeholder={t('machine')}
                    isClearable={true}
                  >
                  </Field>
                  <ErrorField name="machine" />
                </div>
                <div className="row mb-3">
                  <div className="col d-flex justify-content-between align-items-center" style={{ backgroundColor: "#F899BB" }}>
                    <label>{t('booking_codes.cost_repair_time')}</label>
                    <Field
                      name="cost_repair_time"
                      component="input"
                      type="text"
                      className={`form-control h-75 w-25 ${style['field-input']}`}
                      placeholder={t('measurement_unit.hours')}
                      autoFocus
                    />
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col d-flex justify-content-between align-items-center" style={{ backgroundColor: "#FEFFBB" }}>
                    <label>{t('booking_codes.cost_wait_time')}</label>
                    <Field
                      name="cost_wait_time"
                      component="input"
                      type="text"
                      className={`form-control h-75 w-25 ${style['field-input']}`}
                      placeholder={t('measurement_unit.hours')}
                      autoFocus
                    />
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col d-flex justify-content-between align-items-center" style={{ backgroundColor: "#FEFF99" }}>
                    <label>{t('booking_codes.cost_wash_time')}</label>
                    <Field
                      name="cost_wash_time"
                      component="input"
                      type="text"
                      className={`form-control h-75 w-25 ${style['field-input']}`}
                      placeholder={t('measurement_unit.hours')}
                      autoFocus
                    />
                  </div>
                </div>
              </div>
              <div className='w-25'>
                <div className="row">
                  <h6>{t('other_times').toUpperCase()}</h6>
                </div>
                <div className="row mb-3">
                  <div className="col d-flex justify-content-between align-items-center" style={{ backgroundColor: "#F999FF" }}>
                    <label>{t('booking_codes.cost_other_time')}</label>
                    <Field
                      name="cost_other_time"
                      component="input"
                      type="text"
                      className={`form-control h-75 w-25 ${style['field-input']}`}
                      placeholder={t('measurement_unit.hours')}
                      autoFocus
                    />
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col d-flex justify-content-between align-items-center">
                    <label>{t('booking_codes.break_time')}</label>
                    <Field
                      name="break_time"
                      component="input"
                      type="text"
                      className={`form-control h-75 w-25 ${style['field-input']}`}
                      placeholder={t('measurement_unit.hours')}
                      autoFocus
                    />
                  </div>
                </div>
              </div>
            </div>
            {submitError && <div className="form-error-message">{submitError}</div>}
            <ErrorField name="base" />
            <div className="modal-footer">
              <TableButton
                className="btn btn-light btn-active-light-primary fw-semibold me-2 px-6"
                type="button"
                onClick={onClose}
                title={t('cancel')}
              />
              <TableButton
                className="btn btn-warning text-dark fw-semibold px-6"
                type="submit"
                title={t('create')}
                disabled={submitting || pristine}
              />
            </div>
          </div>
        </form>
      )}
    />
  )
}

export default CreateWorkingHours;
