import React, { useEffect, useState, useRef } from 'react';
import { axiosInstance, routes } from "../../utils/api_base";
import Map from "./map";
import classNames from 'classnames'
import Input from "../Input";
import Dropdown from 'react-bootstrap/Dropdown'
import { PlusIcon, TrashSquareIcon } from '../Icons';
import { useTranslation } from 'react-i18next';
import TableButton from '../../components/Table/TableButton';
import EditableTextInput from '../../components/Table/EditableTextInput';
import Toastr from '../Toastr';
import Upload from './UploadField';
import Modal from '../../components/Modal';

export default function Fields({ showAllFields, businessPartner, selectedField, setTrigger, trigger }) {
  const businessPartnerRef = { current: businessPartner.id }
  const pointRef = useRef({});
  const geometryRef = useRef({});
  const { t } = useTranslation();
  const [fields, setFields] = useState([])
  const [geometryAreas, setGeometryAreas] = useState([])
  const [points, setPoints] = useState([])
  const [hiddenPoint, setHiddenPoint] = useState()
  const [selectedPoint, setSelectedPoint] = useState(false)
  const [selectedId, setSelectedId] = useState()
  const [selectedFigureId, setSelectedFigureId] = useState()
  const [newField, setNewField] = useState(false)
  const [drawing, setDrawing] = useState(false)
  const [drawingPoint, setDrawingPoint] = useState(false)
  const [drawingArea, setDrawingArea] = useState(false)
  const [drawingLine, setDrawingLine] = useState(false)
  const [editPointMode, setEditPointMode] = useState(false)
  const [editGeometryMode, setEditGeometryMode] = useState(false)
  const [showMeasureDistance, setShowMeasureDistance] = useState(false)
  const [showMeasureArea, setShowMeasureArea] = useState(false)
  const [editMode, setEditMode] = useState(false)
  const [modalUploadOpen, setModalUploadOpen] = useState(false)
  const [polyType, setPolyType] = useState('Polygon')
  const [visibleFields, setVisibleFields] = useState(false)
  const [triggerPoints, setTriggerPoints] = useState(Math.floor(Date.now() / 1000))
  const [triggerGeometry, setTriggerGeometry] = useState(Math.floor(Date.now() / 1000))
  const [totalInfo, setTotalInfo] = useState({ fields_count: 0, hectare_count: 0 })

  const fieldRef = useRef({ name: '', comment: '', poly_points: [], business_partner_id: businessPartner.id });
  const newFieldRef = useRef({ name: '', comment: '', poly_points: [], business_partner_id: businessPartner.id });
  const newAreaRef = useRef({ name: '', geometry_type: polyType, geometry_points: [], geometricable_type: 'Field' });

  const linkList = {
    downloadFields: `/fields/download.kml?business_partner=${businessPartner.id}`,
    driverInputs: `/fields/business_partner?business_partner_id=${businessPartner.id}`
  }

  const onFieldClick = (field) => {
    if (!field) return
    fieldRef.current = field
    setEditMode(false)
    setDrawing(false)
    setSelectedPoint(false)
    setSelectedId(field?.id)
    setEditMode(false)
    setSelectedFigureId(false)
    setEditGeometryMode(false)
    newAreaRef.current.geometricable_id = field.id
  }

  const onPointClick = (point) => {
    if (!point) return
    pointRef.current = point
    setSelectedPoint(point)
    setEditPointMode(false)
    setDrawing(false)
    setDrawingArea(false)
    setDrawingLine(false)
    setSelectedId(false)
    setSelectedFigureId(false)
    setEditGeometryMode(false)
  }

  const onGeometryClick = (figure) => {
    if (!figure) return
    setSelectedFigureId(figure.id)
    geometryRef.current = figure
    setEditPointMode(false)
    setSelectedPoint(false)
    setSelectedId(false)
    setDrawingArea(false)
    setDrawingLine(false)
  }

  useEffect(() => {
    if (!selectedField) return
    setVisibleFields([selectedField.id])
    selectField(selectedField)
  }, [selectedField])

  useEffect(() => {
    if (!businessPartnerRef.current) return
    getFields({ pageIndex: 0, pageSize: 10000 }, businessPartnerRef.current).then(result => {
      setFields(result.rows.map(row => row.attributes))
    })
  }, [trigger]);

  useEffect(() => {
    getPoints({ pageIndex: 0, pageSize: 10000 }, businessPartnerRef.current).then(result => {
      setPoints(result.rows.map(row => row.attributes))
    })
  }, [triggerPoints]);

  useEffect(() => {
    getGeometries({ pageIndex: 0, pageSize: 10000 }, businessPartnerRef.current).then(result => {
      setGeometryAreas(result.rows.map(row => row.attributes))
    })
  }, [triggerGeometry]);

  useEffect(() => {
    setShowMeasureArea(false)
    setShowMeasureDistance(false)
    setSelectedPoint(false)
    setDrawingPoint(false)
    setEditPointMode(false)
    setEditMode(false)
    setDrawingArea(false)
    setDrawingLine(false)
    setEditGeometryMode(false)
    setSelectedFigureId(false)
  }, [drawing]);

  useEffect(() => {
    setShowMeasureArea(false)
    setShowMeasureDistance(false)
    setSelectedPoint(false)
    setDrawingPoint(false)
    setEditPointMode(false)
    setEditMode(false)
    setSelectedId()
    setDrawing(false)
    setEditPointMode(false)
  }, [drawingArea, drawingLine]);

  useEffect(() => {
    setShowMeasureArea(false)
    setShowMeasureDistance(false)
    setSelectedId()
    setDrawing(false)
    setEditPointMode(false)
  }, [drawingPoint]);

  const selectField = (field) => {
    field && onFieldClick(field)
  }

  const getFields = async (pagination, businessPartnerId) => {
    const { pageIndex, pageSize } = pagination
    const params = { page: pageIndex + 1, limit: pageSize, business_partner_id: businessPartnerId }
    const res = await axiosInstance.get(
      routes.fields(),
      {
        params: params
      }
    )

    const totalHectares = res.data.data.reduce((total, item) => {
      return total + item.attributes.hectare;
    }, 0);
    setTotalInfo({ fields_count: res.data.meta.total_count, hectare_count: totalHectares })

    return (
      { rows: res.data.data, pageCount: res.data.meta.total_pages, rowCount: res.data.meta.total_count }
    )
  }

  const getGeometries = async (pagination, businessPartnerId) => {
    const { pageIndex, pageSize } = pagination
    const params = { page: pageIndex + 1, limit: pageSize, business_partner_id: businessPartnerId }
    const res = await axiosInstance.get(
      routes.geometries(),
      {
        params: params
      }
    )

    return (
      { rows: res.data.data, pageCount: res.data.meta.total_pages, rowCount: res.data.meta.total_count }
    )
  }

  const getPoints = async (pagination, businessPartnerId) => {
    const { pageIndex, pageSize } = pagination
    const params = { page: pageIndex + 1, limit: pageSize, business_partner_id: businessPartnerId }
    const res = await axiosInstance.get(routes.pois(), { params: params })
    return (
      { rows: res.data.data, pageCount: res.data.meta.total_pages, rowCount: res.data.meta.total_count }
    )
  }

  const onSaveDrawing = () => {
    newFieldRef.current.business_partner_id = businessPartner.id
    axiosInstance.post(routes.fields(), newFieldRef.current).then(response => {
      setNewField(response.data.data.id)
      newFieldRef.current = {}
      fieldRef.current = response.data.data.attributes
      setDrawing(false);
      handleClear();
      setSelectedId(response.data.data.attributes.id)
      setVisibleFields([response.data.data.attributes.id])
    }).catch(error => {
      console.error("Error saving field:", error);
    });
  };

  const onSaveAreaDrawing = () => {
    newAreaRef.current.geometry_type = drawingArea ? 'Polygon' : 'Polyline'
    let data = {
      geometry: newAreaRef.current
    }

    axiosInstance.post(routes.geometries(), data).then(response => {
      Toastr({ message: t('success'), options: { showDuration: 2000 } })
      newAreaRef.current.name = ""
      setTriggerGeometry(Math.floor(Date.now() / 1000))
      setDrawingArea(false)
      setDrawingLine(false)
    }).catch(error => {
      console.error("Error saving field:", error);
    });
  };

  const onCancelUpdateFied = () => {
    handleClear();
    setNewField([])
    setEditMode(false)
  };

  const onCancelUpdateGeometry = () => {
    setEditGeometryMode(false)
  };

  const onCancelUpdatePoint = () => {
    setEditPointMode(false)
  };

  const onUpdateField = (id, attrs) => {
    let params = {
      name: fieldRef.current.name,
      business_partner_id: fieldRef.current.business_partner_id,
      comment: fieldRef.current.comment,
      poly_points: fieldRef.current.poly_points,
    }

    if (attrs) { params = { ...params, ...attrs } }
    axiosInstance.put(routes.field(fieldRef.current.id), params).then(response => {
      handleClear();
      setNewField([])
      setVisibleFields([fieldRef.current.id])
      fieldRef.current = response.data.data.attributes
      setDrawing(false);
      setEditMode(false)
    }).catch(error => {
      console.error("Error saving field:", error);
    });
  };

  const onUpdateGeometry = (id, attrs) => {
    let params = {
      geometry: {
        id: geometryRef.current.id,
        geometry_points: geometryRef.current.geometry_points,
        ...attrs
      }
    }

    axiosInstance.patch(routes.geometry(geometryRef.current.id), params).then(response => {
      Toastr({ message: t('success'), options: { showDuration: 2000 } })
      if (response.data.data) geometryRef.current = response.data.data.attributes
      setTriggerGeometry(Math.floor(Date.now() / 1000))
      setDrawingArea(false)
      setDrawingLine(false)
      setEditGeometryMode(false)
    }).catch(error => {
      console.error("Error saving field:", error);
    });
  };

  const onSavePoint = (id, attrs) => {
    let params = { ...pointRef.current, ...attrs }

    axiosInstance.put(routes.poi(pointRef.current.id), params).then(response => {
      Toastr({ message: t('success'), options: { showDuration: 2000 } })
      pointRef.curent = response.data.data.attributes
      setEditPointMode(false)
      setSelectedPoint(response.data.data.attributes)
      setTriggerPoints(Math.floor(Date.now() / 1000))

    }).catch(error => {
      console.error("Error saving field:", error);
      Toastr({
        type: 'error',
        message: error.response?.data?.error || t('critical_error')
      })
    });
  };

  const onUpdatePolygon = (polys) => {
    fieldRef.current.poly_points = polys;
    newFieldRef.current.poly_points = polys;
    newAreaRef.current.geometry_points = polys;
    geometryRef.current.geometry_points = polys;
  };

  const onUpdatePoint = (point, newPosition) => {
    pointRef.current = {
      ...pointRef.current,
      latitude: newPosition.lat(),
      longitude: newPosition.lng(),
    };
  };

  const onAddPoint = (point) => {
    const params = { ...point, business_partner_id: businessPartner.id }
    axiosInstance.post(routes.pois(), params).then(response => {
      Toastr({ message: t('success'), options: { showDuration: 2000 } })
      setTriggerPoints(Math.floor(Date.now() / 1000))
      pointRef.current = response.data.data.attributes
      setSelectedPoint(response.data.data.attributes)
      setDrawingPoint(false)
    }).catch(error => {
      console.error("Error saving field:", error);
    });
  };

  const enableMeasureDistance = () => {
    setShowMeasureDistance(!showMeasureDistance)
    setShowMeasureArea(false)
  }

  const enableDrawingArea = () => {
    setDrawingArea(!drawingArea)
    setDrawingLine(false)
  }

  const enableDrawingLine = () => {
    setDrawingArea(false)
    setDrawingLine(!drawingLine)
  }

  const deleteSelectedField = (id) => {
    axiosInstance.delete(routes.field(id))
      .then(resp => {
        Toastr({ message: t('success'), options: { showDuration: 2000 } })
        setTrigger(Math.floor(Date.now() / 1000))
        setDrawing(false);
        setEditMode(false);
        setSelectedId(false)
        setVisibleFields([])
      })
      .catch(error => {
        Toastr({
          type: 'error',
          message: error.response?.data?.error || t('critical_error')
        })
      })
  }

  const deleteSelectedGeometry = (id) => {
    axiosInstance.delete(routes.geometry(id))
      .then(resp => {
        Toastr({ message: t('success'), options: { showDuration: 2000 } })
        setTriggerGeometry(Math.floor(Date.now() / 1000))
        setDrawingArea(false)
        setDrawingLine(false)
        setEditGeometryMode(false)
        setSelectedFigureId(false)
      })
      .catch(error => {
        Toastr({
          type: 'error',
          message: error.response?.data?.error || t('critical_error')
        })
      })
  }

  const deleteSelectedPoint = (id) => {
    axiosInstance.delete(routes.poi(id))
      .then(resp => {
        Toastr({ message: t('success'), options: { showDuration: 2000 } })
        setHiddenPoint(selectedPoint)
        setSelectedPoint()
        setEditPointMode(false)
      })
      .catch(error => {
        Toastr({
          type: 'error',
          message: error.response?.data?.error || t('critical_error')
        })
      })
  }

  const enableMeasureArea = () => {
    setShowMeasureArea(!showMeasureArea)
    setShowMeasureDistance(false)
  }

  const handleClear = () => {
    setShowMeasureArea(false)
    setShowMeasureDistance(false)
    setTrigger(Math.floor(Date.now() / 1000))
  }

  const DrawTitle = () => {
    if (selectedFigureId) {
      return t(
        geometryRef.current.geometry_type.toLowerCase() === "polygon" ? "area" :
          geometryRef.current.geometry_type.toLowerCase() === "polyline" ? "line" : ""
      );
    }

    return drawingLine ? t('draw_line') : t('draw_area');
  };

  return (
    <>
      <div className='d-flex justify-content-between'>
        <div className='text-gray-600 align-content-center'>
          {totalInfo.fields_count} <span className='px-1'>{t('fields')}</span>
          ({totalInfo.hectare_count.toFixed(2)} <span className='px-1'>{t('measurement_unit.ha')})</span>
        </div>
        <div className='d-flex justify-content-end gap-3'>
          <TableButton
            className={classNames(showMeasureDistance ? 'btn-success' : '', 'btn btn-light-warning', drawing && 'disabled')}
            title={t('measure_distance')}
            onClick={() => enableMeasureDistance()}
          />
          <TableButton
            className={classNames(showMeasureArea ? 'btn-success' : '', 'btn btn-light-warning', drawing && 'disabled')}
            title={t('measure_area')}
            onClick={() => enableMeasureArea()}
          />
          <Dropdown align={"end"} className="d-inline" id="main-dropdown">
            <Dropdown.Toggle className="btn-light-warning" id="custom-dropdown-autoclose-outside">
              {t('actions')}
            </Dropdown.Toggle>
            <Dropdown.Menu className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-warning fw-semibold py-4 w-250px fs-6'>
              <div className="menu-item px-5">
                <div className="menu-content text-muted pb-2 px-5 fs-7 text-uppercase">
                  {t('actions')}
                </div>
              </div>
              <div className="separator border-gray-200" />
              <div className="menu-item px-5">
                <span
                  onClick={() => setModalUploadOpen(true)}
                  className="menu-link px-5">
                  {t('upload_fields')}
                </span>
              </div>
              <div className="menu-item px-5">
                <a href={linkList.downloadFields} className="menu-link px-5">
                  {t('download_fields')}
                </a>
              </div>
              <div className="menu-item px-5">
                <a href={linkList.driverInputs} className="menu-link px-5">
                  {t('driver_inputs_from_all_fields')}
                </a>
              </div>
            </Dropdown.Menu>
          </Dropdown>
          <Dropdown>
            <Dropdown.Toggle id="dropdown-creation">
              <PlusIcon />
              {t('shared.add_to')}
            </Dropdown.Toggle>
            <Dropdown.Menu className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-warning fw-semibold py-4 w-250px fs-6'>
              <div className="menu-item px-5">
                <div className="menu-content text-muted pb-2 px-5 fs-7 text-uppercase">
                  {t('create_new')}
                </div>
              </div>
              <div className="separator border-gray-200" />
              <Dropdown.Item className="menu-item px-5">
                <span className='menu-link px-5' onClick={() => setDrawing(!drawing)}>{t('field')}</span>
              </Dropdown.Item>
              <Dropdown.Item className="menu-item px-5">
                <span onClick={() => setDrawingPoint(!drawingPoint)} className='menu-link px-5'>{t('point')}</span>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>

      {(drawing || selectedId) && (
        <div className='row'>
          <div className='col-12'>
            <div className="card my-3">
              <div className="card-header ribbon ribbon-top ribbon-vertical cursor-pointer">
                <div className="card-title m-0">
                  <h3 className="fw-bold m-0">{t('field_info')}</h3>
                </div>
              </div>
              {!drawing && selectedId && (
                <div className="card-body border-top p-9">
                  <div className="row">
                    <div className="col-6 align-content-center">
                      <div className="d-flex flex-stack">
                        <div className="d-flex flex-column">
                          <div className="fs-6 d-flex gap-2 fw-semibold text-gray-500">
                            {t('name')}
                            <div onClick={() => deleteSelectedField(fieldRef.current?.id)}>
                              <TrashSquareIcon className="fs-1 text-danger cursor-pointer" />
                            </div>
                          </div>
                        </div>
                        <div className="d-flex justify-content-end">
                          <div className="form-check form-check-solid form-check-custom form-switch">
                            <EditableTextInput
                              id={fieldRef.current.id}
                              attribute="name"
                              initialValue={fieldRef.current.name}
                              handleSubmit={onUpdateField}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-6 align-content-center">
                      <div className="d-flex flex-stack">
                        <div className="d-flex">
                          <div className="d-flex flex-column">
                            <div className="fs-6 fw-semibold text-gray-500">{t('comment_area')}</div>
                          </div>
                        </div>
                        <div className="d-flex justify-content-end">
                          <div className="form-check form-check-solid form-check-custom form-switch">
                            {fieldRef.current.hectare?.toFixed(2)} {t('measurement_unit.ha')}
                            {!editMode && !drawing && fieldRef.current.id && (
                              <TableButton
                                className={classNames('btn btn-warning btn-sm ms-3')}
                                onClick={() => setEditMode(!editMode)}
                                title={t('edit')}
                              />
                            )}
                            {editMode && (
                              <>
                                <TableButton
                                  className='btn btn-warning btn-sm ms-3'
                                  title={t('save')}
                                  onClick={onUpdateField}
                                />
                                <TableButton
                                  className='btn btn-warning btn-sm ms-3'
                                  title={t('cancel')}
                                  onClick={onCancelUpdateFied}
                                />
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="separator separator-dashed my-3"></div>
                  <div className="row">
                    <div className="col-6 align-content-center">
                      <div className="d-flex flex-stack">
                        <div className="d-flex">
                          <div className="d-flex flex-column">
                            <div className="fs-6 fw-semibold text-gray-500">{t('comment_cultivation_variety')}</div>
                          </div>
                        </div>
                        <div className="d-flex justify-content-end">
                          <div className="form-check form-check-solid form-check-custom form-switch">
                            <EditableTextInput
                              id={fieldRef.current.id}
                              attribute="comment"
                              initialValue={fieldRef.current.comment || "-"}
                              handleSubmit={onUpdateField}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-6 align-content-center">
                      <div className="d-flex flex-stack">
                        <div className="d-flex">
                          <div className="d-flex flex-column">
                            <div className="fs-6 fw-semibold text-gray-500">{t('driver_inputs')}</div>
                          </div>
                        </div>
                        <div className="d-flex justify-content-end">
                          <div className="form-check form-check-solid form-check-custom form-switch">
                            <TableButton
                              href={`/fields/${fieldRef.current.id}`}
                              className="btn-warning btn-sm"
                              title={t('driver_inputs')}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="separator separator-dashed my-3"></div>
                  <div className="row">
                    <div className="col-6 align-content-center">
                      <div className="d-flex flex-stack">
                        <div className="d-flex">
                          <div className="d-flex flex-column">
                            <div className="fs-6 fw-semibold text-gray-500">{t('draw')}</div>
                          </div>
                        </div>
                        <div className="d-flex justify-content-end">
                          <div className="form-check form-check-solid form-check-custom form-switch">
                            <TableButton
                              className="btn btn btn-warning btn-sm ms-3"
                              onClick={() => enableDrawingArea()}
                              title={t('area')}
                            />
                            <TableButton
                              className="btn btn btn-warning btn-sm ms-3"
                              onClick={() => enableDrawingLine()}
                              title={t('line')}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {drawing && (
                <div className="card-body border-top p-9">
                  <div className="row">
                    <div className="col-12 align-content-center">
                      <div className="d-flex gap-10">
                        <div className="mb-3 w-100">
                          <Input
                            placeholder={t('name')}
                            className={"form-control"}
                            value={newFieldRef.current.name}
                            onChange={(value) => newFieldRef.current.name = value}
                          />
                        </div>
                        <div className="mb-3 w-100">
                          <Input
                            placeholder={t('comment')}
                            className={"form-control"}
                            value={newFieldRef.current.comment}
                            onChange={(value) => newFieldRef.current.comment = value}
                          />
                        </div>

                      </div>
                      <div className="d-flex justify-content-end w-100">
                        <TableButton
                          className="btn btn-warning"
                          title={t('save')}
                          onClick={() => onSaveDrawing()}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}

      {(drawingArea || drawingLine || selectedFigureId) && (
        <div className='row'>
          <div className='col-12'>
            <div className="card my-3">
              <div className="card-header ribbon ribbon-top ribbon-vertical cursor-pointer">
                <div className="card-title m-0">
                  <h3 className="fw-bold m-0">
                    <DrawTitle />
                  </h3>
                </div>
              </div>

              {!drawingArea && !drawingLine && selectedFigureId && (
                <div className="card-body border-top p-9">
                  <div className="row">
                    <div className="col-6 align-content-center">
                      <div className="d-flex flex-stack">
                        <div className="d-flex flex-column">
                          <div className="fs-6 d-flex gap-2 fw-semibold text-gray-500">
                            {t('name')}
                            <div onClick={() => deleteSelectedGeometry(geometryRef.current?.id)}>
                              <TrashSquareIcon className="fs-1 text-danger cursor-pointer" />
                            </div>
                          </div>
                        </div>
                        <div className="d-flex justify-content-end">
                          <div className="form-check form-check-solid form-check-custom form-switch">
                            <EditableTextInput
                              id={geometryRef.current.id}
                              attribute="name"
                              initialValue={geometryRef.current.name}
                              handleSubmit={onUpdateGeometry}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-6 align-content-center">
                      <div className="d-flex flex-stack">
                        <div className="d-flex">
                          <div className="d-flex flex-column">
                            <div className="fs-6 fw-semibold text-gray-500">{t('place')}</div>
                          </div>
                        </div>
                        <div className="d-flex justify-content-end">
                          <div className="form-check form-check-solid form-check-custom form-switch">
                            {!editGeometryMode && !drawingArea && !drawingLine && geometryRef.current.id && (
                              <TableButton
                                className={classNames('btn btn-warning btn-sm ms-3')}
                                onClick={() => setEditGeometryMode(!editGeometryMode)}
                                title={t('edit')}
                              />
                            )}

                            {editGeometryMode && (
                              <>
                                <TableButton
                                  className='btn btn-warning btn-sm ms-3'
                                  title={t('save')}
                                  onClick={onUpdateGeometry}
                                />
                                <TableButton
                                  className='btn btn-warning btn-sm ms-3'
                                  title={t('cancel')}
                                  onClick={onCancelUpdateGeometry}
                                />
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {(drawingArea || drawingLine) && (
                <div className="card-body border-top p-9">
                  <div className="row">
                    <div className="col-12 align-content-center">
                      <div className="d-flex flex-column align-items-start">
                        <div className="mb-3 w-100">
                          <Input
                            placeholder={t('name')}
                            className={"form-control"}
                            value={newAreaRef.current.name}
                            onChange={(value) => newAreaRef.current.name = value}
                          />
                        </div>
                        <div className="d-flex justify-content-end w-100">
                          <TableButton
                            className="btn btn-warning me-3"
                            title={t('save')}
                            onClick={() => onSaveAreaDrawing()}
                          />
                          <TableButton
                            className="btn btn-warning"
                            title={t('cancel')}
                            onClick={() => setDrawingArea(false)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}

      {selectedPoint && (
        <div className='row'>
          <div className='col-12'>
            <div className="card my-3">
              <div className="card-header ribbon ribbon-top ribbon-vertical cursor-pointer">
                <div className="card-title m-0">
                  <h3 className="fw-bold m-0">{t('point_info')}</h3>
                </div>
              </div>
              <div className="card-body border-top p-9">
                <div className="row">
                  <div className="col-6 align-content-center">
                    <div className="d-flex flex-stack">
                      <div className="d-flex flex-column">
                        <div className="fs-6 d-flex gap-2 fw-semibold text-gray-500">
                          {t('name')}
                          <div onClick={() => deleteSelectedPoint(selectedPoint.id)}>
                            <TrashSquareIcon className="fs-1 text-danger cursor-pointer" />
                          </div>
                        </div>
                      </div>
                      <div className="d-flex justify-content-end">
                        <div className="form-check form-check-solid form-check-custom form-switch">
                          <EditableTextInput
                            id={selectedPoint?.id}
                            attribute="name"
                            initialValue={selectedPoint?.name || '_'}
                            handleSubmit={onSavePoint}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-6 align-content-center">
                    <div className="d-flex flex-stack">
                      <div className="d-flex">
                        <div className="d-flex flex-column">
                          <div className="fs-6 fw-semibold text-gray-500">{t('place')}</div>
                        </div>
                      </div>
                      <div className="d-flex justify-content-end">
                        <div className="form-check form-check-solid form-check-custom form-switch">
                          {!editPointMode && !drawingPoint && selectedPoint && (
                            <TableButton
                              className={classNames('btn btn-warning btn-sm ms-3')}
                              title={t('edit')}
                              onClick={() => setEditPointMode(!editPointMode)}
                            />
                          )}
                          {editPointMode && (
                            <>
                              <TableButton
                                className={classNames('btn btn-warning btn-sm ms-3')}
                                title={t('save')}
                                onClick={onSavePoint}
                              />
                              <TableButton
                                className={classNames('btn btn-warning btn-sm ms-3')}
                                title={t('cancel')}
                                onClick={onCancelUpdatePoint}
                              />
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <Modal className="modal fade show" open={modalUploadOpen} onClose={() => setModalUploadOpen(false)} title={t('upload_fields')}>
        <Upload
          businessPartnerId={businessPartner.id}
          setTrigger={setTrigger}
          onClose={() => setModalUploadOpen(false)}
        />
      </Modal>
      <Map
        geometryAreas={geometryAreas}
        businessPartnerRef={businessPartnerRef}
        drawPolygonEnabled={drawing}
        geometryCreationType={true}
        drawingArea={drawingArea}
        editGeometryMode={editGeometryMode}
        selectedFigureId={selectedFigureId}
        onGeometryClick={onGeometryClick}
        drawingLine={drawingLine}
        drawing={drawing}
        drawingPoint={drawingPoint}
        editMode={editMode}
        editPointMode={editPointMode}
        hiddenPoint={hiddenPoint}
        fields={fields}
        newField={newField}
        onAddPoint={onAddPoint}
        onClick={onFieldClick}
        onPointClick={onPointClick}
        visibleFields={visibleFields}
        onUpdatePoint={onUpdatePoint}
        onUpdatePolygon={onUpdatePolygon}
        points={points}
        selectedId={selectedId}
        selectedPoint={selectedPoint}
        setEditMode={setEditMode}
        setSelectedId={setSelectedId}
        showAllFields={showAllFields}
        showMeasureArea={showMeasureArea}
        showMeasureDistance={showMeasureDistance}
        trigger={trigger}
      />
    </>
  );
}
