import React from 'react';
import { useTranslation } from 'react-i18next';
import * as style from './index.module.css';

const Legend = () => {
  const { t } = useTranslation()

  return (
    <div className="d-flex row mt-6">
      <div className="col-12 col-lg-12">
        <div className="card">
          <div className="d-flex justify-content-between align-items-baseline card-body p-1 me-6 ms-6">
            <div>
              <div className="py-2 fs-7 mb-2">
                <span className={`${style['square-bullet']} ${style['working-time']}`}></span>
                {t('booking_codes.cost_work_time')}
              </div>
              <div className="py-2 fs-7">
                <span className={`${style['square-bullet']} ${style['setting-up-time']}`}></span>
                {t('booking_codes.cost_setting_up_time')}
              </div>
              <div className="py-2 fs-7 mt-2">
                <span className={`${style['square-bullet']} ${style['travel-time']}`}></span>
                {t('booking_codes.cost_travel_time')}
              </div>
            </div>
            <div>
              <div className="py-2 fs-7 mb-2">
                <span className={`${style['square-bullet']} ${style['repair-time']}`}></span>
                {t('booking_codes.cost_repair_time')}
              </div>
              <div className="py-2 fs-7">
                <span className={`${style['square-bullet']} ${style['waiting-time']}`}></span>
                {t('booking_codes.cost_wait_time')}
              </div>
              <div className="py-2 fs-7 mt-2">
                <span className={`${style['square-bullet']} ${style['washing-time']}`}></span>
                {t('booking_codes.cost_wash_time')}
              </div>
            </div>
            <div>
              <div className="py-2 fs-7 mb-2">
                <span className={`${style['square-bullet']} ${style['other-time']}`}></span>
                {t('booking_codes.cost_other_time')}
              </div>
              <div className="py-2 fs-7">
                <span className={`${style['square-bullet']} ${style['pause-time']}`}></span>
                {t('booking_codes.break_time')} {`(${t('pause_explanation')})`}
              </div>
              <div className="py-2 fs-7 mt-2">
                <span className={`${style['square-bullet']} ${style['last-gps-point']}`}></span>
                {t('last_position_explanation')}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div >
  );
};

export default Legend;
