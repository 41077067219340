import BaseTable from '../../components/Table';
import Create from '../../components/BusinessPartners/Create';
import Modal from '../../components/Modal';
import React, { useMemo, useState } from 'react';
import Search from './search';
import TableButton from '../../components/Table/TableButton';
import { PlusIcon } from '../../components/Icons';
import { axiosInstance, routes } from '../../utils/api_base';
import { isEmpty } from 'lodash';
import { pushToHistory } from '../../utils/base';
import { useTranslation } from 'react-i18next';

const BusinessPartnersSubcontractors = ({ filters }) => {
  const [modalOpen, setModalOpen] = useState(false)
  const [trigger, setTrigger] = useState()
  const { t, i18n } = useTranslation()

  const tableToolbar = () => {
    return (
      <TableButton
        className="btn-warning me-4"
        onClick={() => setModalOpen(true)}
        icon={<PlusIcon />}
        title={t('shared.add_to')}
      />
    )
  }

  const getSubcontractors = async (pagination, sorting, globalFilter, customFilters) => {
    const { pageIndex, pageSize } = pagination
    const sortQuery = sorting.map((attr) => {
      return attr.desc ? `${attr.id} desc` : attr.id
    })
    let params = { page: pageIndex + 1, limit: pageSize, sort: sortQuery[0] }
    if (globalFilter) {
      params.query = globalFilter
    }
    if (!isEmpty(customFilters)) {
      params = { ...params, ...customFilters }
    }
    const res = await axiosInstance.get(routes.businessPartnersSubcontractors(), { params: params })
    pushToHistory(params)
    return (
      { rows: res.data.data, pageCount: res.data.meta.total_pages, rowCount: res.data.meta.total_count }
    )
  }

  const codeHtml = (row) => {
    return (
      <TableButton
        target="_blank"
        href={`/business_partners/${row?.original?.id}`}
        className="btn-light-warning me-3"
        title={row?.original?.attributes?.client_specific_number}
      />
    )
  }

  const columns = useMemo(
    () => [
      {
        id: 'code',
        header: t('shared.number'),
        accessorFn: row => row?.attributes?.client_specific_number,
        size: 70,
        minSize: 70,
        enableSorting: true,
        cell: ({ row }) => {
          return codeHtml(row)
        },
      },
      {
        id: 'name',
        minSize: 70,
        size: 70,
        header: t('name'),
        accessorFn: row => row?.attributes?.name,
        cell: row => row.getValue(),
      },
      {
        id: 'city',
        size: 70,
        minSize: 70,
        header: t('city'),
        accessorFn: row => row?.attributes?.city,
        cell: row => row.getValue(),
      },
      {
        id: 'staffs',
        size: 70,
        minSize: 70,
        header: t('staff'),
        accessorFn: row => row?.attributes?.staff_count,
        cell: row => row.getValue(),
      },
      {
        id: 'machines',
        size: 70,
        minSize: 70,
        header: t('machine_group_for_service_features.machinery'),
        accessorFn: row => row?.attributes?.machine_count,
        cell: row => row.getValue(),
      }
    ],
    [i18n.language]
  )

  return (
    <>
      <div className="notice d-flex bg-light-warning rounded border-warning border border-dashed p-6 mb-5">
        <div className="d-flex flex-stack flex-grow-1">
          <div className="fw-semibold">
            <p className="fs-6 text-gray-700">{t('subcontractor_alert_one')}</p>
            <p className="fs-6 text-gray-700">{t('subcontractor_alert_two')}</p>
            <p className="fs-6 text-gray-700 mb-0">{t('subcontractor_alert_three')}</p>
          </div>
        </div>
      </div>
      <Search />
      <Modal className="modal fade show modal-lg" open={modalOpen} onClose={() => setModalOpen(false)} title={t('new_subcontractor')}>
        <Create setTrigger={setTrigger} attr='sub_contractor' onClose={() => setModalOpen(false)} />
      </Modal>
      <BaseTable
        columns={columns}
        columnVisibility={{}}
        getDataFn={getSubcontractors}
        tableToolbar={tableToolbar}
        filters={filters}
        trigger={trigger}
      />
    </>
  )
}

export default BusinessPartnersSubcontractors;
