import React from 'react';
import { Chart } from "react-google-charts";
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import * as style from './index.module.css'

const WorkingTimeDistributionChart = ({bookingCodes}) => {
  const { t } = useTranslation();
  const chartData = bookingCodes.map(item => {
    return [t(`booking_codes.${item.code}`), {v: item.quantity * 3600, f: `${moment.utc(item.quantity * 3600 * 1000).format('HH:mm')}`}]
  });

  chartData.unshift(['Code', 'Time'])

  const options = {
    title: t('working_time_distribution'),
    colors: bookingCodes.map(item => item.color),
    legend: 'none',
  };

  const legendHtml = () => {
    return(
      <table className="table align-middle table-row-dashed fs-6 gy-5 dataTable">
        <tbody className="fw-semibold text-gray-600">
          {bookingCodes.map((item, index) => (
            <tr key={index}>
              <td>
                <div style={{ backgroundColor: `#${item.color}` }} className={style['color-block']}>
                </div>
              </td>
              <td>
                <span className="legend-symbol bg-light me-2"/>
                {t(`booking_codes.${item.code}`)}
              </td>
              <td>
                {moment.utc(item.quantity * 3600 * 1000).format('HH:mm')}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    )
  }

  return (
    <div>
      <Chart
        chartType="PieChart"
        data={chartData}
        options={options}
        width={"100%"}
        height={"400px"}
      />
      {legendHtml()}
    </div>
  );
};

export default WorkingTimeDistributionChart;
