import React, { useState } from "react";
import { useTranslation } from 'react-i18next';
import PersonalDetails from './PersonalDetails';
import TableButton from "../../Table/TableButton";
import { PlusIcon } from '../../Icons';
import ChildrenTable from "./ChildrenTable";
import Modal from "../../Modal";
import CreateChild from "./CreateChildren";
import DocumentsTable from "../DocumentsTable";
import CreateDocument from "../CreateDocument";
import WagesTable from "./WagesTable";
import CreateWage from "./CreateWage";

const PersonalData = ({ staff, customizing, updateStaff, setStaff }) => {
  const { t } = useTranslation();
  const [childrenModalOpen, setChildrenModalOpen] = useState(false);
  const [childrenTrigger, setChildrenTrigger] = useState(false);
  const [documentModalOpen, setDocumentModalOpen] = useState(false);
  const [documentsTrigger, setDocumentsTrigger] = useState(false);
  const [wageModalOpen, setWageModalOpen] = useState(false);
  const [wageTrigger, setWageTrigger] = useState(false);

  const ChildrenTableToolbar = () => {
    return (
      <TableButton
        className="btn-warning"
        onClick={() => setChildrenModalOpen(true)}
        icon={<PlusIcon />}
        title={t('new')}
      />
    )
  }

  const DocumentsTableToolbar = () => {
    return (
      <TableButton
        className="btn-warning"
        onClick={() => setDocumentModalOpen(true)}
        icon={<PlusIcon />}
        title={t('new')}
      />
    )
  }

  const WageTableToolbar = () => {
    return (
      <TableButton
        className="btn-warning"
        onClick={() => setWageModalOpen(true)}
        icon={<PlusIcon />}
        title={t('new')}
      />
    )
  }

  return (
    <>
      <PersonalDetails staff={staff} customizing={customizing} updateStaff={updateStaff} setStaff={setStaff} />
      <>
        <Modal className="modal-lg" open={childrenModalOpen} onClose={() => setChildrenModalOpen(false)} title={t('add_child')}>
          <CreateChild
            onClose={() => setChildrenModalOpen(false)}
            setTrigger={setChildrenTrigger}
            staffId={staff.id}
          />
        </Modal>
        <ChildrenTable staffId={staff.id} tableToolbar={ChildrenTableToolbar} setTrigger={setChildrenTrigger} trigger={childrenTrigger} />
      </>
      <div className="mt-6">
        <Modal className="modal-lg" open={documentModalOpen} onClose={() => setDocumentModalOpen(false)} title={t('documents')}>
          <CreateDocument
            onClose={() => setDocumentModalOpen(false)}
            setTrigger={setDocumentsTrigger}
            resourceId={staff.id}
            onlyHr={true}
          />
        </Modal>
        <DocumentsTable
          tableToolbar={DocumentsTableToolbar}
          setTrigger={setDocumentsTrigger}
          trigger={documentsTrigger}
          onlyHr={true}
          documentFor={"personalData"}
          resourceId={staff.id}
        />
      </div>
      <div className="mt-6">
        <Modal className="modal fade show" open={wageModalOpen} onClose={() => setWageModalOpen(false)} title={t('qualifications')}>
          <CreateWage
            onClose={() => setWageModalOpen(false)}
            setTrigger={setWageTrigger}
            staff={staff}
          />
        </Modal>
        <WagesTable
          staff={staff}
          tableToolbar={WageTableToolbar}
          setTrigger={setWageTrigger}
          trigger={wageTrigger}
        />
      </div>
    </>
  );
};

export default PersonalData;
