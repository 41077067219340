import React, { useState, useMemo, useEffect } from 'react'
import { Skeleton } from '../../core/Skeleton';
import { useTranslation } from 'react-i18next';
import consumer from '../../utils/cable';

import {
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'

import {
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table'

const queryClient = new QueryClient()

const Table = ({ columns, data, subscription, columnVisibility = {}, tableAlign = "middle" }) => {
  const { t } = useTranslation();
  const [tableData, setTableData] = useState(data);
  const [isLoading, setIsLoading] = useState(true);

  const updateDataState = (active_ids, record) => {
    setTableData((prevTableData) => {
      const updatedTableData = prevTableData.filter(item => active_ids.includes(Number(item.id)));
      const existingRecord = updatedTableData.findIndex(item => item.id === record.id);

      if (existingRecord !== -1) {
        const updatedTableData = [...prevTableData];

        if (Array.isArray(record.attributes.inprocess_bookings_data)) {
          updatedTableData[existingRecord] = {
            ...record,
            attributes: {
              ...record.attributes,
              inprocess_bookings_data: [
                ...record.attributes.inprocess_bookings_data
                  .sort((a, b) => a.start_time - b.start_time)
              ]
            }
          };
        } else {
          updatedTableData[existingRecord] = record;
        }

        return updatedTableData;
      } else {
        return [...prevTableData, record];
      }
    })
  };

  useEffect(() => {
    setTableData(data);
    setIsLoading(false);
  }, [data]);

  useEffect(() => {
    consumer.subscriptions.create(subscription,
      {
        connected: () => console.log('Table connected for:', subscription),
        disconnected: () => console.log('Table disconnected'),
        received: (data) => {
          const record = JSON.parse(data.data).data

          updateDataState(data.active_ids, record)
        },
      })
    return () => {
      consumer.disconnect()
    }
  }, [])

  const tableColumns = useMemo(
    () =>
      isLoading
        ? columns.map((column) => ({
          ...column,
          cell: <Skeleton />,
        }))
        : columns,
    [isLoading, columns]
  )

  const table = useReactTable({
    data: tableData,
    columns: tableColumns,
    getCoreRowModel: getCoreRowModel(),
    state: {
      columnVisibility: columnVisibility
    },
  })

  const hasRows = table.getRowModel().rows.length > 0;

  return (
    <div className="card card-flush">
      <div className="card-body pt-0">
        <div id="kt_ecommerce_sales_table_wrapper" className="dt-container dt-bootstrap5 dt-empty-footer">
          <div className="row mt-2 justify-content-md-center">
            <div className="col-12 table-responsive">
              <table className={`table align-${tableAlign} table-row-dashed fs-6 gy-5 dataTable`}>
                <thead>
                  {table.getHeaderGroups().map(headerGroup => (
                    <tr className="text-start text-gray-500 fw-bold fs-7 text-uppercase gs-0" key={headerGroup.id}>
                      {headerGroup.headers.map(header => (
                        <th key={header.id} colSpan={header.colSpan} {...header.column.columnDef.meta?.headerProps}>
                          {header.isPlaceholder ? null : (
                            <div>
                              {flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                              )}
                            </div>
                          )}
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody className="fw-semibold text-gray-600">
                  {hasRows ? (
                    table.getRowModel().rows.map(row => (
                      <tr key={row.id}>
                        {row.getVisibleCells().map(cell => {
                          return (
                            <td key={cell.id} {...cell.column.columnDef.meta?.cellProps}>
                              {flexRender(cell.column.columnDef.cell, cell.getContext())}
                            </td>
                          )
                        })}
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={table.getAllColumns().length} className="text-center">
                        {t('shared.no_data')}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const BaseTable = (props) => {

  return (
    <QueryClientProvider client={queryClient}>
      <Table {...props} />
    </QueryClientProvider>
  )
}

export default BaseTable
