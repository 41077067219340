import CurrencyInput from "../../components/Table/EditableCurrencyInput";
import EditableDatePickerInput from '../../components/Table/EditableDatePickerInput';
import EditableSelectInput from "../../components/Table/EditableSelectInput";
import EditableTextInput from "../../components/Table/EditableTextInput";
import Form from 'react-bootstrap/Form';
import React, { useState, useEffect } from 'react';
import TableButton from '../../components/Table/TableButton';
import Toastr from '../../components/Toastr';
import classNames from 'classnames';
import moment from "moment";
import { PlusIcon, RankingIcon } from '../../components/Icons';
import { QuestionIcon } from '../Icons';
import { Tooltip } from 'bootstrap'
import { axiosInstance, routes } from '../../utils/api_base';
import { useTranslation } from 'react-i18next';
import ToggleSwitch from '../ToggleSwitch';

const DetailsPanel = ({ businessPartner, country, paymentTerms, solvency, solvencies, orderLimitChangedBy, solvencyChangedBy }) => {
  const { t, i18n } = useTranslation()
  const [showAdditionlAddres, setShowAdditionlAddres] = useState(
    businessPartner.farm_street?.length > 1
  )
  const dateFormat = i18n.language === "de" ? "YYYY.MM.DD" : "YYYY-MM-DD";
  const appStore = ReactOnRails.getStore("appStore");
  const { currentCustomizing } = appStore.getState()?.appStore || {};

  useEffect(() => {
    const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]')
    const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new Tooltip(tooltipTriggerEl))
  }, [currentCustomizing.plant_waste]);


  const findPaymentTerms = () => {
    if (!paymentTerms) return {}
    const termItem = paymentTerms.find(item => item.value === businessPartner.payment_term_id)
    return termItem
  }

  const solvencyTranslateKey = (name) => {
    switch (name) {
      case "keine Auskunft möglich":
        return "keine-auskunft-moglich"
      case "Kredit gegen Sicherheit":
        return "kredit-gegen-sicherheit"
      case "kreditwürdig":
        return "kreditwurdig"
      case "sehr gute bis gute Bonität":
        return "sehr-gute-bis-gute-bonitat"
    }
  };

  const [businessPartnerDetails, setBusinessPartnerDetails] = useState({
    form_of_address: businessPartner.form_of_address,
    street: businessPartner.street,
    postal_code: businessPartner.postal_code,
    tax_number: businessPartner.tax_number,
    comment: businessPartner.comment,
    discount_perc: businessPartner.discount_perc,
    city: businessPartner.city,
    fon: businessPartner.fon,
    fax: businessPartner.fax,
    email: businessPartner.email,
    order_limit_cents: businessPartner.order_limit_cents,
    order_limit: businessPartner.order_limit,
    contract_renewal_reminder_date: businessPartner.contract_renewal_reminder_date,
    material_purchase_discount_perc: businessPartner.material_purchase_discount_perc,
    material_sell_discount_perc: businessPartner.material_sell_discount_perc,
    net_days: businessPartner.net_days,
    cash_discount_perc: businessPartner.cash_discount_perc,
    cash_discount_days: businessPartner.cash_discount_days,
    hit_zid_number: businessPartner.hit_zid_number,
    uid: businessPartner.uid,
    vvo_number: businessPartner.vvo_number,
    customizings_sustainably_produced: businessPartner.customizings?.sustainably_produced,
    company_number: businessPartner.company_number,
    iban: businessPartner.iban,
    farm_street: businessPartner.farm_street,
    farm_city: businessPartner.farm_city,
    bic: businessPartner.bic,
    farm_postal_code: businessPartner.farm_postal_code,
    country: { value: country?.id, label: country?.name },
    payment_terms: findPaymentTerms(),
    solvency: solvency ? { value: solvency?.id, originalName: solvency?.name, label: t('solvency_list.' + solvencyTranslateKey(solvency?.name)) } : {}
  });

  const updateBusinessPartner = (id, attrs) => {
    const params = {
      business_partner: {
        form_of_address: attrs.form_of_address,
        street: attrs.street,
        postal_code: attrs.postal_code,
        city: attrs.city,
        fon: attrs.fon,
        hit_zid_number: attrs.hit_zid_number,
        fax: attrs.fax,
        bic: attrs.bic,
        iban: attrs.iban,
        email: attrs.email,
        uid: attrs.uid,
        order_limit: attrs.order_limit,
        comment: attrs.comment,
        contract_renewal_reminder_date: attrs.contract_renewal_reminder_date,
        material_purchase_discount_perc: attrs.material_purchase_discount_perc,
        material_sell_discount_perc: attrs.material_sell_discount_perc,
        cash_discount_days: attrs.cash_discount_days,
        cash_discount_perc: attrs.cash_discount_perc,
        net_days: attrs.net_days,
        discount_perc: attrs.discount_perc,
        payment_term_id: attrs.payment_terms?.value,
        vvo_number: attrs.vvo_number,
        company_number: attrs.company_number,
        farm_street: attrs.farm_street,
        farm_city: attrs.farm_city,
        farm_postal_code: attrs.farm_postal_code,
        tax_number: attrs.tax_number,
        country_id: attrs.country?.value,
        solvency_id: attrs.solvency?.value,
        customizings: {
          sustainably_produced: attrs.customizings_sustainably_produced
        }
      }
    }

    axiosInstance.put(routes.businessPartner(id), params)
      .then(resp => {
        Toastr({ message: t('success'), options: { showDuration: 2000 } })

        setBusinessPartnerDetails((prevDetails) => ({
          ...prevDetails,
          ...attrs,
        }));
      })
      .catch(error => {
        Toastr({
          type: 'error',
          message: error.response?.data?.error || t('critical_error')
        })
      })
  }

  const toggleHtml = (attribute) => {
    const isActive = businessPartnerDetails[attribute]
    const label = isActive ? t('true') : t('false')
    return (
      <ToggleSwitch
        id="combination-active-switch"
        handleToggle={() => updateBusinessPartner(businessPartner.id, { [attribute]: !isActive })}
        attribute={attribute}
        active={isActive}
        className="cursor-pointer"
        label={label}
      />
    )
  }

  const getSolvencyOptions = async () => {
    return await preparedSolvencyOptions()
  };

  const defineRaitingColorClass = () => {
    switch (businessPartnerDetails.solvency?.originalName) {
      case "keine Auskunft möglich":
        return "text-gray"
      case "Kredit gegen Sicherheit":
        return "text-danger"
      case "kreditwürdig":
        return "text-warning"
      case "sehr gute bis gute Bonität":
        return "text-success"
    }
  };

  const getPaymentTerms = async () => {
    return await paymentTerms.slice(1)
  };

  const preparedSolvencyOptions = () => {
    return solvencies.map((solvency) => ({
      value: solvency[0],
      label: t('solvency_list.' + solvencyTranslateKey(solvency[1])),
      originalName: solvency[1]
    }))
  }

  const formatedDate = (date) => {
    return moment(date).format(dateFormat);
  }

  const getCountries = (inputText) => {
    let params = {
      page: 1,
      limit: 20,
      query: inputText
    }

    return axiosInstance.get(routes.countries(), { params: params })
      .then((response) => {
        let options = response.data.data.map((locations) => ({
          value: locations.attributes.id,
          label: locations.attributes.name,
        }))
        options.push({
          value: '',
          label: t('no_selection')
        })

        return options
      })
      .catch((error) => {
        console.log('ERROR', error)
      });
  };

  const switchDisplayAdditionlAddress = (value) => {
    setShowAdditionlAddres(value)
    const attrs = {
      farm_street: '',
      farm_postal_code: '',
      farm_city: ''
    }

    if (!value) {
      updateBusinessPartner(businessPartner.id, attrs)
    }
  }

  const centsToAmount = (cents) => {
    return businessPartner.order_limit
      ? businessPartner.order_limit
      : new Intl.NumberFormat('de-DE', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(cents / 10000);

  };

  return (
    <>
      <div className="d-flex row">
        <div className="col-12 col-lg-6">
          <div className="card mb-5 mb-xl-8">
            <div className="card-body pt-2">
              <div className="py-2 fs-6">
                <div className="fw-bold mb-5" >
                  {t('billing_address')}
                </div>
                <div className="separator my-3"></div>
                <div className='d-flex flex-stack'>
                  <div className="fw-bold">{
                    t('salutation')}
                    <span
                      data-bs-toggle="tooltip"
                      data-bs-custom-class="tooltip-inverse"
                      data-bs-placement="top"
                      title={t('example_company_farmer')}
                      className="btn p-0 text-hover-warning"
                    >
                      <QuestionIcon className={"fs-4"} />
                    </span>
                  </div>
                  <div className="text-gray-600">
                    <EditableTextInput
                      id={businessPartner.id}
                      attribute="form_of_address"
                      placeholder={t('salutation')}
                      initialValue={businessPartnerDetails.form_of_address}
                      handleSubmit={updateBusinessPartner}
                    />
                  </div>

                </div>
                <div className="separator separator-dashed my-3"></div>
                <div className='d-flex flex-stack'>
                  <div className="fw-bold ">{t('street')}</div>
                  <div className="text-gray-600">
                    <EditableTextInput
                      id={businessPartner.id}
                      attribute="street"
                      placeholder={t('street')}
                      initialValue={businessPartnerDetails.street}
                      handleSubmit={updateBusinessPartner}
                    />
                  </div>
                </div>
                <div className="separator separator-dashed my-3"></div>

                <div className='d-flex flex-stack'>
                  <div className="fw-bold ">{t('postcode')}</div>
                  <div className="text-gray-600">
                    <EditableTextInput
                      id={businessPartner.id}
                      attribute="postal_code"
                      placeholder={t('postcode')}
                      initialValue={businessPartnerDetails.postal_code}
                      handleSubmit={updateBusinessPartner}
                    />
                  </div>
                </div>
                <div className="separator separator-dashed my-3"></div>

                <div className='d-flex flex-stack'>
                  <div className="fw-bold ">{t('city')}</div>
                  <div className="text-gray-600">
                    <EditableTextInput
                      id={businessPartner.id}
                      attribute="city"
                      placeholder={t('city')}
                      initialValue={businessPartnerDetails.city}
                      handleSubmit={updateBusinessPartner}
                    />
                  </div>
                </div>
                <div className="separator separator-dashed my-3"></div>

                <div className='d-flex flex-stack'>
                  <div className="fw-bold ">{t('country')}</div>
                  <div className="text-gray-600">
                    <EditableSelectInput
                      id={businessPartner.id}
                      attribute={'country'}
                      placeholder={t('country')}
                      returnObject
                      initialValue={{ value: businessPartnerDetails.country?.value, label: businessPartnerDetails.country?.label }}
                      handleSubmit={updateBusinessPartner}
                      getEntities={getCountries}
                    />
                  </div>
                </div>
                <div className="separator separator-dashed my-3"></div>

                <div className='d-flex flex-stack'>
                  <div className="fw-bold ">{t('phone')}</div>
                  <div className="text-gray-600">
                    <EditableTextInput
                      id={businessPartner.id}
                      attribute="fon"
                      placeholder={t('phone')}
                      initialValue={businessPartnerDetails.fon}
                      handleSubmit={updateBusinessPartner}
                    />
                  </div>
                </div>
                <div className="separator separator-dashed my-3"></div>

                <div className='d-flex flex-stack'>
                  <div className="fw-bold ">{t('fax')}</div>
                  <div className="text-gray-600">
                    <EditableTextInput
                      id={businessPartner.id}
                      attribute="fax"
                      placeholder={t('fax')}
                      initialValue={businessPartnerDetails.fax}
                      handleSubmit={updateBusinessPartner}
                    />
                  </div>
                </div>
                <div className="separator separator-dashed my-3"></div>
                <div className='d-flex flex-stack'>
                  <div className="fw-bold ">{t('email')}</div>
                  <div className="text-gray-600">
                    <EditableTextInput
                      id={businessPartner.id}
                      attribute="email"
                      placeholder={t('email')}
                      initialValue={businessPartnerDetails.email}
                      handleSubmit={updateBusinessPartner}
                    />
                  </div>
                </div>
                <div className="separator separator-dashed my-3"></div>
                <div className='d-flex flex-stack'>
                  {!showAdditionlAddres ? (
                    <>
                      <div className="fw-bold ">{t('different_court_address')}</div>
                      <TableButton
                        onClick={() => switchDisplayAdditionlAddress(true)}
                        className="btn-warning btn-sm p-1"
                        icon={<PlusIcon />}
                        title={t('shared.add_to')}
                      />
                    </>
                  ) : (
                    <>
                      <div className="fw-bold ">{t('court_address')}</div>
                      <TableButton
                        onClick={() => switchDisplayAdditionlAddress(false)}
                        className="btn-warning btn-sm p-1"
                        title={t('same_as_billing_address')}
                      />
                    </>
                  )}
                </div>
                {showAdditionlAddres && (
                  <>
                    <div className="separator separator-dashed my-3"></div>
                    <div className='d-flex flex-stack'>
                      <div className="fw-bold ">{t('street')}</div>
                      <div className="text-gray-600">
                        <EditableTextInput
                          id={businessPartner.id}
                          attribute="farm_street"
                          placeholder={t('street')}
                          initialValue={businessPartnerDetails.farm_street?.trim() == '' ? '' : businessPartnerDetails.farm_street}
                          handleSubmit={updateBusinessPartner}
                        />
                      </div>
                    </div>
                    <div className="separator separator-dashed my-3"></div>

                    <div className='d-flex flex-stack'>
                      <div className="fw-bold ">{t('postcode')}</div>
                      <div className="text-gray-600">
                        <EditableTextInput
                          id={businessPartner.id}
                          attribute="farm_postal_code"
                          placeholder={t('postcode')}
                          initialValue={businessPartnerDetails.farm_postal_code}
                          handleSubmit={updateBusinessPartner}
                        />
                      </div>
                    </div>
                    <div className="separator separator-dashed my-3"></div>
                    <div className='d-flex flex-stack'>
                      <div className="fw-bold ">{t('shared.city')}</div>
                      <div className="text-gray-600">
                        <EditableTextInput
                          id={businessPartner.id}
                          attribute="farm_city"
                          placeholder={t('shared.city')}
                          initialValue={businessPartnerDetails.farm_city}
                          handleSubmit={updateBusinessPartner}
                        />
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>

          {(currentCustomizing.use_solvency_in_business_partners || currentCustomizing.use_order_limits) && (
            <>
              <div className="card mb-5 mb-xl-8">
                <div className="card-body pt-2">
                  {currentCustomizing.use_solvency_in_business_partners && (
                    <>
                      <div className='d-flex flex-stack'>
                        <div className="fw-bold me-2">{t('credit_rating')}
                          <RankingIcon className={classNames('fs-2', defineRaitingColorClass())} />
                        </div>
                        <div className="text-gray-600">
                          <EditableSelectInput
                            id={businessPartner.id}
                            attribute={'solvency'}
                            returnObject
                            placeholder={t('credit_rating')}
                            initialValue={{ value: businessPartnerDetails.solvency?.value, label: businessPartnerDetails.solvency?.label }}
                            handleSubmit={updateBusinessPartner}
                            getEntities={getSolvencyOptions}
                          />
                        </div>
                      </div>
                      {businessPartner.solvency_id && (
                        <span className='text-gray-500 mt-1 fw-semibold fs-9'>
                          {businessPartner.solvency_changed_at && (
                            <>
                              {t('at_the_date', { date: formatedDate(businessPartner.solvency_changed_at) })}
                            </>
                          )}
                          <br />
                          {businessPartner.solvency_changed_by_id && t('through_name', { name: solvencyChangedBy?.name })}
                        </span>
                      )}
                    </>
                  )}
                  {currentCustomizing.use_order_limits && (
                    <>
                      <div className="separator separator-dashed my-3"></div>
                      <div className='d-flex flex-stack'>
                        <div className="fw-bold ">{t('total_limit')}</div>
                        <div className="text-gray-600 d-inline-flex">
                          <CurrencyInput
                            id={businessPartner.id}
                            attribute="order_limit"
                            initialValue={businessPartnerDetails.order_limit || businessPartnerDetails.order_limit_cents / 10000}
                            label={businessPartnerDetails.order_limit || centsToAmount(businessPartnerDetails.order_limit_cents)}
                            handleSubmit={updateBusinessPartner}
                          />
                          <span className="ms-1">{businessPartner?.order_limit_currency}</span>
                        </div>
                      </div>
                      {businessPartner.order_limit_changed_at && (
                        <span className='text-gray-500 mt-1 fw-semibold fs-9'>
                          {t('at_the_date', { date: formatedDate(businessPartner.order_limit_changed_at) })}
                          <br />
                          {businessPartner.order_limit_changed_by_id && t('through_name', { name: orderLimitChangedBy?.name })}
                        </span>
                      )}
                      <div className="separator separator-dashed my-3"></div>
                      <div className='d-flex flex-stack'>
                        <div className="fw-bold ">{t('utilized_limit')}</div>
                        <div className="text-gray-600">
                          <div className="d-block">
                            {businessPartnerDetails.used_order_limit || '0.00'}
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </>
          )}
        </div>
        <div className="col-12 col-lg-6">
          <div className="card mb-5 mb-xl-8">
            <div className="card-body pt-2">
              <div className="py-2 fs-6">
                <div className='d-flex flex-stack'>
                  <div className="fw-bold ">{t('tax_number')}</div>
                  <div className="text-gray-600">
                    <EditableTextInput
                      id={businessPartner.id}
                      attribute="tax_number"
                      placeholder={t('tax_number')}
                      initialValue={businessPartnerDetails.tax_number}
                      handleSubmit={updateBusinessPartner}
                    />
                  </div>
                </div>
                <div className="separator separator-dashed my-3"></div>
                <div className='d-flex flex-stack'>
                  <div className="fw-bold ">{t('vat_id')}</div>
                  <div className="text-gray-600">
                    <EditableTextInput
                      id={businessPartner.id}
                      attribute="uid"
                      placeholder={t('vat_id')}
                      initialValue={businessPartnerDetails.uid}
                      handleSubmit={updateBusinessPartner}
                    />
                  </div>
                </div>
                <div className="separator separator-dashed my-3"></div>
                <div className='d-flex flex-stack'>
                  <div className="fw-bold ">{t('bic')}</div>
                  <div className="text-gray-600">
                    <EditableTextInput
                      id={businessPartner.id}
                      attribute="bic"
                      placeholder={t('bic')}
                      initialValue={businessPartnerDetails.bic}
                      handleSubmit={updateBusinessPartner}
                    />
                  </div>
                </div>
                <div className="separator separator-dashed my-3"></div>
                <div className='d-flex flex-stack'>
                  <div className="fw-bold ">{t('iban')}</div>
                  <div className="text-gray-600">
                    <EditableTextInput
                      id={businessPartner.id}
                      attribute="iban"
                      placeholder={t('iban')}
                      initialValue={businessPartnerDetails.iban}
                      handleSubmit={updateBusinessPartner}
                    />
                  </div>
                </div>
                <div className="separator separator-dashed my-3"></div>
                <div className='d-flex flex-stack'>
                  <div className="fw-bold align-items-center d-flex">
                    {t('vvo_number')}
                    <span
                      data-bs-toggle="tooltip"
                      data-bs-custom-class="tooltip-inverse"
                      data-bs-placement="top"
                      title={t('transport_vehicles_registration_number_title')}
                      className="btn p-0 text-hover-warning"
                    >
                      <QuestionIcon className={"fs-4"} />
                    </span>
                  </div>
                  <div className="text-gray-600">
                    <EditableTextInput
                      id={businessPartner.id}
                      attribute="vvo_number"
                      placeholder={t('vvo_number')}
                      initialValue={businessPartnerDetails.vvo_number}
                      handleSubmit={updateBusinessPartner}
                    />
                  </div>
                </div>
                {currentCustomizing.plant_waste && (
                  <>
                    <div className="separator separator-dashed my-3"></div>
                    <div className='d-flex flex-stack'>
                      <div className="fw-bold align-items-center d-flex">
                        {t('hit_zid_nummer')}
                        <span
                          data-bs-toggle="tooltip"
                          data-bs-custom-class="tooltip-inverse"
                          data-bs-placement="top"
                          title={t('unique_identification_number_for_motor_vehicle_title')}
                          className="btn p-0 text-hover-warning"
                        >
                          <QuestionIcon className={"fs-4"} />
                        </span>
                      </div>
                      <div className="text-gray-600">
                        <EditableTextInput
                          id={businessPartner.id}
                          attribute="hit_zid_number"
                          placeholder={t('hit_zid_nummer')}
                          initialValue={businessPartnerDetails.hit_zid_number}
                          handleSubmit={updateBusinessPartner}
                        />
                      </div>
                    </div>
                    <div className="separator separator-dashed my-3"></div>
                    <div className='d-flex flex-stack py-4'>
                      <div className="fw-bold ">
                        {t('company_number')}
                        <span
                          data-bs-toggle="tooltip"
                          data-bs-custom-class="tooltip-inverse"
                          data-bs-placement="top"
                          title={t('entrepreneur_number_title')}
                          className="btn p-0 text-hover-warning"
                        >
                          <QuestionIcon className={"fs-4"} />
                        </span>
                      </div>
                      <div className="text-gray-600">
                        <EditableTextInput
                          id={businessPartner.id}
                          attribute="company_number"
                          placeholder={t('company_number')}
                          initialValue={businessPartnerDetails.company_number}
                          handleSubmit={updateBusinessPartner}
                        />
                      </div>
                    </div>
                    <div className="separator separator-dashed my-3"></div>
                    <div className='d-flex flex-stack'>
                      <div className="fw-bold ">{t('sustainable_delivery_slips')}</div>
                      <div className="text-gray-600">
                        {toggleHtml('customizings_sustainably_produced')}
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="card mb-5 mb-xl-8">
            <div className="card-body pt-2">
              <div className='d-flex flex-stack'>
                <div className="fw-bold ">{t('shared.comment')}</div>
                <div className="text-gray-600">
                  <EditableTextInput
                    id={businessPartner.id}
                    attribute="comment"
                    placeholder={t('shared.comment')}
                    initialValue={businessPartnerDetails.comment}
                    handleSubmit={updateBusinessPartner}
                  />
                </div>
              </div>
              <div className="separator separator-dashed my-3"></div>
              <div className='d-flex flex-stack'>
                <div className="fw-bold ">{t('discount_on_services')}</div>
                <div className="text-gray-600 d-flex align-items-center">
                  <EditableTextInput
                    id={businessPartner.id}
                    attribute="discount_perc"
                    placeholder={t('discount_on_services')}
                    initialValue={businessPartnerDetails.discount_perc}
                    handleSubmit={updateBusinessPartner}
                  />
                  <span className={'ms-2'}>%</span>
                </div>
              </div>
              {businessPartner.vendor && (
                <>
                  <div className="separator separator-dashed my-3"></div>
                  <div className='d-flex flex-stack'>
                    <div className="fw-bold ">{t('discount_on_purchase_of_goods')}</div>
                    <div className="text-gray-600 d-flex align-items-center">
                      <EditableTextInput
                        id={businessPartner.id}
                        attribute="material_purchase_discount_perc"
                        placeholder={t('discount_on_purchase_of_goods')}
                        initialValue={businessPartnerDetails.material_purchase_discount_perc}
                        handleSubmit={updateBusinessPartner}
                      />
                      <span className={'ms-2'}>%</span>
                    </div>
                  </div>
                </>
              )}
              <div className="separator separator-dashed my-3"></div>
              <div className='d-flex flex-stack'>
                <div className="fw-bold ">{t('discount_on_sale_of_goods')}</div>
                <div className="text-gray-600 d-flex align-items-center">
                  <EditableTextInput
                    id={businessPartner.id}
                    attribute="material_sell_discount_perc"
                    placeholder={t('discount_on_sale_of_goods')}
                    initialValue={businessPartnerDetails.material_sell_discount_perc}
                    handleSubmit={updateBusinessPartner}
                  />
                  <span className={'ms-2'}>%</span>
                </div>
              </div>
              <div className="separator separator-dashed my-3"></div>
              <div className='d-flex flex-stack'>
                <div className="fw-bold ">{t('payment_method')}</div>
                <div className="text-gray-600">
                  <EditableSelectInput
                    id={businessPartner.id}
                    attribute={'payment_terms'}
                    placeholder={t('payment_method')}
                    initialValue={{ value: businessPartnerDetails.payment_terms?.value, label: businessPartnerDetails.payment_terms?.label }}
                    handleSubmit={updateBusinessPartner}
                    returnObject
                    getEntities={getPaymentTerms}
                  />
                </div>
              </div>
              <div className="separator separator-dashed my-3"></div>
              <div className='d-flex flex-stack'>
                <div className="fw-bold ">{t('terms_of_payment')}</div>
                <div className="text-gray-600 d-flex align-items-center">
                  <EditableTextInput
                    id={businessPartner.id}
                    attribute="net_days"
                    placeholder={t('terms_of_payment')}
                    initialValue={businessPartnerDetails.net_days}
                    handleSubmit={updateBusinessPartner}
                  />
                  <span className={'ms-2'}>{t('time_expressions.days')}</span>
                </div>
              </div>
              <div className="separator separator-dashed my-3"></div>
              <div className='d-flex flex-stack'>
                <div className="fw-bold ">{t('discount_days')}</div>
                <div className="text-gray-600 d-flex align-items-center">
                  <EditableTextInput
                    id={businessPartner.id}
                    attribute="cash_discount_days"
                    placeholder={t('discount_days')}
                    initialValue={businessPartnerDetails.cash_discount_days}
                    handleSubmit={updateBusinessPartner}
                  />
                  <span className={'ms-2'}>{t('time_expressions.days')}</span>
                </div>
              </div>
              <div className="separator separator-dashed my-3"></div>
              <div className='d-flex flex-stack'>
                <div className="fw-bold ">{t('discount_percentage')}</div>
                <div className="text-gray-600 d-flex align-items-center">
                  <EditableTextInput
                    id={businessPartner.id}
                    attribute="cash_discount_perc"
                    placeholder={t('discount_percentage')}
                    initialValue={businessPartnerDetails.cash_discount_perc}
                    handleSubmit={updateBusinessPartner}
                  />
                  <span className={'ms-2'}>%</span>
                </div>
              </div>
              <div className="separator separator-dashed my-3"></div>
              <div className='d-flex flex-stack'>
                <div className="fw-bold ">{t('contract_renewal_reminder')}</div>
                <div className="text-gray-600">
                  <EditableDatePickerInput
                    id={businessPartner.id}
                    attribute={'contract_renewal_reminder_date'}
                    placeholder={t('contract_renewal_reminder')}
                    initialValue={businessPartnerDetails.contract_renewal_reminder_date || '-'}
                    handleSubmit={updateBusinessPartner}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DetailsPanel;
