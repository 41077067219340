import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ProgressBar from 'react-bootstrap/ProgressBar';
import consumer from '../../../utils/cable';

const PayrollProgressBar = ({ payrollId }) => {
  const { t } = useTranslation();
  const [status, setStatus] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [label, setLabel] = useState();

  useEffect(() => {
    if (!payrollId) return;

    const subscription = {
      channel: "RecalculatePayrollProgressChannel",
      payroll_id: payrollId
    }

    consumer.subscriptions.create(subscription,
      {
        connected: () => console.log('Table connected for:', subscription),
        disconnected: () => console.log('Table disconnected'),
        received: (data) => {
          const attributes = data.data.attributes

          setIsLoading(true)
          setStatus({
            variant: attributes.variant,
            progress: attributes.progress
          })

          if (attributes.status === "working") {
            setLabel(`${t("background.working")} ${attributes.progress}%`)
          }

          if (attributes.status === "failed") {
            console.log("Error: ", attributes.message)

            setLabel(t("background.failed"))
            setTimeout(() => {
              setIsLoading(false);
            }, 1000);
          }

          if (attributes.status === "complete") {
            setLabel(t("background.complete"))
            setTimeout(() => {
              setIsLoading(false);
            }, 1000);
          }
        },
      })

    return () => {
      consumer.disconnect()
    }
  }, [payrollId])

  return (
    <div className='mb-4'>
      {isLoading &&
        <ProgressBar key={1} variant={'warning'} style={{ height: '20px' }}>
          <ProgressBar striped animated variant={status.variant} label={label} now={100} key={2} />
          <ProgressBar striped animated variant={'warning'} now={100 - status.progress} key={3} />
        </ProgressBar>
      }
    </div>
  );
};

export default PayrollProgressBar
