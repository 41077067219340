import React, { useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import { axiosInstance, routes } from '../../../utils/api_base';
import PayrollDetails from './PayrollDetails';
import MonthYearSelector from "./MonthYearSelector";
import Toastr from "../../Toastr";
import { pushToHistory } from "../../../utils/base";
import PayrollProgressBar from "./PayrollProgressBar";
import Actions from "./Actions";
import consumer from '../../../utils/cable';
import PayrollTables from "./PayrollTables";
import Button from "../../../components/Button";
import { DownloadIcon } from '../../../components/Icons';

const Payroll = ({ staff }) => {
  const urlParams = new URLSearchParams(window.location.search);
  const currentDate = new Date();
  const month = urlParams.get('month') || currentDate.getMonth() + 1;
  const year = urlParams.get('year') || currentDate.getFullYear();
  const { t } = useTranslation();
  const [payroll, setPayroll] = useState();
  const [yearMonth, setYearMonth] = useState({ year: year, month: month });

  useEffect(() => {
    getPayroll();
  }, [yearMonth]);

  useEffect(() => {
    if (!payroll?.id) return;

    const subscription = {
      channel: "RecalculatePayrollChannel",
      payroll_id: payroll.id
    }

    consumer.subscriptions.create(subscription,
      {
        connected: () => console.log('Table connected for:', subscription),
        disconnected: () => console.log('Table disconnected'),
        received: (data) => {
          const parsedData = JSON.parse(data.data).data

          setPayroll((prevDetails) => ({
            ...prevDetails,
            ...parsedData.attributes
          }));
        },
      })

    return () => {
      consumer.disconnect()
    }
  }, [payroll?.id])

  const getPayroll = async () => {
    return axiosInstance.get(routes.remunerationPayrollYearMonth(), {
      params: { year: yearMonth.year, month: yearMonth.month, staff_id: staff.id }
    })
      .then((response) => {
        setPayroll(response.data.data.attributes)
        pushToHistory({ year: yearMonth.year, month: yearMonth.month, staff_page: "payroll" })
      })
      .catch((error) => {
        console.log("error", error)
        Toastr({
          type: 'error',
          message: error.response?.data?.error || t('critical_error')
        })
        setYearMonth({
          year: currentDate.getFullYear(),
          month: currentDate.getMonth()
        })
      });
  };

  const updatePayroll = (id, params) => {
    const data = {
      payroll: {
        bonus_payment: params.bonus_payment,
        comment_for_staff: params.comment_for_staff,
        comment_for_office: params.comment_for_office,
        vacation_account: params.vacation_account
      }
    }
    axiosInstance.put(routes.remunerationPayroll(id), data)
      .then(resp => {
        Toastr({ message: t('success'), options: { showDuration: 2000 } })
        setPayroll((prevDetails) => ({
          ...prevDetails,
          ...resp.data.data.attributes
        }));
      })
      .catch(error => {
        Toastr({
          type: 'error',
          message: error.response?.data?.error || t('critical_error')
        })
      })
  }

  const recalculatePayroll = (id, params) => {
    const data = {
      payroll: {
        settled_work_hours: params.settled_work_hours,
        work_account: params.work_account,
      }
    }
    axiosInstance.put(routes.remunerationRecalculatePayroll(id), data)
  }

  const closePayroll = () => {
    axiosInstance.post(routes.remunerationClosePayroll(payroll?.id))
  }

  const reopenPayroll = () => {
    axiosInstance.post(routes.remunerationReopenPayroll(payroll?.id))
  }

  const createExport = (id) => {
    axiosInstance.post(routes.exports(), {
      type: 'PayrollsExport',
      export_format: 'xlsx',
      additional_params: {
        payroll_id: id,
      }
    })
      .then(response => console.log('RESPONSE', response))
      .catch(error => console.log('ERROR', error))
  }

  return (
    <>
      <MonthYearSelector month={yearMonth.month} year={yearMonth.year} currentDate={currentDate} staffId={staff.id} setYearMonth={setYearMonth} />
      <div className="d-flex justify-content-between mb-4">
        <div className="w-50">
          <PayrollProgressBar payrollId={payroll?.id} />
        </div>
        <div className="w-50 d-flex justify-content-end">
          <Actions payroll={payroll} reopenPayroll={reopenPayroll} closePayroll={closePayroll} />
        </div>
      </div>
      <PayrollDetails payroll={payroll} updatePayroll={updatePayroll} recalculatePayroll={recalculatePayroll} />
      <PayrollTables payroll={payroll} />
      <div className="d-flex">
        <Button>
          <a href={payroll?.bookings_link} className="text-dark">{t('all_bookings')}</a>
        </Button>
        <Button classNames={"success"} onClick={() => createExport(payroll?.id)}>
          <div className="d-flex align-items-center">
            <DownloadIcon className={"text-white fs-2 me-2"} />
            {t('payroll_in_excel')}
          </div>
        </Button>
      </div>
    </>
  );
};

export default Payroll;
