import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import TableButton from '../Table/TableButton';
import { PlusIcon } from '../Icons';
import Table from './Table';
import Create from './Create';
import Modal from '../Modal';

const Staffs = ({ isCustomer, show_location, filters }) => {
  const { t } = useTranslation()
  const [modalOpen, setModalOpen] = useState(false)
  const columnVisibility = { location: show_location }

  const tableToolbar = () => {
    return (
      <>
        <TableButton
          className="btn-warning"
          onClick={() => setModalOpen(true)}
          icon={<PlusIcon />}
          title={t('shared.add_to')}
        />
      </>
    )
  }

  return (
    <>
      <Modal className="modal fade show" open={modalOpen} onClose={() => setModalOpen(false)} title={t('staffs.new_colleague')}>
        <Create onClose={() => setModalOpen(false)} isCustomer={isCustomer} />
      </Modal>
      <Table tableToolbar={tableToolbar} columnVisibility={columnVisibility} filters={filters} />
    </>
  )
}

export default Staffs;
