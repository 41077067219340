import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { axiosInstance, routes } from '../../../utils/api_base';
import { Form, Field } from 'react-final-form'
import AsyncSelectAdapter from '../../Form/AsyncSelectAdapter';
import ErrorField from '../../Form/ErrorField';
import Toastr from '../../Toastr';
import { FORM_ERROR } from 'final-form'
import TableButton from '../../Table/TableButton';
import DateRangePicker from '../../DateRangePicker';
import moment from 'moment';
import { mapValues } from 'lodash';
import { isRequired } from '../../Form/validations';

const CreateAbsence = ({ onClose, setTrigger, staff }) => {
  const { t } = useTranslation();

  const onSubmit = async values => {
    const data = {
      absence: {
        absence_reason_id: values.absenceReason?.value,
        start_date: values?.period?.startTime.format("DD.MM.YYYY"),
        end_date: values?.period?.endTime.format("DD.MM.YYYY"),
        staff_id: staff.id
      }
    };

    return axiosInstance.post(routes.absences(), data)
      .then(response => {
        Toastr({
          title: 'Success!',
          message: response.data.message,
          options: { closeButton: true }
        })
        onClose()
        setTrigger(Math.floor(Date.now() / 1000)) // Dirty hack to refresh the table
      })
      .catch(error => {
        const errorData = error.response?.data

        if (errorData.error?.base instanceof Array) {
          return mapValues(errorData.error, (val) => (val.join(', ')))
        } else {
          return { [FORM_ERROR]: error.message }
        }
      })
  }

  const getAbsenceReasons = () => {
    return axiosInstance.get(routes.absenceReasons())
      .then((response) => {
        return response.data.map((absenceReason) => ({
          value: absenceReason.attributes.id,
          label: absenceReason.attributes.name,
        }));
      })
      .catch((error) => {
        console.log('ERROR', error)
      });
  };

  const DateSelectAdapter = ({ input, meta, ...rest }) => {
    const [isDateSelected, setIsDateSelected] = useState(false);
    const [placeholder, setPlaceholder] = useState("");

    const handleDateChange = (startTime, endTime) => {
      // DateRangePicker component is expecting a Promise.
      return new Promise((resolve, reject) => {
        try {
          input.onChange({ startTime, endTime });
          const formattedStartDate = moment(startTime).format("DD-MM-YYYY");
          const formattedEndDate = moment(endTime).format("DD-MM-YYYY");

          setPlaceholder(`${formattedStartDate}  ${formattedEndDate}`);
          setIsDateSelected(true);

          resolve();
        } catch (error) {
          reject(error);
        }
      });
    };

    return (
      <DateRangePicker
        placeholder={isDateSelected ? placeholder : t('range_date_placeholder')}
        classList="cursor-pointer form-control text-left"
        options={{
          showDropdowns: true,
          startDate: input.value?.startTime ? moment(input.value.startTime) : moment(),
          endDate: input.value?.endTime ? moment(input.value.endTime) : moment(),
          onChange: handleDateChange,
          autoApply: true,
          autoUpdateInput: false
        }}
        {...rest}
      />
    );
  };

  return (
    <Form
      onSubmit={onSubmit}
      validate={values => {
        let errors = {}
        errors.period = isRequired(values.period)
        errors.absenceReason = isRequired(values.absenceReason)

        return errors
      }}
      render={({ submitError, handleSubmit, submitting, pristine }) => (
        <form onSubmit={handleSubmit}>
          <div className="row mt-2">
            <div className="col m-4">
              <h6>{staff.name.toUpperCase()}</h6>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col m-4">
              <label className='required mb-2'>{t('period')}</label>
              <Field
                name='period'
                component={DateSelectAdapter}
                isClearable={true}
              >
              </Field>
              <ErrorField name="period" />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col m-4">
              <label className='required mb-2'>{t('absence')}</label>
              <Field
                name='absenceReason'
                component={AsyncSelectAdapter}
                loadOptions={getAbsenceReasons}
                placeholder={t('select')}
                isClearable={true}
              >
              </Field>
              <ErrorField name="absenceReason" />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col m-4">
              <label>{t('comment')}</label>
              <Field
                name='comment'
                component="input"
                type="text"
                className="form-control"
                placeholder={t('comment')}
              >
              </Field>
            </div>
          </div>
          <ErrorField name="base" />
          {submitError && <div className="form-error-message">{submitError}</div>}
          <div className="modal-footer">
            <TableButton
              className="btn btn-light btn-active-light-primary fw-semibold me-2 px-6"
              type="button"
              onClick={onClose}
              title={t('cancel')}
            />
            <TableButton
              className="btn btn-warning text-dark fw-semibold px-6"
              type="submit"
              title={t('create')}
              disabled={submitting || pristine}
            />
          </div>
        </form>
      )}
    />
  )
}

export default CreateAbsence;
