import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import BaseTable from '../Table';
import { axiosInstance, routes } from '../../utils/api_base';
import TableButton from '../Table/TableButton';

const ContributionMarginPerResource = ({order}) => {
  const { t, i18n } = useTranslation()

  const getResources = async (pagination, sorting, globalFilter, customFilters) => {
    const res = await axiosInstance.get(routes.orderResources(order.id))

    return({ rows: res.data })
  }

  const nameHtml = (row) => {
    if (row.original?.summary) {
      return(
        <div>
          <span><b>{row?.original?.long_name}</b></span>
        </div>
      )
    }
    if (row.original?.id) {
      return(
        <TableButton
          target="_blank"
          href={row?.original?.link}
          className="btn-light-primary me-3"
          title={row?.original?.long_name}
        />
      )
    }

    return(
      <div>
        <span>{row?.original?.long_name}</span>
      </div>
    )
  }

  const columns = useMemo(() => {
      return(
        [
          {
            id: 'name',
            header: t('resource'),
            accessorFn: row => row?.long_name,
            enableSorting: false,
            cell: ({ row }) => {
              return nameHtml(row)
            },
          },
          {
            id: 'costs',
            accessorFn: row => row?.costs,
            header: t('costs'),
            enableSorting: false,
            cell: row => row.getValue(),
          },
          {
            id: 'revenue_distribution',
            accessorFn: row => row?.revenue,
            header: t('revenue_distribution'),
            enableSorting: false,
            cell: row => row.getValue(),
          },
          {
            id: 'profit_loss',
            accessorFn: row => row?.profit,
            header: t('profit_loss'),
            enableSorting: false,
            cell: row => row.getValue(),
          },
        ]
      )
    }, [i18n.language]
  )
  return (
    <BaseTable
      keyName="Resources"
      enablePagination={false}
      showSearchInput={false}
      columns={columns}
      getDataFn={getResources}
      tableToolbar={()=>{}}
    />
  )
};

export default ContributionMarginPerResource;
