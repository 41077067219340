import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import TableButton from '../Table/TableButton';
import { ExportIcon, PlusIcon } from '../Icons';
import Table from './Table';
import Create from './Create';
import Modal from '../Modal';

const Machines = ({ filters }) => {
  const { t } = useTranslation()
  const [modalOpen, setModalOpen] = useState(false)

  const tableToolbar = () => {
    return (
      <>
        <TableButton type="button" className="btn-light-warning me-3" icon={<ExportIcon />} title={t('shared.export')} />
        <TableButton
          className="btn-warning"
          onClick={() => setModalOpen(true)}
          icon={<PlusIcon />}
          title={t('machines.create_machine')}
        />
      </>
    )
  }

  return (
    <>
      <Modal className="modal fade show modal-lg" open={modalOpen} onClose={() => setModalOpen(false)} title={t('machines.new_machine')}>
        <Create onClose={() => setModalOpen(false)} />
      </Modal>
      <Table filters={filters} tableToolbar={tableToolbar} />
    </>
  )
}

export default Machines
