import React, { useMemo } from 'react';
import BaseTable from '../Table';
import { isEmpty } from 'lodash';
import { axiosInstance, routes } from '../../utils/api_base';
import { useTranslation } from 'react-i18next';

const Table = ({ tableToolbar, machineId }) => {
  const { t, i18n } = useTranslation()

  const getDieselStatistics = async () => {
    const res = await axiosInstance.get(routes.dieselStatistics(), { params: { machine_id: machineId }})

    return(
      { rows: res.data.data, meta: res.data.meta }
    )
  }

  const staffHtml = (row) => {
    if (row?.original?.attributes?.staff_link) {
      return(
        <a href={row.original.attributes.staff_link}>
          {row.original.attributes.staff_name}
        </a>
      )
    }
  }

  const columns = useMemo(
    () => [
      {
        id: "date",
        accessorFn: row => row?.attributes?.formatted_date,
        header: t("time_expressions.day"),
        enableSorting: false,
        cell: row => row.getValue(),
        footer: t("total")
      },
      {
        id: "staff",
        accessorFn: row => row?.attributes?.staff_name,
        header: t("staff"),
        enableSorting: false,
        cell: ({ row }) => {
          return staffHtml(row)
        }
      },
      {
        id: "quantity",
        accessorFn: row => row?.attributes?.quantity,
        header: t("measurement_unit.liter"),
        enableSorting: false,
        cell: row => row.getValue(),
        footer: data => data?.table?.options?.meta?.total_row?.total_quantity
      },
    ],
    [i18n.language]
  )

  return (
    <BaseTable
      getDataFn={getDieselStatistics}
      columns={columns}
      tableToolbar={tableToolbar}
      showSearchInput={false}
      showHeader={false}
      enablePagination={false}
      enableFooter={true}
    />
  )
}

export default Table;
