import * as style from './index.module.css';
import BaseTable from '../../../components/Table';
import EditableTextInput from '../../../components/Table/EditableTextInput';
import Form from 'react-bootstrap/Form';
import React, { useMemo, useState, useEffect } from 'react';
import Toastr from '../../../components/Toastr';
import classNames from 'classnames'
import moment from "moment";
import { Tooltip } from 'bootstrap'
import { TrashSquareIcon, EditIcon, CheckIcon, ExportIcon, SendIcon } from '../../../components/Icons';
import { axiosInstance, routes } from '../../../utils/api_base';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import ToggleSwitch from '../../ToggleSwitch';

const Table = ({ tableToolbar, filters, businessPartnerId, columnVisibility, indexTrigger }) => {
  const { t, i18n } = useTranslation()
  const [trigger, setTrigger] = useState(false)

  const getContacts = async (pagination, sorting, globalFilter, customFilters) => {

    const { pageIndex, pageSize } = pagination
    const sortQuery = sorting.map((attr) => {
      return attr.desc ? `${attr.id} desc` : attr.id
    })
    let params = { page: pageIndex + 1, limit: pageSize, sort: sortQuery[0] }
    if (globalFilter) {
      params.query = globalFilter
    }
    if (!isEmpty(customFilters)) {
      params = { ...params, ...customFilters }
    }
    const res = await axiosInstance.get(routes.contacts(), { params: { ...params, business_partner_id: businessPartnerId } })

    return (
      { rows: res.data.data, pageCount: res.data.meta.total_pages, rowCount: res.data.meta.total_count }
    )
  }

  useEffect(() => {
    const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]')
    const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new Tooltip(tooltipTriggerEl))
  }, []);

  const updateContact = (id, params) => {
    axiosInstance.put(routes.contact(id), params)
      .then(resp => {
        Toastr({ message: t('success'), options: { showDuration: 2000 } })
        setTrigger(Math.floor(Date.now() / 1000)) // Dirty hack to refresh the table
      })
      .catch(error => {
        Toastr({
          type: 'error',
          message: error.response?.data?.error || t('critical_error')
        })
      })
  }

  const deleteContact = (id) => {
    axiosInstance.delete(routes.contact(id))
      .then(resp => {
        Toastr({ message: t('success'), options: { showDuration: 2000 } })
        setTrigger(Math.floor(Date.now() / 1000)) // Dirty hack to refresh the table
      })
      .catch(error => {
        Toastr({
          type: 'error',
          message: error.response?.data?.error || t('critical_error')
        })
      })
  }

  const createNotifications = (id) => {
    axiosInstance.post(routes.farmerLoginNotifications(), { id: id })
      .then(resp => {
        Toastr({ type: resp.data.type, message: resp?.data?.message, options: { showDuration: 2000 } })
        setTrigger(Math.floor(Date.now() / 1000)) // Dirty hack to refresh the table
      })
      .catch(error => {
        Toastr({
          type: 'error',
          message: error.response?.data?.error || t('critical_error')
        })
      })
  }

  const standardHtml = (row) => {
    const isActive = row.original.attributes?.standard
    const label = isActive ? t('true') : t('false')

    return (
      <ToggleSwitch
        id={row.original.id}
        handleToggle={() => updateContact(row.original.id, { standard: !isActive })}
        attribute={"active"}
        active={isActive}
        label={label}
      />
    )
  }

  const dieselBookHtml = (row) => {
    const isActive = row.original.attributes?.allowed_to_see_diesel_book
    const label = isActive ? t('true') : t('false')

    return (
      <ToggleSwitch
        id={`diesel-book-${row.original.id}`}
        handleToggle={() => updateContact(row.original.id, { allowed_to_see_diesel_book: !isActive })}
        attribute={"active"}
        active={isActive}
        label={label}
      />
    )
  }

  const allowSendInvoiceHtml = (row) => {
    const isActive = row.original.attributes?.allowed_to_send_invoices_by_email
    const label = isActive ? t('yes') : t('no')

    return (
      <ToggleSwitch
        id={`allowed-to-send-invoices-by-email-${row.original.id}`}
        handleToggle={() => updateContact(row.original.id, { allowed_to_send_invoices_by_email: !isActive })}
        attribute={"active"}
        active={isActive}
        label={label}
      />
    )
  }

  const actionsHtml = (row) => {
    const date = row.original?.attributes?.staff ? row.original.attributes.staff.attributes.created_at : ''
    const link_url = `/business_partners/${businessPartnerId}/vcards.vcf?contact_id=${row.original.id}`

    return (
      <div className={'d-flex'}>
        {row.original.attributes.staff ?
          <div
            data-bs-toggle="tooltip"
            data-bs-custom-class="tooltip-inverse"
            data-bs-placement="top"
            title={t('send_farmer_login_title1', { date: moment.parseZone(date).format('DD-MM-YYYY') })}
            onClick={() => createNotifications(row.original.id)}><CheckIcon className="fs-1 cursor-pointer text-success" /></div>
          :
          <div
            data-bs-toggle="tooltip"
            data-bs-custom-class="tooltip-inverse"
            data-bs-placement="top"
            title={t('send_farmer_login_title2')}
            onClick={() => createNotifications(row.original.id)}>
            <SendIcon className="fs-1 cursor-pointer text-warning" />
          </div>
        }
        <a href={link_url}
          data-bs-toggle="tooltip"
          data-bs-custom-class="tooltip-inverse"
          data-bs-placement="top"
          title={t('vcards_title')}>
          <ExportIcon className="fs-1 cursor-pointer text-warning" />
        </a>
      </div>

    )
  }

  const salutationHtml = (row) => {
    const isActive = row.original.attributes?.sex_female
    const label = isActive ? t('salutations.ms') : t('salutations.mr')

    return (
      <Form.Check
        onChange={() => updateContact(row.original.id, { sex_female: !isActive })}
        checked={isActive}
        type="switch"
        id={`sex-female-${row.original.id}`}
        className={classNames(style.hidden_checkbox)}
        label={
          <>
            <EditIcon className={'me-1 text-warning'} />
            {label}
          </>
        }
      />
    )
  }

  const deleteHtml = (row) => {
    return (
      <div onClick={() => deleteContact(row.original.id)}><TrashSquareIcon className="fs-2hx text-danger cursor-pointer" /></div>
    )
  }

  const columns = useMemo(
    () => [
      {
        id: 'standard_contact',
        header: t('send_to_smartphone'),
        accessorFn: row => row?.attributes?.sex_female,
        cell: ({ row }) => standardHtml(row)
      },
      {
        id: 'salutation',
        header: t('salutation'),
        accessorFn: row => row?.attributes?.sex_female,
        cell: ({ row }) => salutationHtml(row)
      },
      {
        id: 'first_name',
        header: t('shared.first_name'),
        accessorFn: row => row?.attributes?.first_name,
        cell: ({ row }) => <EditableTextInput id={row.original.id} attribute='first_name' initialValue={row.original.attributes.first_name} handleSubmit={updateContact} />
      },
      {
        id: 'last_name',
        header: t('last name'),
        accessorFn: row => row?.attributes?.last_name,
        cell: ({ row }) => <EditableTextInput id={row.original.id} attribute='last_name' initialValue={row.original.attributes.last_name} handleSubmit={updateContact} />
      },
      {
        id: 'phone',
        header: t('phone'),
        accessorFn: row => row?.attributes?.fon,
        cell: ({ row }) => <EditableTextInput id={row.original.id} attribute='fon' initialValue={row.original.attributes.fon} handleSubmit={updateContact} />

      },
      {
        id: 'mobile',
        header: t('mobile'),
        accessorFn: row => row?.attributes?.mobile,
        cell: ({ row }) => <EditableTextInput id={row.original.id} attribute='mobile' initialValue={row.original.attributes.mobile} handleSubmit={updateContact} />
      },
      {
        id: 'email',
        header: t('email'),
        accessorFn: row => row?.attributes?.email,
        cell: ({ row }) => <EditableTextInput id={row.original.id} attribute='email' initialValue={row.original.attributes.email} handleSubmit={updateContact} />
      },
      {
        id: 'department',
        header: t('function'),
        accessorFn: row => row?.attributes?.department,
        cell: ({ row }) => <EditableTextInput id={row.original.id} attribute='department' initialValue={row.original.attributes.department} handleSubmit={updateContact} />
      },
      {
        id: 'actions',
        header: t('actions'),
        cell: ({ row }) => actionsHtml(row)
      },
      {
        id: 'diesel_book_visible',
        header: t('diesel_book_visible'),
        accessorFn: row => row?.attributes?.allowed_to_see_diesel_book,
        cell: ({ row }) => dieselBookHtml(row)

      },
      {
        id: 'invoice_by',
        header: t('invoice_by_email'),
        accessorFn: row => row?.attributes?.allowed_to_send_invoices_by_email,
        cell: ({ row }) => allowSendInvoiceHtml(row)
      },
      {
        id: 'delete',
        cell: ({ row }) => deleteHtml(row)
      }
    ],
    [i18n.language]
  )

  return (
    <BaseTable
      getDataFn={getContacts}
      columns={columns}
      columnVisibility={columnVisibility}
      tableToolbar={tableToolbar}
      filters={filters}
      initialSortDirection={'asc'}
      trigger={trigger + indexTrigger}
      tableName={'Contacts'}
    />
  )
}

export default Table;
