import React, { useState, useEffect } from 'react';
import Table from './Table';
import { axiosInstance, routes } from '../../../utils/api_base';
import { useTranslation } from 'react-i18next';

const PayrollTables = ({ payroll }) => {
  const { t, i18n } = useTranslation();
  const [currentPayrollDays, setCurrentPayrollDays] = useState();
  const [oldPayrollDays, setOldPayrollDays] = useState();
  const [showOldPayrollDays, setShowOldPayrollDays] = useState(false);

  const getPayrollDays = async () => {
    if (!payroll?.id) { return }

    const res = await axiosInstance.get(routes.remunerationPayrollDays(payroll?.id))

    formatData(res.data)
  }

  useEffect(() => {
    getPayrollDays();
  }, [payroll])

  useEffect(() => {
    if (oldPayrollDays?.rows?.data?.length > 0) {
      setShowOldPayrollDays(true);
    } else {
      setShowOldPayrollDays(false);
    }
  }, [oldPayrollDays]);

  const formatData = (data) => {
    const oldPayrollDays = {
      headers: data[0][0],
      rows: JSON.parse(data[0][1])
    }

    const currentPayrollDays = {
      headers: data[1][0],
      rows: JSON.parse(data[1][1]),
      footers: data[1][2]
    }

    setCurrentPayrollDays(currentPayrollDays);
    setOldPayrollDays(oldPayrollDays);
  };

  return (
    <>
      {showOldPayrollDays && (
        <>
          <div className="d-flex flex-stack fs-4 py-3">
            <div className="fw-bold rotate collapsible" data-bs-toggle="collapse" href="#staff-payroll-old" role="button">
              <span className="ms-2 rotate-180">
                <i className="ki-duotone ki-down fs-3"></i>
              </span>
            </div>
          </div>
          <div className="separator my-3"></div>

          <div id="staff-payroll-old" className="collapse show">
            <Table data={oldPayrollDays} />
          </div>
        </>
      )}

      <div className="d-flex flex-stack fs-4 py-3">
        <div className="fw-bold rotate collapsible" data-bs-toggle="collapse" href="#staff-payroll-current" role="button">
          <span className="ms-2 rotate-180">
            <i className="ki-duotone ki-down fs-3"></i>
          </span>
        </div>
      </div>

      <div className="separator my-3"></div>

      <div id="staff-payroll-current" className="collapse show">
        {currentPayrollDays && <Table data={currentPayrollDays} />}
      </div>
    </>
  );
}

export default PayrollTables;
