import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { axiosInstance, routes } from '../../utils/api_base';
import Select from '../Select';
import Toastr from '../Toastr';
import { WrenchIcon } from '../Icons';
import { Form, Field } from 'react-final-form'
import TableButton from '../Table/TableButton';
import { isRequired } from '../Form/validations';
import ErrorField from '../Form/ErrorField';
import { mapValues } from 'lodash';
import { FORM_ERROR } from 'final-form'

const Create = ({ onClose }) => {
  const { t } = useTranslation();
  const [unitOptions, setUnitOptions] = useState([]);
  const [serviceGroupOptions, setServiceGroupOptions] = useState([]);
  const [serviceColorOptions, setServiceColorOptions] = useState([]);
  const [revenueCodeOptions, setRevenueCodeOptions] = useState([]);

  useEffect(() => {
    getOptions();
  }, []);

  const showErrors = (errors) => {
    Toastr({
      type: 'error',
      message: errors,
      options: { closeButton: true }
    });
  };

  const getOptions = async () => {
    try {
      const { data } = await axiosInstance.get(routes.dataServiceForm());

      const parseData = (dataKey) =>
        JSON.parse(data[dataKey]).data.map(group => ({
          id: group.attributes.id,
          text: group.attributes.long_name || group.attributes.name
        }))

      const units = await parseData('units');
      const serviceGroups = await parseData('service_groups');
      const serviceColors = await parseData('service_colors');
      const revenueCodes = await parseData('revenue_codes');

      setUnitOptions(units);
      setServiceGroupOptions(serviceGroups);
      setServiceColorOptions(serviceColors);
      setRevenueCodeOptions(revenueCodes);

    } catch (error) {
      showErrors(error.response?.data?.error || 'Error fetching options');
    }
  };

  const onSubmit = async values => {
    const data = {
      service: {
        name: values.name,
        unit_id: values.unit_id,
        service_group_id: values.service_group_id,
        service_color_id: values.service_color_id,
        revenue_code_id: values.revenue_code_id
      }
    }

    axiosInstance
      .post(
        routes.services(),
        data
      )
      .then(response => {
        onClose()
        Toastr({
          title: t('shared.success'),
          message: response.data.message,
          options: { closeButton: true }
        })

        window.location.href = `${window.location.protocol}//${window.location.host}${window.location.pathname}/${response.data.id}`;
      })
      .catch(error => {
        const errorData = error.response.data
        if (errorData instanceof Array) {
          return mapValues(errorData.error, (val) => (val.join(', ')))
        } else {
          return { [FORM_ERROR]: error.message }
        }
      })
  };

  return (
    <>
      <Form
        onSubmit={onSubmit}
        initialValues={{
          name: "",
          unit_id: "",
          service_group_id: "",
          service_color_id: "",
          revenue_code_id: ""
        }}
        validate={values => {
          let errors = {}
          errors.name = isRequired(values.name)
          errors.unit_id = isRequired(values.unit_id)
          errors.service_group_id = isRequired(values.service_group_id)
          errors.service_color_id = isRequired(values.service_color_id)
          return errors
        }}
        render={({ submitError, handleSubmit, submitting, pristine }) => (
          <form onSubmit={handleSubmit}>
            <div className="container">
              <div className='p-6'>
                <div className="row mb-4">
                  <div className="col">
                    <label className='required'>{t('shared.name')}</label>
                    <Field
                      name="name"
                      placeholder={t('shared.name')}
                      required='required'
                      component="input"
                      type="text"
                      className="form-control"
                    />
                    <ErrorField name="name" />
                  </div>
                </div>
                <div className="row mb-4">
                  <div className="col">
                    <label className='required'>{t("shared.unit")}</label>
                    <Field
                      name="unit_id"
                      render={({ input }) => (
                        <Select
                          options={unitOptions}
                          placeholder={t("shared.unit")}
                          onChange={input.onChange}
                          allowBlank
                        />
                      )}
                    />
                    <ErrorField name="unit_id" />
                  </div>
                </div>
                <div className="row mb-4">
                  <div className="col">
                    <label className='required'>{t('shared.classification')}</label>
                    <Field
                      name="service_group_id"
                      render={({ input }) => (
                        <Select
                          options={serviceGroupOptions}
                          placeholder={t('shared.classification')}
                          onChange={input.onChange}
                          allowBlank
                        />
                      )}
                    />
                    <ErrorField name="service_group_id" />
                  </div>
                </div>
                <div className="row mb-4">
                  <div className="col">
                    <label className='required'>
                      {t('shared.color_group')}
                      <a title={t('shared.color_define')} href="/service_colors">
                        <WrenchIcon />
                      </a>
                    </label>
                    <Field
                      name="service_color_id"
                      render={({ input }) => (
                        <Select
                          options={serviceColorOptions}
                          placeholder={t('shared.color_group')}
                          onChange={input.onChange}
                          allowBlank
                        />
                      )}
                    />
                    <ErrorField name="service_color_id" />
                  </div>
                </div>
                <div className="row mb-4">
                  <div className="col">
                    <label>{t('shared.revenue_codes')}</label>
                    <Field
                      name="revenue_code_id"
                      render={({ input }) => (
                        <Select
                          options={revenueCodeOptions}
                          placeholder={t('shared.revenue_codes')}
                          onChange={input.onChange}
                          allowBlank
                        />
                      )}
                    />
                    <ErrorField name="revenue_code_id" />
                  </div>
                </div>
                {submitError && <div className="form-error-message">{submitError}</div>}
                <div className="modal-footer">
                  <TableButton
                    className="btn btn-light btn-active-light-warning fw-semibold me-2 px-6"
                    type="button"
                    onClick={onClose}
                    title={t('cancel')}
                  />
                  <TableButton
                    className="btn btn-warning fw-semibold px-6"
                    type="submit"
                    title={t('create')}
                    disabled={submitting || pristine}
                  />
                </div>
              </div>
            </div>
          </form>
        )}
      />
    </>
  );
};

export default Create
