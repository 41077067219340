import React, { useMemo } from 'react';
import BaseTable from '../Table';
import { isEmpty } from 'lodash';
import { axiosInstance, routes } from '../../utils/api_base';
import { pushToHistory } from '../../utils/base';
import { useTranslation } from 'react-i18next';
import TableButton from '../Table/TableButton';

const Table = ({ tableToolbar, filters, columnVisibility }) => {
  const { t, i18n } = useTranslation()

  const getBusinessPartners = async (pagination, sorting, globalFilter, customFilters) => {
    const { pageIndex, pageSize } = pagination
    const sortQuery = sorting.map((attr) => {
      return attr.desc ? `${attr.id} desc` : attr.id
    })
    let params = { page: pageIndex + 1, limit: pageSize, sort: sortQuery[0], customer: true }
    if (globalFilter) {
      params.query = globalFilter
    }
    if (!isEmpty(customFilters)) {
      params = { ...params, ...customFilters }
    }
    const res = await axiosInstance.get(routes.businessPartners(), { params: { ...params, serialize: "full" } })
    pushToHistory(params)

    return (
      { rows: res.data.data, pageCount: res.data.meta.total_pages, rowCount: res.data.meta.total_count }
    )
  }

  const codeHtml = (row) => {
    return (
      <TableButton
        target="_blank"
        href={row?.original?.attributes?.link}
        className="btn-light-warning me-3"
        title={row?.original?.attributes?.client_specific_number}
      />
    )
  }

  const groupHtml = (row) => {
    return (
      <>
        {
          row?.original?.attributes?.group_name &&
          <TableButton
            target="_blank"
            href={row.original.attributes.group_link}
            className="btn-light-warning me-3"
            title={row.original.attributes.group_name}
          />
        }
      </>
    )
  }

  const columns = useMemo(
    () => [
      {
        id: 'customer',
        header: t('shared.number'),
        accessorFn: row => row?.attributes?.client_specific_number,
        size: 70,
        minSize: 70,
        enableSorting: true,
        cell: ({ row }) => {
          return codeHtml(row)
        },
      },
      // {
      //   id: 'use_barcode',
      //   accessorFn: row => row?.attributes?.use_barcode ? t('yes') : t('no'),
      //   header: t('business_partners.should_barcode_be_generated'),
      //   cell: row => row.getValue(),
      // },
      {
        id: 'group',
        header: t('shared.group'),
        accessorFn: row => row?.attributes?.group_name,
        size: 70,
        minSize: 70,
        cell: ({ row }) => {
          return groupHtml(row)
        },
      },
      {
        id: 'company_name',
        accessorFn: row => row?.attributes?.name,
        header: t('business_partners.company_name'),
        cell: row => row.getValue(),
      },
      {
        id: 'postal_code',
        accessorFn: row => row?.attributes?.postal_code,
        header: t('business_partners.post_code_abbreviation'),
        cell: row => row.getValue(),
      },
      {
        id: 'city',
        accessorFn: row => row?.attributes?.city,
        header: t('business_partners.city'),
        enableSorting: false,
        cell: row => row.getValue(),
      },
      {
        id: 'contact_name',
        accessorFn: row => row?.attributes?.contact_name,
        header: t('business_partners.contact_person'),
        enableSorting: false,
        cell: row => row.getValue(),
      },
      {
        id: 'fon',
        accessorFn: row => row?.attributes?.fon,
        header: t('business_partners.telephone'),
        cell: row => row.getValue(),
      },
      {
        id: 'revenue',
        accessorFn: row => row?.attributes?.revenue,
        header: t('shared.sales_volume'),
        cell: row => row.getValue(),
      },
      {
        id: 'costs',
        accessorFn: row => row?.attributes?.costs,
        header: t('shared.costs'),
        cell: row => row.getValue(),
      },
      {
        id: 'location',
        accessorFn: row => row?.attributes?.location_name,
        header: t('shared.location'),
        cell: row => row.getValue(),
      },
      {
        id: 'remaining_order_limit',
        accessorFn: row => row?.attributes?.remaining_order_limit,
        header: t('business_partners.available_limit'),
        cell: row => row.getValue(),
      },
    ],
    [i18n.language]
  )

  return (
    <BaseTable
      getDataFn={getBusinessPartners}
      columns={columns}
      columnVisibility={columnVisibility}
      tableToolbar={tableToolbar}
      filters={filters}
      initialSortDirection={'asc'}
    />
  )
}

export default Table;
