import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'bootstrap'
import EditableTextInput from "../../Table/EditableTextInput";
import QuestionMark from "../../Icons/QuestionMark";

const PersonalDetails = ({ payroll, updatePayroll, recalculatePayroll }) => {
  const { t } = useTranslation();

  useEffect(() => {
    const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]')
    const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new Tooltip(tooltipTriggerEl))
  }, [payroll]);

  const ClosedAtInput = ({ attribute, action }) => {
    return (
      <>
        {
          payroll?.payroll_is_closed ? (
            <div className="text-gray-600">
              {payroll?.[attribute]}
            </div>
          ) : (
            <div className="text-gray-600">
              <EditableTextInput
                id={payroll?.id}
                attribute={attribute}
                initialValue={payroll?.[attribute] || "-"}
                handleSubmit={action}
              />
            </div>
          )
        }
      </>
    )
  };

  return (
    <>
      {/* First 2 Cards */}
      <div className="d-flex row">
        <div className="col-12 col-lg-6">
          <div className="card mb-5 mb-xl-8">
            <div className="card-body pt-2">
              <div className="py-2 fs-6">
                <div className="d-flex justify-content-between">
                  {t("working_time_month")}
                  <div className="text-gray-600">
                    {payroll?.working_time}
                  </div>
                </div>
                <span className="separator my-5"></span>
                {payroll?.paid_absence_reasons.map((absence) => (
                  <div key={absence.id}>
                    <div className="d-flex justify-content-between">
                      {absence.name}
                      <div className="text-gray-600">
                        {absence.times}
                      </div>
                    </div>
                    <span className="separator my-5"></span>
                  </div>
                ))}
                <div className="d-flex justify-content-between fw-bold">
                  {t("total_time")}
                  <div className="text-gray-600">
                    {payroll?.total_work_absence_times}
                  </div>
                </div>
                <span className="separator my-5"></span>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-6">
          <div className="card mb-5 mb-xl-8">
            <div className="card-body pt-2">
              <div className="py-2 fs-6">
                <div className="d-flex justify-content-between align-items-baseline">
                  <div>
                    {t("remuneration_payrolls.target_hours_working_days")}
                    <span
                      data-bs-toggle="tooltip"
                      data-bs-custom-class="tooltip-inverse"
                      data-bs-placement="top"
                      title={t('remuneration_payrolls.sum_target_hours_monthly')}
                      className='btn btn-sm mb-1 pb-2 ps-1 pe-0'
                    >
                      <QuestionMark />
                    </span>
                  </div>
                  <div className="text-gray-600">
                    {payroll?.desired_working_hours}
                  </div>
                </div>
                <span className="separator my-5"></span>
                {payroll?.has_wage_benefits_overtime &&
                  <div>
                    <div className="d-flex justify-content-between align-items-baseline">
                      <div>
                        {t("remuneration_payrolls.target_hours_worked_days")}
                        <span
                          data-bs-toggle="tooltip"
                          data-bs-custom-class="tooltip-inverse"
                          data-bs-placement="top"
                          title={t('remuneration_payrolls.sum_target_hours_worked_days')}
                          className='btn btn-sm mb-1 pb-2 ps-1 pe-0'
                        >
                          <QuestionMark />
                        </span>
                      </div>
                      <div className="text-gray-600">
                        {payroll?.finished_desired_working_hours}
                      </div>
                    </div>
                    <span className="separator my-5"></span>
                  </div>
                }
                <div className="d-flex justify-content-between align-items-baseline">
                  <div>
                    <div>
                      {t("remuneration_payrolls.billed_hours")}
                      <span
                        data-bs-toggle="tooltip"
                        data-bs-custom-class="tooltip-inverse"
                        data-bs-placement="top"
                        title={t('remuneration_payrolls.billed_hours_tooltip')}
                        className='btn btn-sm mb-1 pb-2 ps-1 pe-0'
                      >
                        <QuestionMark />
                      </span>
                    </div>
                    <small className="fst-italic">{t("remuneration_payrolls.from_the_az_account")} {payroll?.hours_from_work_account}</small>
                  </div>
                  <div className="text-gray-600">
                    {payroll?.can_recalculate_payroll && !payroll?.has_bookings_inprocess ?
                      <ClosedAtInput attribute={"settled_work_hours"} action={recalculatePayroll} /> :
                      payroll?.settled_work_hours
                    }
                  </div>
                </div>
                <span className="separator my-5"></span>
                <div className="d-flex justify-content-between fw-bold">
                  {t("remuneration_payrolls.comment_for_staff")}
                  <div className="text-gray-600">
                    <ClosedAtInput attribute={"comment_for_staff"} action={updatePayroll} />
                  </div>
                </div>
                <span className="separator my-5"></span>
                <div className="d-flex justify-content-between fw-bold">
                  {t("remuneration_payrolls.comment_for_payroll_office")}
                  <div className="text-gray-600">
                    <ClosedAtInput attribute={"comment_for_office"} action={updatePayroll} />
                  </div>
                </div>
                <span className="separator my-5"></span>
              </div>
            </div>
          </div>
        </div>
      </div >
      {/* Last 2 Cards */}
      <div className="d-flex row">
        <div className="col-12 col-lg-6">
          <div className="card mb-5 mb-xl-8">
            <div className="card-body pt-2">
              <div className="py-2 fs-6">
                {payroll?.has_wage_benefits_overtime &&
                  <div>
                    <div className="d-flex justify-content-between">
                      {payroll?.wage_benefits_overtime_rule_name}
                      <div className="text-gray-600">
                        {payroll?.wage_benefits_overtime}
                      </div>
                    </div>
                    <span className="separator my-5"></span>
                  </div>
                }
                {payroll?.wage_benefits_without_overtime.map((wage_benefit) => (
                  <div key={wage_benefit.id}>
                    <div className="d-flex justify-content-between">
                      {wage_benefit.name}
                      <div className="text-gray-600">
                        {wage_benefit.times}
                      </div>
                    </div>
                    <span className="separator my-5"></span>
                  </div>
                ))}
                {payroll?.has_bonus_payment &&
                  <div>
                    <div className="d-flex justify-content-between">
                      {t("remuneration_payrolls.special_surcharge")}
                      <ClosedAtInput attribute={"bonus_payment"} action={updatePayroll} />
                    </div>
                    <span className="separator my-5"></span>
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-6">
          <div className="card mb-5 mb-xl-8">
            <div className="card-body pt-2">
              <div className="py-2 fs-6">
                <div className="d-flex justify-content-between">
                  {t("status")}
                  {
                    payroll?.payroll_is_closed ? (
                      <div className="badge badge-light-dark d-inline">
                        {t('closed')}
                      </div>
                    ) : (
                      <div className="badge badge-warning d-inline">
                        {t('open')}
                      </div>
                    )
                  }
                </div>
                <span className="separator my-5"></span>
                <div className="d-flex justify-content-between">
                  {t("remuneration_payrolls.working_time_previous_month")}
                  <div className="text-gray-600">
                    {payroll?.previous_work_account}
                  </div>
                </div>
                <span className="separator my-5"></span>
                <div className="d-flex justify-content-between align-items-baseline fw-bold">
                  <div>
                    <div>
                      {t("remuneration_payrolls.working_time_actual")}
                      <span
                        data-bs-toggle="tooltip"
                        data-bs-custom-class="tooltip-inverse"
                        data-bs-placement="top"
                        title={t('remuneration_payrolls.working_time_actual_tooltip')}
                        className='btn btn-sm mb-1 pb-2 ps-1 pe-0'
                      >
                        <QuestionMark />
                      </span>
                    </div>
                    {
                      payroll?.payroll_is_closed &&
                      <small className="fst-italic">{payroll?.work_account_difference} {t("remuneration_payrolls.hours_previous_month")}</small>
                    }
                  </div>
                  <div className="text-gray-600">
                    {payroll?.can_recalculate_payroll && !payroll?.has_bookings_inprocess ?
                      <ClosedAtInput attribute={"work_account"} action={recalculatePayroll} /> :
                      payroll?.work_account
                    }
                  </div>
                </div>
                <span className="separator my-5"></span>
                {
                  !payroll?.payroll_is_closed &&
                  <div>
                    <div className="d-flex justify-content-between align-items-baseline">
                      <div className="w-50">
                        {t("remuneration_payrolls.working_time_current_month")}
                        <br></br>
                        <small className="fst-italic">{payroll?.work_account_sum_difference} {t("remuneration_payrolls.hours_previous_month")}</small>
                      </div>
                      <div className="text-gray-600">
                        {payroll?.work_account_sum}
                      </div>
                    </div>
                    <span className="separator my-5"></span>
                  </div>
                }
                <div>
                  <div className="d-flex justify-content-between fw-bold">
                    {t("remuneration_payrolls.vacation_account_actual")}
                    <ClosedAtInput attribute={"vacation_account"} action={updatePayroll} />
                  </div>
                </div>
                <span className="separator my-5"></span>
              </div>
            </div>
          </div>
        </div>
      </div >
    </>
  );
}

export default PersonalDetails;
