import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import BaseTable from '../Table';
import { axiosInstance, routes } from '../../utils/api_base';

const AdditionalCosts = ({order}) => {
  const { t, i18n } = useTranslation()

  const getAdditionalCosts = async (pagination, sorting, globalFilter, customFilters) => {
    const res = await axiosInstance.get(routes.orderAdditionalCosts(order.id))

    return({ rows: res.data })
  }

  const columns = useMemo(() => {
      return(
        [
          {
            id: 'description',
            accessorFn: row => row?.description,
            header: t('description'),
            enableSorting: false,
            cell: row => row.getValue(),
          },
          {
            id: 'costs',
            accessorFn: row => row?.costs,
            header: t('costs'),
            enableSorting: false,
            cell: row => row.getValue(),
          },
          {
            id: 'revenue_distribution',
            accessorFn: row => row?.revenue,
            header: t('revenue_distribution'),
            enableSorting: false,
            cell: row => row.getValue(),
          },
          {
            id: 'profit_loss',
            accessorFn: row => row?.profit,
            header: t('profit_loss'),
            enableSorting: false,
            cell: row => row.getValue(),
          },
        ]
      )
    }, [i18n.language]
  )
  return (
    <BaseTable
      keyName="AdditionalCosts"
      enablePagination={false}
      showSearchInput={false}
      columns={columns}
      getDataFn={getAdditionalCosts}
      tableToolbar={()=>{}}
    />
  )
};

export default AdditionalCosts;
