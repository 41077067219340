import React, { useMemo } from 'react';
import { axiosInstance, routes } from '../../utils/api_base';
import { useTranslation } from 'react-i18next';
import { pushToHistory } from '../../utils/base';
import BaseTable from '../../components/Table';
import { isEmpty } from 'lodash';

const Table = ({ filters, tableToolbar, businessPartnerId }) => {
  const { t, i18n } = useTranslation()

  const getChronicles = async (pagination, sorting, globalFilter, customFilters) => {
    const { pageIndex, pageSize } = pagination
    const sortQuery = sorting.map((attr) => {
      return attr.desc ? `${attr.id} desc` : attr.id
    })
    let params = { business_partner_id: businessPartnerId }
    if (globalFilter) {
      params.query = globalFilter
    }
    if (!isEmpty(customFilters)) {
      params = { ...params, ...customFilters }
    }
    const res = await axiosInstance.get(routes.fieldsDriverInputs(), { params: params })
    pushToHistory(params)

    const groupedData = res.data.data.reduce((acc, item) => {
      const field_name = item?.attributes?.field_name;
      if (!acc[field_name]) {
        acc[field_name] = [];
      }
      acc[field_name].push(item);
      return acc;
    }, {});

    const rows = Object.entries(groupedData).flatMap(([field_name, items]) => [
      { isDateHeader: true, field_name },
      ...items
    ]);

    return (
      { rows: rows }
    )
  }

  const orderHtml = (row) => {
    return (
      <>
        {row.row.original.attributes?.order_id && (
          <a href={`/orders/${row.row.original.attributes.order_id}`}>
            {row.row.original.attributes.order_name}
          </a>
        )}
      </>
    )
  }

  const timeHtml = (row) => {
    return (
      <>
        {row.row.original.attributes?.time && (
          <a href={`/booking_results/order_with_times?order=${row.row.original.attributes.order_id}`}>
            {row.row.original.attributes.time}
          </a>
        )}
      </>
    )
  }

  const resultItems = (row) => {
    return (
      <>
        {row.length > 0 && row?.map((item, index) => (
          <div className='small'>
            <div key={index} style={{ display: 'flex', marginBottom: '10px' }}>
              <div style={{ fontWeight: 'bold', marginRight: '10px' }}>{item.name}:</div>
              <div>{item.value || 'N/A'}</div>
            </div>
            <div className="separator mb-3"></div>
          </div>
        ))}
      </>
    );
  }

  const columns = useMemo(
    () => [
      {
        id: 'field',
        header: t('field'),
        size: 40,
        minSize: 40,
        enableSorting: false,
        accessorFn: row => row.isDateHeader ? row?.field_name : '',
        cell: row => {
          const value = row.getValue();
          return row.row.original.isDateHeader ? <strong className='fs-4'>{value}</strong> : value;
        },
      },
      {
        id: 'time',
        header: t('date'),
        size: 40,
        minSize: 40,
        enableSorting: false,
        accessorFn: row => row.isDateHeader ? "" : row?.attributes?.time,
        cell: row => {
          const value = row.getValue();
          return row.row.original.isDateHeader ? <strong className='fs-1'>{value}</strong> : timeHtml(row);
        },
      },
      {
        id: 'order',
        header: t('order'),
        accessorFn: row => row.isDateHeader ? '' : row?.attributes,
        cell: row => row.isDateHeader ? '' : orderHtml(row),
        enableSorting: false
      },
      {
        id: 'service',
        header: t('service'),
        accessorFn: row => row.isDateHeader ? '' : row?.attributes?.service_name,
        cell: row => row.isDateHeader ? '' : row.getValue(),
        enableSorting: false
      },
      {
        id: 'driver',
        header: t('driver'),
        accessorFn: row => row.isDateHeader ? '' : row?.attributes?.staff_name,
        cell: row => row.isDateHeader ? '' : row.getValue(),
        enableSorting: false
      },
      {
        id: 'items',
        header: '',
        accessorFn: row => row.isDateHeader ? '' : row?.attributes?.booking_result_items,
        cell: row => row.isDateHeader ? '' : resultItems(row.getValue()),
        enableSorting: false
      },
    ],
    [i18n.language]
  )

  return (
    <BaseTable
      columns={columns}
      getDataFn={getChronicles}
      tableToolbar={tableToolbar}
      enablePagination={false}
      showSearchInput={false}
    />
  )
}

export default Table;
