import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import BaseTable from '../Table';
import { axiosInstance, routes } from '../../utils/api_base';

const ContributionMarginPerTeam = ({order, bookingCodes}) => {
  const { t, i18n } = useTranslation()

  const getStaffOrders = async (pagination, sorting, globalFilter, customFilters) => {
    const res = await axiosInstance.get(routes.orderStaffOrders(order.id))

    console.log('THIS IS DATA', res.data)
    return({ rows: res.data })
  }

  const nameHtml = (row) => {
    if (row.original?.id) {
      return (
        <a href={row?.original?.link}>{row?.original?.name}</a>
      )
    } else {
      return (
        <div>
          <div>{row.original?.name}</div>
          <div>{row.original?.service_name}</div>
        </div>
      )
    }
  }

  const bookingCodeHtml = (row, code) => {
    return (
        <div>{row.original[code]}</div>
    )
  }

  const columns = useMemo(() => {
    const bookingCodesColumns = bookingCodes.map(bookingCode => {
      return {
        id: bookingCode.code,
        header: t(`booking_codes.${bookingCode.code}`),
        accessorFn: row => row[bookingCode.code],
        cell: ({ row }) => {
          return bookingCodeHtml(row, bookingCode.code)
        },
      }
    })
    return(
      [
        {
          id: 'name',
          header: undefined,
          accessorFn: row => row?.name,
          enableSorting: false,
          cell: ({ row }) => {
            return nameHtml(row)
          },
        }
      ].concat(bookingCodesColumns)
      .concat(
        [
          {
            id: 'total',
            header: t('total'),
            accessorFn: row => row.total,
            cell: row => row.getValue(),
          },
          {
            id: 'working_hours',
            header: t('hour_of_use'),
            accessorFn: row => row?.working_hours,
            cell: row => row.getValue(),
          },
          {
            id: 'travel_time',
            header: t('travel_time_hour'),
            accessorFn: row => row.travel_time,
            cell: row => row.getValue(),
          },
          {
            id: 'revenue_distribution',
            header: t('revenue_distribution'),
            accessorFn: row => row.revenue_distribution,
            cell: row => row.getValue(),
          },
          {
            id: 'profit_loss',
            header: t('profit_loss'),
            accessorFn: row => row.profit_loss,
            cell: row => row.getValue(),
          }
        ]
      )
    )
    }, [i18n.language, bookingCodes]
  )
  return (
    <BaseTable
      keyName="ContributionMarginPerTeam"
      enablePagination={false}
      showSearchInput={false}
      columns={columns}
      getDataFn={getStaffOrders}
      tableToolbar={()=>{}}
    />
  )
};

export default ContributionMarginPerTeam;
