import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import BaseTable from '../Table';
import { axiosInstance, routes } from '../../utils/api_base';
import TableButton from '../Table/TableButton';

const ContributionMarginPerArticle = ({order, bookingCodes}) => {
  const { t, i18n } = useTranslation()

  const getArticles = async (pagination, sorting, globalFilter, customFilters) => {
    const res = await axiosInstance.get(routes.orderStatisticArticles(order.id))

    return({ rows: res.data })
  }

  const nameHtml = (row) => {
    return(
      <TableButton
        target="_blank"
        href={row?.original?.link}
        className="btn-light-primary me-3"
        title={row?.original?.long_name}
      />
    )
  }

  const quantityHtml = (row) => {
    return(
      <span>{`${row?.original?.cost_quantity.toFixed(2)} ${row?.original?.unit_name}`}</span>
    )
  }

  const columns = useMemo(() => {
    return(
      [
        {
          id: 'name',
          header: t('article'),
          accessorFn: row => row?.long_name,
          enableSorting: true,
          cell: ({ row }) => {
            return nameHtml(row)
          },
        },
        {
          id: 'quantity',
          header: t('quantity'),
          accessorFn: row => row?.cost_quantity,
          enableSorting: true,
          cell: ({ row }) => {
            return quantityHtml(row)
          },
        },
        {
          id: 'per_unit',
          accessorFn: row => row?.cost_per_unit,
          header: t('per_unit'),
          enableSorting: false,
          cell: row => row.getValue(),
        },
        {
          id: 'costs',
          accessorFn: row => row?.costs,
          header: t('costs'),
          enableSorting: false,
          cell: row => row.getValue(),
        },
        {
          id: 'revenue_distribution',
          accessorFn: row => row?.revenue,
          header: t('revenue_distribution'),
          enableSorting: false,
          cell: row => row.getValue(),
        },
        {
          id: 'profit_loss',
          accessorFn: row => row?.profit,
          header: t('profit_loss'),
          enableSorting: false,
          cell: row => row.getValue(),
        },
      ]
    )
    }, [i18n.language]
  )
  return (
    <BaseTable
      keyName="ContributionMarginPerArticle"
      enablePagination={false}
      showSearchInput={false}
      columns={columns}
      getDataFn={getArticles}
      tableToolbar={()=>{}}
    />
  )
};

export default ContributionMarginPerArticle;
