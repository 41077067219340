import React from 'react';

const SpareParts = ({ machineData }) => {
  return (
    <div className="card card-flush">
      <div className="card-body">
        <table className="table align-middle table-row-dashed fs-6 gy-5 dataTable">
          <tbody className="fw-semibold text-gray-600">
            {
              machineData.attributes.spare_parts.map((sparePart, index) => {
                 return(
                    <tr key={index}>
                      <td>
                        {sparePart.article_name}
                      </td>
                      <td>
                        {`${sparePart.quantity} ${sparePart.unit}`}
                      </td>
                      <td>
                        {sparePart.costs}
                      </td>
                    </tr>
                  )
                }
              )
            }
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default SpareParts;
