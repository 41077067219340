import React, { useEffect, useState } from 'react'
import TableButton from '../../components/Table/TableButton';
import Toastr from '../../components/Toastr';
import { ArrowsCircleIcon, CloudDownloadIcon } from '../../components/Icons';
import { PencilIcon } from '../../components/Icons';
import { axiosInstance, routes } from '../../utils/api_base';
import { useTranslation } from 'react-i18next';

const EditCustomizingModal = ({ onClose, plantProtection }) => {
  const { t } = useTranslation()
  const [checkboxValue, setCheckboxValue] = useState(false);
  const [disableCheckbox, setDisableCheckbox] = useState(true);
  const [customizingId, setCustomizingId] = useState(true);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getCurentCustomizing();
  }, []);

  const getCurentCustomizing = async () => {
    axiosInstance
      .get(routes.currentCustomizing())
      .then(response => {
        setCheckboxValue(response.data.data?.attributes?.show_article_tab_in_order)
        setDisableCheckbox(false)
        setCustomizingId(response?.data.data?.id)
        setLoading(false)
      })
      .catch(error => {
        console.log(error)
      })
  };

  const handleCheckboxChange = async (event) => {
    setCheckboxValue(event.target.checked);
    axiosInstance
      .put(routes.customizing(customizingId), { show_article_tab_in_order: event.target.checked })
      .then(response => {
        Toastr({
          title: t('success'),
          options: { closeButton: true }
        })
      })
      .catch(error => {
        console.log(error)
      })
  };

  const handleLinkClick = (event) => {
    event.preventDefault();
    if (window.confirm(textForPlantProtection('question'))) {
      axiosInstance
        .put(routes.articlesLoadPlantProtectionProducts())
        .then(response => {
          Toastr({
            message: response.data.notice,
            options: { closeButton: true }
          })
          window.location = response.data.redirect_url
        })
        .catch(error => {
          console.log(error)
        })
    }
  };

  const textForPlantProtection = (key) => {

    const texts = {
      title: plantProtection ? t('update_pesticides') : t('load_crop_production_into_storage'),
      question: plantProtection ? t('update_pesticides_confirm_question') : t('load_crop_production_into_storage_confirm_question'),
    }

    return texts[key]
  }

  return (
    <>
      {loading ? (
        <div className="text-center d-flex py-5 h-300px align-items-center justify-content-center">
          <div className="spinner-border" role="status"></div>
        </div>
      ) : (
        <>
          <div className="modal-body">
            <div className="row mb-3">
              <div className="col">
                <span className='text-gray-600'>{t('article_groups')}</span>
              </div>
              <div className="col">
                <TableButton
                  href='/article_groups'
                  className="btn-light-primary text-start"
                  icon={<PencilIcon />}
                  title={t('edit_article_groups')}
                />
              </div>
            </div>
            <div className="row mb-3">
              <div className="col">
                <span className='text-gray-600'>{t('units_word')}</span>
              </div>
              <div className="col">
                <TableButton
                  href='/article_groups'
                  className="btn-light-primary text-start"
                  icon={<PencilIcon />}
                  title={t('edit_units')}
                />
              </div>
            </div>
            <div className="row mb-3">
              <div className="col">
                <span className='text-gray-600'>{t('show_article')}</span>
              </div>
              <div className="col">
                  <div className="form-check form-switch form-check-warning form-check-solid">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="show_article_tab_in_order"
                    checked={checkboxValue}
                    onChange={handleCheckboxChange}
                    disabled={disableCheckbox}
                  />
                </div>
              </div>
              <div>
              </div>
            </div>
            <div className="row mb-3">
              <div className="col">
                <span className='text-gray-600'>{t('plant_protection')}</span>
              </div>
              <div className="col">
                <TableButton
                  className="btn-light-primary text-start"
                  rel="nofollow"
                  onClick={handleLinkClick}
                  title={textForPlantProtection('title')}
                  icon={plantProtection ? <ArrowsCircleIcon className='fs-2' /> : <CloudDownloadIcon className='fs-2' />}
                />
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <TableButton
              className="btn btn-light btn-active-light-primary fw-semibold me-2 px-6"
              type="button"
              onClick={onClose}
              title={t('cancel')}
            />
          </div>
        </>
      )}
    </>
  )
}

export default EditCustomizingModal;
