import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Swal from 'sweetalert2';
import { TrashSquareIcon, PlusIcon } from '../Icons';
import Toastr from '../Toastr';
import { axiosInstance, routes } from '../../utils/api_base';
import EditableDatePickerInput from '../Table/EditableDatePickerInput';
import EditableTextInput from '../Table/EditableTextInput';
import TableButton from '../Table/TableButton';
import ChangePricesForm from '../../pages/BusinessPartnerServiceFeatures/ChangePricesForm';
import DefinePriceForm from '../../pages/BusinessPartnerServiceFeatures/DefinePriceForm';
import ImportPricesForm from '../../pages/BusinessPartnerServiceFeatures/ImportPricesForm';
import ServiceFeatureTable from '../../pages/BusinessPartnerServiceFeatures/ServiceFeatureTable';

const Prices = ({ businessPartnerId }) => {
  const { t, i18n } = useTranslation();
  const [serviceFeatures, setServiceFeatures] = useState([]);
  const [services, setServices] = useState([]);

  useEffect(() => {
    fetchData(routes.businessPartnerServiceFeatures(), setServiceFeatures);
    fetchData(routes.businessPartnerServices(), setServices);
  }, []);

  const fetchData = async (url, setData) => {
    const params = { business_partner_id: businessPartnerId };
    try {
      const res = await axiosInstance.get(url, { params });
      setData(res.data.data);
    } catch (error) {
      console.error(error);
    }
  };

  const addBPServiceFeature = (serviceId) => {
    const params = { business_partner: businessPartnerId, service: serviceId }

    axiosInstance.post(routes.businessPartnerServiceFeatures(), params)
      .then(() => {
        Toastr({ message: t("create_success") });
        window.location.reload()
      })
      .catch((error) => {
        Toastr({
          type: "error",
          message: error.response?.data?.error || t("critical_error")
        });
      });
  }

  const deleteBPServiceFeature = (id) => {
    Swal.fire({
      title: t("shared.confirmation_question"),
      width: 400,
      toast: true,
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonText: t("shared.delete"),
      cancelButtonText: t("cancel"),
      allowEscapeKey: false
    }).then((result) => {
      if (result.isConfirmed) {
        axiosInstance.delete(routes.businessPartnerServiceFeature(id))
          .then(() => {
            Toastr({ message: t("delete_success") });
            window.location.reload()
          })
          .catch((error) => {
            Toastr({
              type: "error",
              message: error.response?.data?.error || t("critical_error")
            });
          });
      }
    });
  };

  const updateBusinessPartnerServiceFeature = (id, params) => {
    axiosInstance.patch(routes.businessPartnerServiceFeature(id), params)
      .then(() => {
        Toastr({ message: t("update_success") });
        fetchData(routes.businessPartnerServiceFeatures(), setServiceFeatures);
        fetchData(routes.businessPartnerServices(), setServices);
      })
      .catch((error) => {
        Toastr({
          type: "error",
          message: error.response?.data?.error || t("critical_error")
        });
      });
  };

  // Find a base service feature for the service
  const baseFeatureFor = (service) => {
    return serviceFeatures.find(sf => sf.attributes?.service_id == service.id && sf.attributes?.base_feature);
  };

  return (
    <>
      <div className="card card-flush">
        <div className="card-body">
          <DefinePriceForm businessPartnerId={businessPartnerId} />
          <ImportPricesForm businessPartnerId={businessPartnerId} />
          <ChangePricesForm businessPartnerId={businessPartnerId} />
        </div>
      </div>

      {
        services.map((service, index) => {
          const baseFeature = baseFeatureFor(service);

          return (
            <div key={index} className="mt-6 mb-4">
              <div className="px-8 mb-4 d-flex">
                <div>
                  <div className="d-flex align-items-center">
                    <a href={service.attributes?.link}>
                      {service.attributes?.long_name}
                    </a>
                    <div className="cursor-pointer" onClick={() => deleteBPServiceFeature(baseFeature?.id)}>
                      <TrashSquareIcon className="fs-2hx text-danger" />
                    </div>
                  </div>

                  <div className="d-flex align-items-center">
                    <div className="me-2">{t("business_partners.rough_period_from")}</div>
                    <EditableDatePickerInput
                      id={baseFeature?.attributes?.id}
                      attribute="start_date"
                      placeholder={t("start_date")}
                      initialValue={baseFeature?.attributes?.formatted_start_date}
                      handleSubmit={updateBusinessPartnerServiceFeature}
                    />
                    <div className="mx-2">{t("shared.until")}</div>
                    <EditableDatePickerInput
                      id={baseFeature?.attributes?.id}
                      attribute="end_date"
                      placeholder={t("end_date")}
                      initialValue={baseFeature?.attributes?.formatted_end_date}
                      handleSubmit={updateBusinessPartnerServiceFeature}
                    />
                  </div>

                  <div className="d-flex align-items-center">
                    <div className="me-2">{t("shared.comment")}:</div>
                    <EditableTextInput
                      id={baseFeature?.attributes?.id}
                      attribute="comment"
                      placeholder={t("comment")}
                      initialValue={baseFeature?.attributes?.comment || "- - - - -"}
                      handleSubmit={updateBusinessPartnerServiceFeature}
                    />
                  </div>

                  <div className="d-flex align-items-center">
                    <div className="me-2">{t("shared.order_volume")}:</div>
                    <EditableTextInput
                      id={baseFeature?.attributes?.id}
                      attribute="hectare"
                      placeholder={t("measurement_unit.ha")}
                      initialValue={baseFeature?.attributes?.hectare || "- - - - -"}
                      handleSubmit={updateBusinessPartnerServiceFeature}
                    />
                    <div className="mx-2">{t("measurement_unit.ha")}</div>

                    {
                      baseFeature?.attributes?.service_unit_name !== "ha" &&
                      <>
                        <EditableTextInput
                          id={baseFeature?.attributes?.id}
                          attribute="quantity"
                          placeholder={t("quantity")}
                          initialValue={baseFeature?.attributes?.quantity || "- - - - -"}
                          handleSubmit={updateBusinessPartnerServiceFeature}
                        />
                        <div className="mx-2">
                          {t(`measurement_unit.${baseFeature?.attributes?.service_unit_name}`)}
                        </div>
                      </>
                    }
                  </div>
                </div>

                <TableButton
                  className="btn-warning ms-auto mt-auto"
                  size="small"
                  onClick={() => addBPServiceFeature(service.id)}
                  icon={<PlusIcon />}
                  title={t("service_features.add_price_surcharge")}
                />
              </div>

              <ServiceFeatureTable
                businessPartnerId={businessPartnerId}
                serviceId={service.attributes?.id}
                updateBusinessPartnerServiceFeature={updateBusinessPartnerServiceFeature}
              />
            </div>
          );
        })
      }
    </>
  );
};

export default Prices;
