import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import queryString from 'query-string';
import Overview from '../../components/BusinessPartners/Overview';
import Statistics from '../../components/BusinessPartners/Statistics';
import Fields from '../../components/BusinessPartners/Fields';
import Prices from '../../components/BusinessPartners/Prices';
import DetailSideBar from '../../components/BusinessPartners/sidebar';
import Contracts from '../../components/BusinessPartners/Contracts';
import { pushToHistory } from '../../utils/base';
import SettingsPanel from '../../components/BusinessPartners/SettingsPanel';

const BusinessPartner = ({id, payment_terms, creatorName, currentStaffId, companyId,
                            businessPartner, currentLocation, salesForce, group, country, solvency, businessAreas, solvencies,
                            orderLimitChangedBy, solvencyChangedBy}) => {

  const { t } = useTranslation()
  const parsedQuery = queryString.parse(location.search)
  const [activeTab, setActiveTab] = useState(parsedQuery.tab || 'overview')
  const [showAllFields, setShowAllFields] = useState(false)
  const [selectedField, setSelectedField] = useState({})
  const appStore = ReactOnRails.getStore("appStore");
  const { currentCustomizing } = appStore.getState()?.appStore || {};
  const [trigger, setTrigger] = useState(Math.floor(Date.now() / 1000))

  useEffect(() => {
    pushToHistory({ tab: activeTab })
  }, [activeTab])

  return (
    <>
      <SettingsPanel paymentTerms={payment_terms} businessPartner={businessPartner} id={id}/>
      <div className='d-flex flex-column flex-lg-row'>
        <DetailSideBar
          creatorName={creatorName}
          businessPartner={businessPartner}
          group={group}
          currentLocation={currentLocation}
          salesForce={salesForce}
          businessAreas={businessAreas}
          activeTab={activeTab}
          handleShowAllFields={() => { setShowAllFields(!showAllFields) }}
          setSelectedField={setSelectedField}
          trigger={trigger}
          />
        <div className='flex-lg-row-fluid ms-lg-15'>
          { businessPartner.customer ? (
            <>
              <Tabs
                onSelect={(k) => {
                  setActiveTab(k)
                  pushToHistory({ tab: k })
                }}
                defaultActiveKey={activeTab}
                id="partner-tabs"
                className="nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-semibold mb-8"
              >
                <Tab eventKey="overview" title={t('overview')} mountOnEnter={true} unmountOnExit={true}>
                  <Overview businessPartnerId={id}
                    currentStaffId={currentStaffId}
                    companyId={companyId}
                    businessPartner={businessPartner}
                    country={country}
                    paymentTerms={payment_terms}
                    solvency={solvency}
                    solvencies={solvencies}
                    solvencyChangedBy={solvencyChangedBy}
                    orderLimitChangedBy={orderLimitChangedBy}/>
                </Tab>
                <Tab eventKey="prices" title={t('prices')} mountOnEnter={true} unmountOnExit={true}>
                  <Prices businessPartnerId={id} />
                </Tab>
                <Tab eventKey="fields" title={t('fields')} mountOnEnter={true} unmountOnExit={true}>
                  <Fields setTrigger={setTrigger} trigger={trigger} selectedField={selectedField} showAllFields={showAllFields} businessPartner={businessPartner}/>
                </Tab>
                <Tab eventKey="statistic" title={t('statistics')} mountOnEnter={true} unmountOnExit={true}>
                  <Statistics businessPartnerId={id} companyId={companyId}/>
                </Tab>
                {currentCustomizing?.use_yearly_treaties_in_business_partners &&
                  <Tab eventKey="contracts" title={t('contracts')} mountOnEnter={true} unmountOnExit={true}>
                    <Contracts businessPartnerId={id} activeTab={activeTab} />
                  </Tab>}
              </Tabs>
            </>
          )
          :
            <Overview businessPartnerId={id}
              currentStaffId={currentStaffId}
              companyId={companyId}
              businessPartner={businessPartner}
              country={country}
              paymentTerms={payment_terms}
              solvency={solvency}
              solvencies={solvencies}
              solvencyChangedBy={solvencyChangedBy}
              orderLimitChangedBy={orderLimitChangedBy} />
            }
        </div>
      </div>
    </>
  )
};

export default BusinessPartner;
