import React, {useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ContributionMarginChart from './ContributionMarginChart';
import CostStructureChart from './CostStructureChart';
import WorkingTimeDistributionChart from './WorkingTimeDistributionChart';
import ContributionMarginPerTeam from './ContributionMarginPerTeam';
import ContributionMarginPerArticle from './ContributionMarginPerArticle';
import {axiosInstance, routes} from '../../utils/api_base';
import AdditionalCosts from './AdditionalCosts';
import ContributionMarginPerResource from './ContributionMarginPerResource';

const Statistics = ({order}) => {
  const { t } = useTranslation();
  // booking codes used in table and working time distribution chart
  const [bookingCodes, setBookingCodes] = useState([]);

  const getBookingCodes = (orderId) => {
    axiosInstance.get(routes.orderBookingCodes(orderId))
      .then(response => {
        setBookingCodes(response.data)
      })
      .catch(error => {
        console.log('error', error)
      })
  }

  useEffect(() => {
    getBookingCodes(order.id);
  }, []);

  return (
    <div>
      <div className="d-flex flex-row gap-5 align-items-start">
        <ContributionMarginChart order={order} />
        <CostStructureChart order={order} />
        <WorkingTimeDistributionChart bookingCodes={bookingCodes} />
      </div>
      <div>
        <div>
          <h2>{t('contribution_margin_per_team')}</h2>
          <ContributionMarginPerTeam order={order} bookingCodes={bookingCodes} />
        </div>
        <div>
          <h2>{t('contribution_margin_per_article')}</h2>
          <ContributionMarginPerArticle order={order} />
        </div>
        <div>
          <h2>{t('additional_costs')}</h2>
          <AdditionalCosts order={order} />
        </div>
        <div>
          <h2>{t('contribution_margin_per_resource')}</h2>
          <ContributionMarginPerResource order={order} />
        </div>
      </div>
    </div>
  )
};

export default Statistics;
