import React from "react";
import Button from "../../components/Button";

import classNames from 'classnames'

import { AddIcon, ArrowLeftIcon } from '../../components/Icons'
import TimeSwitch from "./TimeSwitch";
import TypeSwitch from "./TypeSwitch";
import HeaderTitle from "./HeaderTitle";

const Header = ({ addOrder, onSelectViewType, viewType, onClickPrev, onClickNext, type, onChangeType, onClickToday, startTime, endTime, loading = false }) => {

    return (
        <div className="btn-toolbar justify-content-between align-items-center my-2" role="toolbar"
            aria-label="Toolbar with button groups">
            <div className={"btn-toolbar mx-2"}>
                <TimeSwitch onClickNext={onClickNext} onClickPrev={onClickPrev} onClickToday={onClickToday} />
                <TypeSwitch type={type} onChangeType={onChangeType} />
            </div>

            <HeaderTitle type={type} startTime={startTime} endTime={endTime} loading={loading} />

            <div>
                <div className="btn-group">
                    <label
                        className={classNames("btn btn-outline btn-active-warning btn-sm", viewType === 'resource' && 'active')}>
                        <button className="btn-check" onClick={() => onSelectViewType('resource')} />
                        Mitarbeiter
                    </label>

                    <label
                        className={classNames("btn btn-outline btn-active-warning btn-sm", viewType === 'machines' && 'active')}>
                        <button className="btn-check" onClick={() => onSelectViewType('machines')} />
                        Maschinen
                    </label>

                    <label
                        className={classNames("btn btn-outline btn-active-warning btn-sm", viewType === 'orders' && 'active')}>
                        <button className="btn-check" onClick={() => onSelectViewType('orders')} />
                        Aufträge
                    </label>
                </div>

                <button
                    className="btn btn-outline btn-active-warning btn-sm"
                    style={{ paddingRight: 8, marginLeft: 10 }}
                    onClick={() => addOrder(true)}
                >
                    <AddIcon />
                </button>
            </div>
        </div>
    )
}

export default Header
