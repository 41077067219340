import React from 'react';
import ServiceBusinessPartnerStatistics from '../../components/ServiceBusinessPartnerStatistics';
import { useTranslation } from 'react-i18next';

const Statistics = ({ businessPartnerId, companyId }) => {
  const { t } = useTranslation()

  const filters = [
    {
      label: t("active"),
      attribute: "active",
      select: true,
      include_all: true,
      options: [["1", t("active")], ["0", t("inactive")]]
    }
  ]

  return (
    <ServiceBusinessPartnerStatistics filters={filters} tab='statistics' businessPartnerId={businessPartnerId} company_id={companyId}/>
  )
};

export default Statistics;
