import React, { useState } from 'react';
import Toastr from '../../components/Toastr';
import { axiosInstance, routes } from '../../utils/api_base';
import EditableTextInput from '../../components/Table/EditableTextInput';
import { useTranslation } from 'react-i18next';
import Form from 'react-bootstrap/Form';
import EditableSelectInput from "../../components/Table/EditableSelectInput";

const UserProfile = ({ currentCountry, businessPartner, currentLocation }) => {
  const { t } = useTranslation()
  const [businessPartnerDetails, setBusinessPartnerDetails] = useState({
    active: businessPartner.active,
    client_specific_number: businessPartner.client_specific_number,
    location: { value: currentLocation?.id, label: currentLocation?.name },
    country: { value: currentCountry?.id, label: currentCountry?.name },
    name: businessPartner.name,
    postal_code: businessPartner.postal_code,
    city: businessPartner.city,
    fon: businessPartner.fon,
    email: businessPartner.email,
    fax: businessPartner.fax,
    street: businessPartner.street
  });

  const toggleHtml = (attribute) => {
    const isActive = businessPartnerDetails[attribute]
    const label = isActive ? t('true') : t('false')
    return (
      <Form.Check
        onChange={() => updateBusinessPartner(businessPartner.id, { [attribute]: !isActive })}
        checked={isActive}
        className="cursor-pointer"
        type="switch"
        id={attribute}
        label={label}
      />
    )
  }

  const updateBusinessPartner = (id, attrs) => {
    const params = {
      business_partner: {
        active: attrs.active,
        client_specific_number: attrs.client_specific_number,
        street: attrs.street,
        location_id: attrs.location?.value,
        country_id: attrs.country?.value,
        name: attrs.name,
        postal_code: attrs.postal_code,
        city: attrs.city,
        email: attrs.email,
        fax: attrs.fax,
        fon: attrs.fon
      }
    }
    axiosInstance.put(routes.businessPartner(id), params)
      .then(resp => {
        Toastr({ message: t('success'), options: { showDuration: 2000 } })
        setBusinessPartnerDetails((prevDetails) => ({
          ...prevDetails,
          ...attrs,
        }));
      })
      .catch(error => {
        Toastr({
          type: 'error',
          message: error.response?.data?.error || t('critical_error')
        })
      })
  }

  const getLocations = (inputText) => {
    let params = {
      page: 1,
      limit: 20,
      query: inputText,
      scope_name: 'default'
    }

    return axiosInstance.get(routes.locations(), { params: params })
      .then((response) => {
        return response.data.data.map((locations) => ({
          value: locations.attributes.id,
          label: locations.attributes.name,
        }));
      })
      .catch((error) => {
        console.log('ERROR', error)
      });
  };

  const getCountries = (inputText) => {
    let params = {
      page: 1,
      limit: 20,
      query: inputText
    }

    return axiosInstance.get(routes.countries(), { params: params })
      .then((response) => {
        let options = response.data.data.map((locations) => ({
          value: locations.attributes.id,
          label: locations.attributes.name,
        }))
        options.push({
          value: '',
          label: t('no_selection')
        })

        return options
      })
      .catch((error) => {
        console.log('ERROR', error)
      });
  };

  return (
    <div className="flex-column flex-lg-row-auto w-lg-250px w-xl-350px mb-10">
      <div className="card mb-5 mb-xl-8 top-0 position-md-sticky">
        <div className="card-header ribbon ribbon-end ribbon-clip">
          <span className="align-content-center fs-3 text-gray-800 fw-bold">
            <EditableTextInput
              id={businessPartner.id}
              attribute="name"
              initialValue={businessPartnerDetails.name}
              handleSubmit={updateBusinessPartner}
            />
          </span>
        </div>
        <div className="card-body">
          <div className="d-flex flex-stack fs-4 py-3">
            <div className="fw-bold align-content-center" >
              {t('details')}
            </div>
          </div>
          <div className="separator"></div>
          <div id="kt_user_view_details" className="collapse show">
            <div className="pb-5 fs-6">
              <div className='d-flex justify-content-between py-4'>
                <div className="fw-bold align-content-center">
                  {t("active")}
                </div>
                <div className="text-gray-600 cursor-pointer">
                  {toggleHtml('active')}
                </div>
              </div>
              <div className="separator"></div>
              <div className='d-flex justify-content-between py-4'>
                <div className="fw-bold align-content-center ">{t('shared.number')}</div>
                <div className="text-gray-600">
                  <EditableTextInput
                    id={businessPartner.id}
                    attribute="client_specific_number"
                    initialValue={businessPartnerDetails.client_specific_number}
                    handleSubmit={updateBusinessPartner}
                  />
                </div>
              </div>
              <div className="separator"></div>
              <div className='d-flex justify-content-between py-4'>
                <div className="fw-bold align-content-center ">{t('street')}</div>
                <div className="text-gray-600">
                  <EditableTextInput
                    id={businessPartner.id}
                    attribute="street"
                    initialValue={businessPartnerDetails.street}
                    handleSubmit={updateBusinessPartner}
                  />
                </div>
              </div>
              <div className="separator"></div>
              <div className='d-flex justify-content-between py-4'>
                <div className="fw-bold align-content-center ">{t('postcode')}</div>
                <div className="text-gray-600">
                  <EditableTextInput
                    id={businessPartner.id}
                    attribute="postal_code"
                    initialValue={businessPartnerDetails.postal_code}
                    handleSubmit={updateBusinessPartner}
                  />
                </div>
              </div>
              <div className="separator"></div>
              <div className='d-flex justify-content-between py-4'>
                <div className="fw-bold align-content-center ">{t('city')}</div>
                <div className="text-gray-600">
                  <EditableTextInput
                    id={businessPartner.id}
                    attribute="city"
                    initialValue={businessPartnerDetails.city}
                    handleSubmit={updateBusinessPartner}
                  />
                </div>
              </div>
              <div className="separator"></div>
              <div className='d-flex justify-content-between py-4'>
                <div className="fw-bold ">{t('country')}</div>
                <div className="text-gray-600">
                  <EditableSelectInput
                    id={businessPartner.id}
                    attribute={'country'}
                    returnObject={true}
                    initialValue={{ value: businessPartnerDetails.country?.value, label: businessPartnerDetails.country?.label }}
                    handleSubmit={updateBusinessPartner}
                    getEntities={getCountries}
                  />
                </div>
              </div>
              <div className="separator"></div>
              <div className='d-flex justify-content-between py-4'>
                <div className="fw-bold align-content-center">{t('phone')}</div>
                <div className="text-gray-600">
                  <EditableTextInput
                    id={businessPartner.id}
                    attribute="fon"
                    initialValue={businessPartnerDetails.fon}
                    handleSubmit={updateBusinessPartner}
                  />
                </div>
              </div>
              <div className="separator"></div>
              <div className='d-flex justify-content-between py-4'>
                <div className="fw-bold align-content-center ">{t('fax')}</div>
                <div className="text-gray-600">
                  <EditableTextInput
                    id={businessPartner.id}
                    attribute="fax"
                    initialValue={businessPartnerDetails.fax}
                    handleSubmit={updateBusinessPartner}
                  />
                </div>
              </div>
              <div className="separator"></div>
              <div className='d-flex justify-content-between py-4'>
                <div className="fw-bold align-content-center ">{t('email')}</div>
                <div className="text-gray-600">
                  <EditableTextInput
                    id={businessPartner.id}
                    attribute="email"
                    initialValue={businessPartnerDetails.email}
                    handleSubmit={updateBusinessPartner}
                  />
                </div>
              </div>
              <div className="separator"></div>
              <div className='d-flex justify-content-between py-4'>
                <div className="fw-bold align-content-center">{t('location')}</div>
                <div className="text-gray-600">
                  <EditableSelectInput
                    id={businessPartner.id}
                    attribute={'location'}
                    returnObject={true}
                    initialValue={{ value: businessPartnerDetails.location.value, label: businessPartnerDetails.location.label }}
                    handleSubmit={updateBusinessPartner}
                    getEntities={getLocations}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserProfile;
