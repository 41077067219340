import AsyncSelectAdapter from '../../Form/AsyncSelectAdapter';
import ErrorField from '../../Form/ErrorField';
import React, { useCallback } from 'react';
import TableButton from '../../Table/TableButton';
import Toastr from '../../Toastr';
import debounce from "lodash/debounce";
import { FORM_ERROR } from 'final-form'
import { Form, Field } from 'react-final-form'
import { axiosInstance, routes } from '../../../utils/api_base';
import { isRequired } from '../../Form/validations';
import { mapValues } from 'lodash';
import { useTranslation } from 'react-i18next';

const Create = ({ onClose, businessPartnerId }) => {
  const { t } = useTranslation();

  const onSubmit = async values => {
    const data = {
      plant_waste_analysis: {
        plant_waste_id: values.plant_waste_id?.value,
        business_partner_id: businessPartnerId,
      }
    }

    axiosInstance.post(routes.plantWasteAnalyses(), data)
      .then(response => {
        Toastr({
          title: t('success'),
          message: response.data.message,
          options: { closeButton: true }
        })
        onClose()
        if (response.data.redirect_url) window.location.href = response.data.redirect_url;
      })
      .catch(error => {
        const errorData = error.response.data
        if (errorData instanceof Array) {
          return mapValues(errorData.error, (val) => (val.join(', ')))
        } else {
          return { [FORM_ERROR]: error.message }
        }
      })
  }

  const fetchData = (url, params) => {
    return axiosInstance.get(url, { params })
      .then(response => response.data.data.map(item => ({
        value: item.id,
        label: item.attributes.name,
      })))
      .catch(error => {
        console.log('ERROR', error);
        return [];
      });
  };

  const createLoadOptions = (fetchFunction) =>
    useCallback(
      debounce((inputText, callback) => {
        fetchFunction(inputText).then(options => callback(options));
      }, 1000),
      []
    );

  const loadOptions = createLoadOptions((inputText) => {
    const params = { page: 1, limit: 35, sort: 'name', query: inputText, business_partner_id: businessPartnerId };
    return fetchData(routes.serviceFields(), params);
  })

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={{
        plant_waste_id: '',
      }}
      validate={values => {
        let errors = {}
        errors.plant_waste_id = isRequired(values.plant_waste_id)
        return errors
      }}
      render={({ submitError, handleSubmit, submitting, pristine }) => (
        <>
          <form onSubmit={handleSubmit}>
            <div className="row px-7 mb-3">
              <div className="col">
                <label>{t('type_of_farm_manure')}</label>
                <Field
                  name='plant_waste_id'
                  component={AsyncSelectAdapter}
                  loadOptions={loadOptions}
                  placeholder="Select"
                  isClearable={true}
                >
                </Field>
                <ErrorField name="plant_waste_id" />
              </div>
            </div>
            {submitError && <div className="form-error-message">{submitError}</div>}
            <div className="modal-footer">
              <TableButton
                className="btn btn-light btn-active-light-warning fw-semibold me-2 px-6"
                type="button"
                onClick={onClose}
                title={t('cancel')}
              />
              <TableButton
                className="btn btn-warning fw-semibold px-6"
                type="submit"
                title={t('create')}
                disabled={submitting || pristine}
              />
            </div>
          </form>
        </>
      )}
    />
  )
}

export default Create;
