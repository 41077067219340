import React from 'react';
import classNames from 'classnames';
import EditableTextInput from '../../components/Table/EditableTextInput';
import TableButton from '../../components/Table/TableButton';
import Input from '../../components/Input';
import { TrashSquareIcon } from '../../components/Icons';
import { useTranslation } from 'react-i18next';

const Sidebar = ({
  selectedPoint,
  deleteSelectedPoint,
  onSavePoint,
  editPointMode,
  drawingPoint,
  setEditPointMode,
  drawing,
  newFieldRef,
  onSaveDrawing,
  selectedField,
  onUpdateField,
  onCancelUpdatePoint,
  onCancelUpdateField,
  editMode,
  setEditMode,
  selected,
  drawingArea,
  drawingLine,
  targetFigure,
  deleteSelectedField,
  deleteSelectedGeometry,
  onUpdateGeometry,
  newAreaRef,
  editGeometryMode,
  setEditGeometryMode,
  onSaveAreaDrawing,
  onCancelUpdateGeometry
}) => {
  const { t } = useTranslation()

  const DrawTitle = () => {
    if (targetFigure) {
      return t(
        targetFigure.geometry_type.toLowerCase() === "polygon" ? "area" :
          targetFigure.geometry_type.toLowerCase() === "polyline" ? "line" : ""
      );
    }

    return drawingLine ? t('draw_line') : t('draw_area');
  };

  return (
    <div className="col-3">
      {(drawingArea || drawingLine || targetFigure) && (
        <div className="row">
          <div className="col-12">
            <div className="card my-3">
              <div className="card-header ribbon ribbon-top ribbon-vertical cursor-pointer">
                <div className="card-title m-0">
                  <h3 className="fw-bold m-0">
                    <DrawTitle />
                  </h3>
                </div>
              </div>

              {!drawingArea && !drawingLine && targetFigure && (
                <div className="card-body border-top p-9">
                  <div className="align-content-center">
                    <div className="d-flex flex-stack">
                      <div className="d-flex flex-column">
                        <div className="fs-6 d-flex gap-2 fw-semibold text-gray-500">
                          {t('name')}
                          <div onClick={() => deleteSelectedGeometry(targetFigure)}>
                            <TrashSquareIcon className="fs-1 text-danger cursor-pointer" />
                          </div>
                        </div>
                      </div>
                      <div className="d-flex justify-content-end">
                        <div className="form-check form-check-solid form-check-custom form-switch">
                          <EditableTextInput
                            id={targetFigure?.id}
                            insideForm={true}
                            attribute="name"
                            initialValue={targetFigure.name || '-'}
                            handleSubmit={onUpdateGeometry}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="separator separator-dashed my-3"></div>
                    <div className="d-flex flex-stack">
                      <div className="fs-6 fw-semibold text-gray-500">{t('place')}</div>
                      <div className="form-check form-check-solid form-check-custom form-switch">
                        {editGeometryMode ? (
                          <>
                            <TableButton
                              className="btn btn-warning btn-sm ms-3"
                              title={t('save')}
                              onClick={onUpdateGeometry}
                            />
                            <TableButton
                              className="btn btn-warning btn-sm ms-3"
                              title={t('cancel')}
                              onClick={onCancelUpdateGeometry}
                            />
                          </>
                        ) : (
                          !drawingArea && !drawingLine && (
                            <TableButton
                              className="btn btn-warning btn-sm ms-3"
                              onClick={() => setEditGeometryMode(!editGeometryMode)}
                              title={t('edit')}
                            />
                          )
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {(drawingArea || drawingLine) && (
                <div className="card-body border-top p-9">
                  <div className="mb-3 w-100">
                    <Input
                      placeholder={t('name')}
                      className="form-control"
                      value={newAreaRef.current.name}
                      onChange={(value) => (newAreaRef.current.name = value)}
                    />
                  </div>
                  <div className="d-flex justify-content-end w-100">
                    <TableButton
                      className="btn btn-warning"
                      title={t('save')}
                      onClick={() => onSaveAreaDrawing()}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}

      {selectedPoint && (
        <div className="card mb-4">
          <div className="card-header ribbon ribbon-top ribbon-vertical cursor-pointer">
            <div className="card-title m-0">
              <h3 className="fw-bold m-0">{t('point_info')}</h3>
            </div>
          </div>
          <div className="card-body border-top p-9">
            <div className="d-flex flex-stack">
              <div className="d-flex flex-column">
                <div className="fs-6 d-flex gap-2 fw-semibold text-gray-500">
                  {t('name')}
                  <div onClick={() => deleteSelectedPoint(selectedPoint.id)}>
                    <TrashSquareIcon className="fs-1 text-danger cursor-pointer" />
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-end">
                <div className="form-check form-check-solid form-check-custom form-switch">
                  <EditableTextInput
                    id={selectedPoint?.id}
                    attribute="name"
                    insideForm={true}
                    initialValue={selectedPoint?.name || '_'}
                    handleSubmit={onSavePoint}
                  />
                </div>
              </div>
            </div>
            <div className="separator separator-dashed my-3"></div>
            <div className="d-flex flex-stack">
              <div className="d-flex flex-column">
                <div className="fs-6 fw-semibold text-gray-500">{t('place')}</div>
              </div>
              <div className="d-flex justify-content-end">
                <div className="form-check form-check-solid form-check-custom form-switch">
                  {!editPointMode && !drawingPoint && selectedPoint && (
                    <TableButton
                      className={classNames('btn btn-warning btn-sm ms-3')}
                      title={t('edit')}
                      onClick={() => setEditPointMode(!editPointMode)}
                    />
                  )}
                  {editPointMode && (
                    <>
                      <TableButton
                        className={classNames('btn btn-warning btn-sm ms-3')}
                        title={t('save')}
                        onClick={onSavePoint}
                      />
                      <TableButton
                        className={classNames('btn btn-warning btn-sm ms-3')}
                        title={t('cancel')}
                        onClick={onCancelUpdatePoint}
                      />
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {drawing && (
        <div className="card shadow-md mb-3">
          <div className="card-header">
            <h3 className="card-title">{t('new_field')}</h3>
          </div>
          <div className="card-body border-top p-9">
            <div className="align-content-center">
              <div className="d-flex flex-column align-items-start">
                <div className="mb-3 w-100">
                  <Input
                    placeholder={t('name')}
                    className="form-control"
                    value={newFieldRef.current.name}
                    onChange={(value) => (newFieldRef.current.name = value)}
                  />
                </div>
                <div className="mb-3 w-100">
                  <Input
                    placeholder={t('comment')}
                    className="form-control"
                    value={newFieldRef.current.comment}
                    onChange={(value) => (newFieldRef.current.comment = value)}
                  />
                </div>
                <div className="d-flex justify-content-end w-100">
                  <TableButton
                    className="btn btn-warning"
                    title={t('save')}
                    onClick={() => onSaveDrawing()}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {selectedField?.id ? (
        <div className="card shadow-md">
          <div className="card-header">
            <h3 className="card-title">{t('field_info')}</h3>
          </div>
          <div className="card-body border-top p-9">
            <div className="row">
              <div className="col-12 align-content-center">
                <div className="d-flex flex-stack">
                  <div className="d-flex flex-column">
                    <div className="fs-6 d-flex gap-2 fw-semibold text-gray-500">
                      {t('name')}
                      <div onClick={() => deleteSelectedField(selectedField?.id)}>
                        <TrashSquareIcon className="fs-1 text-danger cursor-pointer" />
                      </div>
                    </div>
                  </div>
                  <div className="d-flex justify-content-end">
                    <div className="form-check form-check-solid form-check-custom form-switch">
                      <EditableTextInput
                        id={selectedField.id}
                        attribute="name"
                        insideForm={true}
                        initialValue={selectedField?.name}
                        handleSubmit={onUpdateField}
                      />
                    </div>
                  </div>
                </div>
                <div className="separator separator-dashed my-3"></div>
                <div className="d-flex flex-stack">
                  <div className="d-flex flex-column">
                    <div className="fs-6 d-flex gap-2 fw-semibold text-gray-500">
                      {t('comment')}
                    </div>
                  </div>
                  <div className="d-flex justify-content-end">
                    <div className="form-check form-check-solid form-check-custom form-switch">
                      <EditableTextInput
                        id={selectedField?.id}
                        insideForm={true}
                        attribute="comment"
                        initialValue={selectedField.comment || '-'}
                        handleSubmit={onUpdateField}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="separator separator-dashed my-3"></div>
              <div className="col-12 align-content-center">
                <div className="d-flex flex-stack">
                  <div className="d-flex">
                    <div className="d-flex flex-column">
                      <div className="fs-6 fw-semibold text-gray-500">{t('comment_area')}</div>
                    </div>
                  </div>
                  <div className="d-flex justify-content-end">
                    <div className="form-check form-check-solid form-check-custom form-switch">
                      {!editMode && !drawing && selectedField?.id && (
                        <TableButton
                          className={classNames('btn btn-warning btn-sm ms-3')}
                          onClick={() => setEditMode(!editMode)}
                          title={t('edit')}
                        />
                      )}
                      {editMode && (
                        <>
                          <TableButton
                            className="btn btn-warning btn-sm ms-3"
                            title={t('save')}
                            onClick={onUpdateField}
                          />
                          <TableButton
                            className="btn btn-warning btn-sm ms-3"
                            title={t('cancel')}
                            onClick={onCancelUpdateField}
                          />
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="card shadow-md">
          <div className="card-header">
            <div className="card-title m-0">
              <h3 className="fw-bold m-0">{t('fields')}</h3>
            </div>
          </div>
          <div className="card-body p-2">
            {selected.length > 0 ? (
              <ul className="list-group list-group-flush">
                {selected.map((item, index) => (
                  <li key={index} className="list-group-item d-flex justify-content-between">
                    <div>{item.name || `Field ${index + 1}`}</div>
                    <div>{item.hectare.toFixed(2)} hectares</div>
                  </li>
                ))}
                <li className="list-group-item d-flex justify-content-between">
                  <div className="fw-bold">{t('total')}</div>
                  <div>
                    {selected.reduce((sum, item) => sum + (item.hectare || 0), 0).toFixed(2)}{' '}
                    {t('measurement_unit.ha')}
                  </div>
                </li>
              </ul>
            ) : (
              <div className="text-gray-600 fw-bold text-center">{t('please_select')}</div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Sidebar;
