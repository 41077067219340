import React, { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import Toastr from '../../components/Toastr';
import { axiosInstance, routes } from '../../utils/api_base';
import { Form, Field } from 'react-final-form'
import { isRequired } from '../../components/Form/validations';
import TableButton from '../../components/Table/TableButton';
import ErrorField from '../../components/Form/ErrorField';
import AsyncSelectAdapter from '../../components/Form/AsyncSelectAdapter';
import debounce from "lodash/debounce";

const Create = ({ onClose }) => {
  const { t } = useTranslation();
  const [errors, setErrors] = useState({});

  const onSubmit = async values => {
    const data = {
      combination: {
        name: values.name,
        staff_id: values.driver.value,
        fahrzeug_id: values.vehicle.value,
        geraet1_id: values.device1.value,
        geraet2_id: values.device2.value
      }
    };

    axiosInstance.post(routes.combinations(), data)
      .then(response => {
        Toastr({
          title: 'Success!',
          message: response.data.message,
          options: { closeButton: true }
        })

        onClose()
        window.location.href = response.data.redirect_url
      })
      .catch(error => {
        setErrors(error.response.data.error);
      })
  }

  const fetchData = (url, params) => {
    return axiosInstance.get(url, { params })
      .then(response => response.data.data.map(item => ({
        value: item.id,
        label: item.attributes.long_name,
      }))
      )
      .catch(error => {
        console.log('ERROR', error);
        return [];
      });
  };

  const createLoadOptions = (fetchFunction) =>
    useCallback(
      debounce((inputText, callback) => {
        fetchFunction(inputText).then(options => callback(options));
      }, 1000),
      []
    );

  const loadDrivers = createLoadOptions((inputText) => {
    const params = { page: 1, limit: 20, sort: 'code', query: inputText, without_admin_farmer_subcontractor: 'true' };
    return fetchData(routes.staffs(), params);
  });

  const loadVehicles = createLoadOptions((inputText) => {
    const params = { page: 1, limit: 20, sort: 'code', query: inputText, fahrzeug: 'true', serialize: "short" };
    return fetchData(routes.machines(), params);
  });

  const loadDevices1 = createLoadOptions((inputText) => {
    const params = { page: 1, limit: 20, sort: 'name', query: inputText, geraet: 'true', serialize: "short" };
    return fetchData(routes.machines(), params);
  });

  // I had to make the same request in different functions because otherwise one of the requests gets stuck waiting for the other.
  const loadDevices2 = createLoadOptions((inputText) => {
    const params = { page: 1, limit: 20, sort: 'name', query: inputText, geraet: 'true', serialize: "short" };
    return fetchData(routes.machines(), params);
  });

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={{
        name: '',
        driver: '',
        vehicle: '',
        device1: '',
        device2: ''
      }}
      validate={values => {
        let errors = {}
        errors.name = isRequired(values.name)
        errors.vehicle = isRequired(values.vehicle)
        return errors
      }}
      render={({ submitError, handleSubmit, submitting, pristine }) => (
        <form onSubmit={handleSubmit}>
          <>
            <div className="container">
              <div className='p-6'>
                <div className="row mb-4">
                  <div className="col">
                    <label className='required'>{t('name')}</label>
                    <Field
                      name="name"
                      component="input"
                      type="text"
                      className="form-control"
                    />
                    <ErrorField name="name" />
                  </div>
                </div>
                <div className="row mb-4">
                  <div className="col">
                    <label>{t('driver')}</label>
                    <Field
                      name='driver'
                      component={AsyncSelectAdapter}
                      loadOptions={loadDrivers}
                      placeholder={t('select')}
                      isClearable={true}
                    >
                    </Field>
                  </div>
                </div>
                <div className="row mb-4">
                  <div className="col">
                    <label className='required'>{t('vehicle')}</label>
                    <Field
                      name='vehicle'
                      component={AsyncSelectAdapter}
                      loadOptions={loadVehicles}
                      placeholder={t('select')}
                      isClearable={true}
                    >
                    </Field>
                    <ErrorField name="vehicle" />
                  </div>
                </div>
                <div className="row mb-4">
                  <div className="col">
                    <label>{t('device1')}</label>
                    <Field
                      name='device1'
                      component={AsyncSelectAdapter}
                      loadOptions={loadDevices1}
                      placeholder={t('select')}
                      isClearable={true}
                    >
                    </Field>
                  </div>
                </div>
                <div className="row mb-4">
                  <div className="col">
                    <label>{t('device2')}</label>
                    <Field
                      name='device2'
                      component={AsyncSelectAdapter}
                      loadOptions={loadDevices2}
                      placeholder={t('select')}
                      isClearable={true}
                    >
                    </Field>
                  </div>
                </div>
                {submitError && <div className="form-error-message">{submitError}</div>}
                <div className="modal-footer">
                  <TableButton
                    className="btn btn-light btn-active-light-primary fw-semibold me-2 px-6"
                    type="button"
                    onClick={onClose}
                    title={t('cancel')}
                  />
                  <TableButton
                    className="btn btn-primary fw-semibold px-6"
                    type="submit"
                    disabled={submitting || pristine}
                    title={t('create')}
                  />
                </div>
              </div>
            </div>
          </>
        </form>
      )}
    />
  )
}

export default Create;
