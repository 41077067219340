import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import classNames from "classnames";
import { useTranslation } from 'react-i18next';
import * as style from './index.module.css';

import PdfImage from '../../images/pdf_image.png';

function Dropzone(props) {
  const { t } = useTranslation();
  const [files, setFiles] = useState([]);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    // accept: "image/*",
    multiple: false,
    noDrag: false,
    onDrop: acceptedFiles => {
      const files = acceptedFiles.map(file =>
        Object.assign(file, {
          preview: URL.createObjectURL(file)
        })
      );
      setFiles(files);
      if (props.onChange) {
        props.onChange(files);
      }
    }
  });

  const removeFile = file => () => {
    const newFiles = [...files];
    newFiles.splice(newFiles.indexOf(file), 1);
    setFiles(newFiles);
  };

  const niceBytes = (x) => {
    const units = ['Bytes', 'Kb', 'Mb', 'Gb', 'Tb', 'Pb', 'Eb', 'Zb', 'Yb'];
    let l = 0, n = parseInt(x, 10) || 0;

    while(n >= 1024 && ++l){
      n = n/1024;
    }

    return(
      <><strong>{n.toFixed(n < 10 && l > 0 ? 1 : 0)}</strong> {units[l]}</>
    )
  }

  const blobPreview = (file) => {
    let imageSource
    if (file.type.includes('image')) {
      imageSource = file.preview
    } else if (file.type.includes('application/pdf')) {
      imageSource = PdfImage
    } else {
      imageSource = file.preview
    }

    return <img className={style.img} src={imageSource} alt="" />
  }


  const thumbs = files.map((file, index )=> (
    <div key={file.preview || index}>
      <div className="dz-image">{blobPreview(file)}</div>
      <div className="dz-details">
        <div className="dz-size"><span data-dz-size="">{niceBytes(file.size)}</span></div>
        <div className="dz-filename"><span data-dz-name="">{file.name}</span></div>
      </div>
      <a className="dz-remove" onClick={removeFile(file)}>{t('remove_file')}</a>
    </div>
  ));

  useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      files.forEach(file => URL.revokeObjectURL(file.preview));
    },
    [files]
  );

  return (
    <div className="fv-row mb-10">
      <div {...getRootProps({ className: classNames("dropzone dz-clickable", {'dz-drag-hover': isDragActive, 'dz-started': files.length > 0}) })}>
        <input {...getInputProps()} />
        <div className={classNames("dz-message needsclick", { 'd-none': files.length > 0 })}>
          <i className="ki-duotone ki-file-up fs-3hx text-primary">
            <span className="path1"/>
            <span className="path2"/>
          </i>
          <div className="ms-4">
            <h3 className="dfs-3 fw-bold text-gray-900 mb-1">{t('drop_file_here_text')}</h3>
          </div>
        </div>
        {
          files.length > 0 && (
            <div className="dz-preview dz-file-preview dz-preview-single">
              {thumbs}
            </div>
          )
        }
      </div>
    </div>
  );
}

export default Dropzone;

