import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next';
import { BlackArrowRightIcon, EyeIcon, CheckIcon, CalendarIcon } from '../../../components/Icons';

import {
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'

import {
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table'

const queryClient = new QueryClient()

const Table = ({ data, tableAlign = "middle" }) => {
  const { t, i18n } = useTranslation();

  const formatFooter = (footers, header) => {
    if (footers.hasOwnProperty(header)) {
      return footers[header];
    }

    switch (header) {
      case "working_hours_link":
        return footers.working_hours_link_footer
      case 'date':
        return footers.date_footer
      case 'deduction':
        return footers.deduction_footer
      case 'working_hours_without_break':
        return footers.working_hours_without_break_footer
      case 'pause':
        return footers.pause_footer
      case 'overtime_rule':
        return footers.overtime_rule_footer
      default:
        return null;
    }
  };

  const formatRow = (attributes, header) => {
    for (const key of Object.keys(attributes.wage_benefits_without_overtime)) {
      if (header === key) {
        return genericColumn(attributes.wage_benefits_without_overtime[key], attributes.is_reversed_booking);
      }
    }

    for (const key of Object.keys(attributes.present_paid_absence_reasons)) {
      if (header === key) {
        return genericColumn(attributes.present_paid_absence_reasons[key], attributes.is_reversed_booking);
      }
    }

    for (const key of Object.keys(attributes.present_unpaid_absence_reasons)) {
      if (header === key) {
        return genericColumn(attributes.present_unpaid_absence_reasons[key], attributes.is_reversed_booking);
      }
    }

    switch (header) {
      case "working_hours_link":
        return workingHoursLinkColumn(attributes)
      case 'date':
        return dateColumn(attributes)
      case 'deduction':
        return deductionColumn(attributes)
      case 'working_hours_without_break':
        return genericColumn(attributes.working_time_per_day, attributes.is_reversed_booking)
      case 'pause':
        return genericColumn(attributes.break_time_per_day, attributes.is_reversed_booking)
      case 'overtime_rule':
        return genericColumn(attributes.wage_benefit_per_day, attributes.is_reversed_booking)
      default:
        return genericColumn(null, attributes.is_reversed_booking);
    }
  };

  const columns = useMemo(
    () => {
      if (!data?.headers) return [];

      return Object.keys(data?.headers).map(header => {
        let size = 20;
        let minSize = 20;

        if (header === 'date') {
          size = 120;
          minSize = 120;
        } else if (header === 'deduction') {
          size = 70;
          minSize = 70;
        }

        if (data.footers) {
          return {
            id: header,
            size: size,
            minSize: minSize,
            header: data?.headers[header],
            cell: ({ row }) => formatRow(row.original.attributes, header),
            footer: formatFooter(data.footers, header)
          };
        } else {
          return {
            id: header,
            size: size,
            minSize: minSize,
            header: data?.headers[header],
            cell: ({ row }) => formatRow(row.original.attributes, header),
          };
        }
      });
    },
    [i18n.language, data]
  );

  const table = useReactTable({
    data: data.rows.data,
    columns: columns,
    getCoreRowModel: getCoreRowModel(),
  })

  const workingHoursLinkColumn = (attributes) => {
    if (attributes.is_normal_booking && attributes.is_driver) {
      return (
        <a href={attributes.driver_link}> <BlackArrowRightIcon className={"text-success fs-1"} /></a>
      );
    } else {
      if (attributes.is_inprocess_booking) {
        return (
          <a href={attributes.staff_multi_bookings_link}> <EyeIcon className={"text-danger fs-1"} /></a>
        );
      } else if (attributes.is_pending_booking) {
        return (
          <a href={attributes.staff_multi_bookings_link}> <BlackArrowRightIcon className={"text-danger fs-1"} /></a>
        );
      } else if (attributes.is_booked_booking) {
        return (
          <a href={attributes.staff_multi_bookings_link}> <CheckIcon className={"text-success fs-1"} /></a>
        );
      } else {
        return (
          <a href={attributes.staff_multi_bookings_link}> <CalendarIcon className={"text-dark fs-1"} /></a>
        );
      }
    }
  };

  const dateColumn = (attributes) => {
    if (attributes.is_reversed_booking) {
      return (
        <div>
          <div
            className="text-start"
            style={{
              fontFamily: 'monospace',
              whiteSpace: 'normal',
              color: 'black',
              textAlign: 'start',
              borderBottom: 'none'
            }}
          >
            <span className="fs-6" style={{ display: 'block' }}>
              {attributes.id}
            </span>
          </div>
          <div className="separator my-3"></div>
          <small className="fs-7" style={{ display: 'block' }}>
            {t("payroll_table_date_small_comment", { date: attributes.foreign_payroll_name })}
          </small>
        </div>
      )
    } else {
      return (
        <div
          className="text-start"
          style={{
            fontFamily: 'monospace',
            whiteSpace: 'normal',
            color: 'black',
            textAlign: 'start',
            borderBottom: 'none'
          }}
        >
          <span className="fs-6" style={{ display: 'block' }}>
            {attributes.id}
          </span>
        </div>
      )
    }
  };

  const deductionColumn = (attributes) => {
    if (attributes.is_reversed_booking) {
      return (
        <div>
          <div
            className="text-start"
            style={{
              fontFamily: 'monospace',
              whiteSpace: 'normal',
              color: 'black',
              textAlign: 'start',
              borderBottom: 'none'
            }}
          >
            <span className="fs-6" style={{ display: 'block' }}>
            </span>
          </div>
          <div className="separator my-3"></div>
          <small className="fs-7" style={{ display: 'block' }}>
            {attributes.working_time_start_and_end.split(' ').join('\n')}
          </small>
        </div>
      )
    } else {
      return (
        <div
          className="text-start"
          style={{
            fontFamily: 'monospace',
            whiteSpace: 'normal',
            color: 'black',
            textAlign: 'start',
            borderBottom: 'none'
          }}
        >
          <span className="fs-6" style={{ display: 'block' }}>
            {attributes.working_time_start_and_end.split(' ').join('\n')}
          </span>
        </div>
      )
    }
  };

  const genericColumn = (attribute, is_reversed_booking) => {
    if (is_reversed_booking) {
      return (
        <div>
          <div
            className="text-start"
            style={{
              fontFamily: 'monospace',
              whiteSpace: 'normal',
              color: 'black',
              textAlign: 'start',
              borderBottom: 'none',
            }}
          >
            <span className="fs-6" style={{ display: 'block' }}>
            </span>
          </div>
          <div className="separator my-3"></div>
          <small className="fs-7" style={{ display: 'block' }}>
            {attribute || "-"}
          </small>
        </div>
      )
    } else {
      return (
        <div
          className="text-start"
          style={{
            fontFamily: 'monospace',
            whiteSpace: 'normal',
            color: 'black',
            textAlign: 'start',
            borderBottom: 'none'
          }}
        >
          <span className="fs-6" style={{ display: 'block' }}>
            {attribute}
          </span>
        </div>
      )
    }
  };


  const hasRows = table.getRowModel().rows.length > 0;

  const getBackgroundColor = (row) => {
    if (row.original?.attributes.is_inprocess_booking) {
      return { backgroundColor: '#ffb0ff' };
    }
    if (row.original?.attributes.is_weekend) {
      return { backgroundColor: '#f2dede' };
    }
    if (row.original?.attributes.is_bank_holiday) {
      return { backgroundColor: '#d9edf7' };
    }
    return {};
  };

  return (
    <div className="card card-flush">
      <div className="card-body pt-0">
        <div id="kt_ecommerce_sales_table_wrapper" className="dt-container dt-bootstrap5 dt-empty-footer">
          <div className="row mt-2 justify-content-md-center">
            <div className="col-12 table-responsive">
              <table className={`table align-${tableAlign} table-row-dashed fs-1 gy-1 dataTable`}>
                <thead>
                  {table.getHeaderGroups().map(headerGroup => (
                    <tr className="text-start text-gray-500 fw-bold fs-7 text-uppercase gs-0" key={headerGroup.id}>
                      {headerGroup.headers.map(header => (
                        <th key={header.id} colSpan={header.colSpan}>
                          {header.isPlaceholder ? null : (
                            <div>
                              {flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                              )}
                            </div>
                          )}
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody className="fw-semibold text-gray-600">
                  {hasRows ? (
                    table.getRowModel().rows.map(row => (
                      <tr
                        key={row.id}
                        style={getBackgroundColor(row)}
                      >
                        {row.getVisibleCells().map(cell => {
                          return (
                            <td
                              key={cell.id}
                              style={{ width: cell.column.getSize() }}
                            >
                              {flexRender(cell.column.columnDef.cell, cell.getContext())}
                            </td>
                          )
                        })}
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={table.getAllColumns().length} className="text-center">
                        {t('shared.no_data')}
                      </td>
                    </tr>
                  )}
                </tbody>
                <tfoot className="fw-bold text-black fs-6">
                  {table.getFooterGroups().map(footerGroup => (
                    <tr key={footerGroup.id}>
                      {footerGroup.headers.map(footer => (
                        <th key={footer.id}>
                          {flexRender(footer.column.columnDef.footer, footer.getContext())}
                        </th>
                      ))}
                    </tr>
                  ))}
                </tfoot>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const BaseTable = (props) => {

  return (
    <QueryClientProvider client={queryClient}>
      <Table {...props} />
    </QueryClientProvider>
  )
}

export default BaseTable
