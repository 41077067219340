import React, { useMemo } from 'react';
import BaseTable from '../../Table';
import { axiosInstance, routes } from '../../../utils/api_base';
import { useTranslation } from 'react-i18next';

const SummaryTable = ({ tableToolbar, staff, day, trigger }) => {
  const { t, i18n } = useTranslation();

  const getBookingsSummary = async (pagination, sorting, globalFilter) => {
    const { pageIndex } = pagination
    const sortQuery = sorting.map((attr) => {
      return attr.desc ? `${attr.id} desc` : attr.id
    })

    let params = {
      page: pageIndex + 1,
      limit: 20,
      sort: sortQuery[0],
      staff_id: staff.id,
      staff_time_bookings: "true",
      day: day
    }

    if (globalFilter) {
      params.query = globalFilter
    }

    const res = await axiosInstance.get(routes.bookingsSummary(), { params: params })

    return (
      { rows: res.data.data, pageCount: 1, rowCount: res.data.length }
    )
  }

  const columns = useMemo(
    () => [
      {
        id: 'order_name',
        header: t('order'),
        accessorFn: row => row?.name,
        size: 300,
        minSize: 300,
        cell: row => row?.getValue()
      },
      {
        id: 'cost_work_time',
        header: t("booking_codes.cost_work_time"),
        accessorFn: row => row?.cost_work_time,
        enableSorting: false,
        cell: row => row?.getValue()
      },
      {
        id: 'cost_setting_up_time',
        header: t("booking_codes.cost_setting_up_time"),
        accessorFn: row => row?.cost_setting_up_time,
        enableSorting: false,
        cell: row => row?.getValue()
      },
      {
        id: 'cost_travel_time',
        header: t("booking_codes.cost_travel_time"),
        accessorFn: row => row?.cost_travel_time,
        enableSorting: false,
        cell: row => row?.getValue()
      },
      {
        id: 'cost_repair_time',
        header: t("booking_codes.cost_repair_time"),
        accessorFn: row => row?.cost_repair_time,
        enableSorting: false,
        cell: row => row?.getValue()
      },
      {
        id: 'cost_wait_time',
        header: t("booking_codes.cost_wait_time"),
        accessorFn: row => row?.cost_wait_time,
        enableSorting: false,
        cell: row => row?.getValue()
      },
      {
        id: 'cost_wash_time',
        header: t("booking_codes.cost_wash_time"),
        accessorFn: row => row?.cost_wash_time,
        enableSorting: false,
        cell: row => row?.getValue()
      },
      {
        id: 'cost_other_time',
        header: t("booking_codes.cost_other_time"),
        accessorFn: row => row?.cost_other_time,
        enableSorting: false,
        cell: row => row?.getValue()
      },
      {
        id: 'total',
        header: t("total"),
        accessorFn: row => row?.total,
        enableSorting: false,
        cell: row => row?.getValue()
      },
    ],
    [i18n.language]
  )

  return (
    <>
      <div className="d-flex flex-stack fs-4 py-3">
        <div className="fw-bold rotate collapsible" data-bs-toggle="collapse" href="#staff-summary" role="button">
          {t('summary').toUpperCase()}
          <span className="ms-2 rotate-180">
            <i className="ki-duotone ki-down fs-3"></i>
          </span>
        </div>
      </div>
      <div className="separator my-3"></div>
      <div id="staff-summary" className="collapse show">
        <BaseTable
          keyName="summary"
          getDataFn={getBookingsSummary}
          columns={columns}
          tableToolbar={tableToolbar}
          showHeader={false}
          trigger={day + trigger}
        />
      </div >
    </>
  )
}

export default SummaryTable;

