import React, { useMemo, useState, useEffect } from 'react';
import BaseTable from './Table';
import { axiosInstance, routes } from '../../utils/api_base';
import { useTranslation } from 'react-i18next';
import Timer from '../../components/Timer';

const StaffBookingsTable = ({ client_id }) => {
  const { t, i18n } = useTranslation();
  const [data, setData] = useState([]);

  const getStaffBookings = async () => {
    const res = await axiosInstance.get(routes.bookings(), { params: { active_bookings: true } })

    return (res.data.data)
  }

  useEffect(() => {
    const fetchData = async () => {
      const staffData = await getStaffBookings();
      setData(staffData);
    };

    fetchData();
  }, []);

  const staffBooking = (attributes) => {
    return (
      <div className='p-3 rounded' key={attributes.id} style={{ backgroundColor: attributes.inprocess_bookings_data.code_color }}>
        <a key={attributes?.id} href={attributes?.staff_multi_bookings_link}>{attributes?.staff_name}</a>
      </div>
    )
  };

  const columns = useMemo(
    () => [
      {
        id: 'timer',
        header: t('time_since'),
        enableSorting: false,
        meta: {
          headerProps: {
            style: {
              width: '100px',
            },
          },
        },
        cell: ({ row }) => {
          return <Timer startTime={row?.original?.attributes?.start_time_in_seconds} />
        },
      },
      {
        id: 'staff',
        header: t('staff'),
        accessorFn: row => staffBooking(row?.attributes),
        enableSorting: false,
        cell: row => row.getValue(),
        meta: {
          headerProps: {
            style: {
              width: '300px'
            },
          },
        },
      },
      {
        id: 'comment',
        header: t('comment'),
        accessorFn: row => row?.attributes?.comment,
        enableSorting: false,
        cell: row => row.getValue()
      },
    ],
    [i18n.language]
  )

  return (
    <>
      <div className="d-flex flex-stack fs-4 py-3">
        <div className="fw-bold rotate collapsible" data-bs-toggle="collapse" href="#staff-bookings" role="button">
          {t('staff_bookings')}
          <span className="ms-2 rotate-180">
            <i className="ki-duotone ki-down fs-3" />
          </span>
        </div>
      </div>
      <div className="separator my-3"></div>
      <div id="staff-bookings" className="collapse show">
        <BaseTable
          columns={columns}
          data={data}
          subscription={{
            channel: "StaffBookingsChannel",
            client_id: client_id
          }}
        />
      </div >
    </>
  )
};

export default StaffBookingsTable;
