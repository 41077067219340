import Create from './Create';
import Modal from '../../../components/Modal';
import React, { useState } from 'react';
import Table from '../../BusinessPartners/PlantWasteAnalyses/Table';
import TableButton from '../../Table/TableButton';
import { PlusIcon } from '../../Icons';
import { useTranslation } from 'react-i18next';

const PlantWasteAnalyse = ({ businessPartnerId }) => {
  const { t } = useTranslation()
  const [modalOpen, setModalOpen] = useState(false)

  const tableToolbar = () => {
    return (
      <TableButton
        className="btn-warning"
        onClick={() => setModalOpen(true)}
        icon={<PlusIcon />}
        title={t('shared.add_to')}
      />
    )
  }

  return (
    <>
      <Modal className="modal fade show" open={modalOpen} onClose={() => setModalOpen(false)} title={t('enter_analysis')}>
        <Create businessPartnerId={businessPartnerId} onClose={() => setModalOpen(false)} />
      </Modal>
      <Table tableToolbar={tableToolbar} businessPartnerId={businessPartnerId} />
    </>
  )
}

export default PlantWasteAnalyse
