import React from 'react';
import { useTranslation } from 'react-i18next';

const RepairTimesPerStaff = ({ machineData }) => {
  const { t } = useTranslation()
  const repairsData = machineData.attributes.repair_times_per_staff

  return (
    <>
      {
        repairsData &&
          <div className="card card-flush">
            <div className="card-body">
              <table className="table align-middle table-row-dashed fs-6 gy-5 dataTable">
                <thead>
                  <tr>
                    <th>{t("time_expressions.day")}</th>
                    <th>{t("shared.time_length")}</th>
                    <th>{t("shared.spare_parts")}</th>
                    <th>{t("shared.spare_parts_costs")}</th>
                  </tr>
                </thead>
                <tbody className="fw-semibold text-gray-600">
                  {
                    repairsData.map((repair, index) => {
                      return(
                        <tr key={index}>
                          <td>
                            {
                              repair.date.map((element) => {
                                return(
                                  <><a href={element.link}>{element.title}</a><br/></>
                                )
                              })
                            }
                          </td>
                          <td>
                            {repair.duration}
                          </td>
                          <td>
                            {
                              repair.spare_parts.map((sparePart) => {
                                return(
                                  <>{sparePart?.title}<br/></>
                                )
                              })
                            }
                          </td>
                          <td>
                            {
                              repair.costs.map((cost) => {
                                return(
                                  <>{cost?.title}<br/></>
                                )
                              })
                            }
                          </td>
                        </tr>
                      )
                    })
                  }
                </tbody>
              </table>
            </div>
          </div>
      }
    </>
  )
}

export default RepairTimesPerStaff;
