import { useDrop } from 'react-dnd';
import React, {useEffect, useRef, useState, useCallback, memo } from "react";
import itemRenderer from "./Timeline/OrderItem";
import groupRenderer from "./Timeline/GroupRenderer";
import { useTranslation } from 'react-i18next';
import moment from 'moment'

import Timeline, {
    TimelineHeaders,
    DateHeader,
    TodayMarker,
    CustomMarker,
    TimelineMarkers,
} from "react-calendar-timeline/lib";
import {axiosInstance, routes} from "../../utils/api_base";
import Toastr from "../Toastr";

const keys = {
    groupIdKey: "id",
    groupTitleKey: "title",
    groupRightTitleKey: "rightTitle",
    itemIdKey: "id",
    itemTitleKey: "title",
    itemDivTitleKey: "title",
    itemGroupKey: "group",
    itemTimeStartKey: "start",
    itemTimeEndKey: "end",
    groupLabelKey: "title"
};

const CustomTimeline = memo(({ onAdd, onDrop, groups, items, onTimeChange, startTime, endTime, onClickCallback, viewType, onCallback }) => {
    const { t, i18n } = useTranslation()

    const timelineRef = useRef(null);

    moment.locale(i18n.language)

    const headerHeight = 62;

    const [{ isOver }, drop] = useDrop({
        accept: 'BOX',
        drop: (item, monitor) => {
            const delta = monitor.getClientOffset();
            const boundingRect = timelineRef.current.container.getBoundingClientRect();
            const offsetX = delta.x - boundingRect.left;
            const offsetY = delta.y - boundingRect.top - headerHeight
            const groupIndex = Math.floor(offsetY / boundingRect.height * groups.length);
            const group = groups[groupIndex];
            const time = timelineRef.current.canvasTimeStart + (offsetX / boundingRect.width) * (timelineRef.current.canvasTimeEnd - timelineRef.current.canvasTimeStart);
            onDrop(item, group, time);
        },
        collect: (monitor) => ({
            isOver: !!monitor.isOver(),
        }),
    });

    const isResourceView = viewType === 'resource'

    const updateStaffOrder = async (item) => {
        const params = {
            start_time: new Date(item.start),
            end_time: new Date(item.end),
            staff_id: item.group
        }

        return axiosInstance.put(routes.staffOrder(item.id), params).then(() => {
            Toastr({ message: t('success'), options: { showDuration: 2000 } })

            return item
        }).catch(() => console.error("Error on Update"))
    }

    const updateOrder = (item) => {
        const params = {
            start_time: item.start,
            end_time: item.end
        }

        return axiosInstance.put(routes.order(item.id), params).then(() => {
            Toastr({ message: t('success'), options: { showDuration: 2000 } })

            return item
        }).catch(() => console.error("Error on Update"))
    }


    const onItemResize = (itemId, timestamp, groupIndex) => {
        const item = items.find(item => item.id === itemId)

        item.end = timestamp
        if(isResourceView) {
            updateStaffOrder(item).then(onCallback)
        } else {
            updateOrder(item).then(onCallback)
        }
    }

    const onItemMove = (itemId, timestamp, groupIndex) => {
        const item = items.find(item => item.id === itemId)

        const diff = item.end - item.start

        item.start = timestamp
        item.end = timestamp + diff
        item.group = groups[groupIndex].id

        if(isResourceView) {
            updateStaffOrder(item).then(onCallback)
        } else {
            updateOrder(item).then(onCallback)
        }
    }

    const onItemClick = (itemId) => {
        const item = items.find(item => item.id === itemId)
        let orderId = itemId

        if(isResourceView) {
            orderId = item.orderId
        }

        onClickCallback(itemId, orderId)
    }

    useEffect(() => {
        if(!timelineRef.current) return

        drop(timelineRef.current.container);
    }, [drop, timelineRef.current]);

    return (
        <Timeline
            ref={timelineRef}
            groups={groups}
            items={items}
            keys={keys}
            itemsSorted
            itemTouchSendsClick={false}
            stackItems
            itemHeightRatio={0.75}
            showCursorLine
            buffer={1}
            canResize={true}
            canScroll={true}
            visibleTimeStart={startTime instanceof Date ? startTime.getTime() : startTime}
            visibleTimeEnd={endTime instanceof Date ? endTime.getTime() : endTime}
            onTimeChange={onTimeChange}
            onCanvasClick={onAdd} // Add Order
            onItemClick={onItemClick}
            onItemResize={onItemResize}
            onItemMove={onItemMove}
            itemRenderer={itemRenderer}
            groupRenderer={groupRenderer}
        >
            <TimelineHeaders className="sticky">
                <DateHeader unit="primaryHeader"/>
                <DateHeader/>
            </TimelineHeaders>

            <TimelineMarkers>
                <TodayMarker/>
                <CustomMarker date={(new Date()).valueOf()}>
                    {/* custom renderer for this marker */}
                    {({styles, date}) => {
                        const customStyles = {
                            ...styles,
                            backgroundColor: 'deeppink',
                            width: '4px'
                        }
                        return <div style={customStyles}/>
                    }}
                </CustomMarker>
            </TimelineMarkers>
        </Timeline>
    );
}, (prevProps, nextProps) => { prevProps.startTime === nextProps.startTime && prevProps.endTime === nextProps.endTime })

export default CustomTimeline
