import React, {useState} from 'react';
import { useTranslation } from 'react-i18next';
import Toastr from '../Toastr';
import { axiosInstance, routes } from '../../utils/api_base';
import { Form, Field } from 'react-final-form'
import TableButton from '../Table/TableButton';
import Dropzone from '../Form/Dropzone'
import classNames from 'classnames'

const UploadField = ({ onClose, setTrigger, businessPartnerId }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false)
  const [showErrorText, setShowErrorText] = useState(false)
  const [fileType, setFileType] = useState('zip_kml')

  const uploadUrls = {
    zip_kml: routes.importShapeFile(),
    flik_xml: routes.fieldsUploadXml(),
    xml: routes.fieldsUploadXml(),
  };

  const fileTypeDescriptions = {
    zip_kml: t('fields_page.fields_upload_paths'),
    flik_xml: t('fields_page.select_xml_file_from'),
    xml: t('fields_page.xml_from_naming')
  };

  const onSubmit = async values => {
    if (values.tmp_file && values.tmp_file[0]) {
      setLoading(true)
      const formData = new FormData();
      formData.append('business_partner', businessPartnerId);
      formData.append('type', fileType);

      if (fileType === 'zip_kml') {
        formData.append('field[tmp_file]', values.tmp_file[0]);
        zipKmlRequest(formData)
      } else {
        formData.append('file', values.tmp_file[0]);
        xmlRequest(formData)
      }

    }
  }

  const zipKmlRequest = (formData) => {
    axiosInstance.post(uploadUrls[fileType], formData)
      .then(response => {
        const toastrType = response.data.upload_success ? 'success' : 'error'
        const heaterText = response.data.upload_success ? 'success' : 'critical_error'
        Toastr({
          title: t(`${heaterText}`),
          type: toastrType,
          options: { closeButton: true }
        })
        setLoading(false)
        setShowErrorText(!response.data.upload_success)
        setTrigger(Math.floor(Date.now() / 1000))
        onClose()
      })
      .catch(error => {
        setLoading(false)
        setShowErrorText(true)
      })
  }

  const xmlRequest = (formData) => {
    setShowErrorText(false)
    axiosInstance.post(uploadUrls[fileType], formData)
      .then(response => {
        setTrigger(Math.floor(Date.now() / 1000))
        setLoading(false)
        onClose()
      })
      .catch(error => {
        setLoading(false)
        Toastr({
          title: error.response.data.error,
          type: 'error',
          options: { closeButton: true }
        })
      })
  }

  const errorAlert = () => {
    return (
      <div className="alert alert-danger d-flex align-items-center m-7 mt-1 p-5">
        <div className="d-flex flex-column small">
          <span>
            {t('fields_page.file_upload_fail_alert')}
          </span>
          <ul>
            <li>{t('fields_page.file_upload_fail_alert_li1')}</li>
            <li>{t('fields_page.file_upload_fail_alert_li2')}</li>
            <li>{t('fields_page.file_upload_fail_alert_li3')}</li>
            <li>{t('fields_page.file_upload_fail_alert_li4')}</li>
          </ul>
          <span>{t('fields_page.general_information')}</span>
          <ul>
            <li>{t('fields_page.general_information_li1')}</li>
            <li>{t('fields_page.general_information_li2')}</li>
          </ul>
        </div>
      </div>
    );
  }

  return (
    <>
      <Form
        onSubmit={onSubmit}
        render={({ submitError, handleSubmit, submitting, pristine }) => (
          <>
            <div className="btn-group px-7 mb-3">
              {[{ label: t('fields_page.kml'), value: 'zip_kml' },
                { label: t('fields_page.flik_conversion_xml'), value: 'flik_xml' },
                { label: t('fields_page.naming_xml'), value: 'xml' }
                ].map(item => <label
                  className={classNames("btn btn-outline btn-active-primary btn-sm", item.value === fileType && "active")}>
                  <button className="btn-check" onClick={() => setFileType(item.value)} />
                  {item.label}
                </label>
              )}
            </div>
            <form onSubmit={handleSubmit}>
              <div className="row px-7 mb-3">
                <div className="col">
                  <p className="small text-gray-600 my-3">
                    {fileTypeDescriptions[fileType] || ""}
                  </p>
                  <Field name="tmp_file">
                    {props => (
                      <div>
                        <Dropzone {...props.input} />
                      </div>
                    )}
                  </Field>
                </div>
              </div>
              {submitError && <div className="form-error-message">{submitError}</div>}
              <div className="modal-footer">
                <TableButton
                  className="btn btn-light btn-active-light-primary fw-semibold me-2 px-6"
                  type="button"
                  onClick={onClose}
                  title={t('cancel')}
                />
                <TableButton
                  className="btn btn-primary fw-semibold d-flex px-6"
                  type="submit"
                  title={t('upload')}
                  icon={
                    <>
                      {loading &&
                        <div className=" pe-2 text-center d-flex align-items-center justify-content-center">
                          <div className="spinner-border" role="status"></div>
                        </div>}
                    </>
                  }
                  disabled={pristine || submitting || loading}
                />
              </div>
            </form>
          </>
        )}
      />
      {showErrorText && errorAlert()}
    </>
  )
}

export default UploadField;
