export const statusLTE = (statuses, statusId, statusCode) => {
  if(!statusId || !statusCode) return false;

  const sourceStatus = statuses.find(status => status.id === statusId);
  const targetStatus = statuses.find(status => status.code === statusCode);
  return sourceStatus.key <= targetStatus.key;
};

export const statusLT = (statuses, statusId, statusCode) => {
  if(!statusId || !statusCode) return false;

  const sourceStatus = statuses.find(status => status.id === statusId);
  const targetStatus = statuses.find(status => status.code === statusCode);
  return sourceStatus.key < targetStatus.key;
};

export const statusGTE = (statuses, statusId, statusCode) => {
  if(!statusId || !statusCode) return false;

  const sourceStatus = statuses.find(status => status.id === statusId);
  const targetStatus = statuses.find(status => status.code === statusCode);
  return sourceStatus.key >= targetStatus.key;
};
