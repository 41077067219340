import React, { useEffect, useMemo, useState, useRef } from 'react';

import Select, {
  components,
} from 'react-select';
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from 'react-sortable-hoc';
import { axiosInstance, routes } from "../../../utils/api_base";
import Map from "../../BusinessPartners/map";
import classNames from 'classnames'
import TableButton from '../../Table/TableButton';
import { useTranslation } from 'react-i18next';
import Dropdown from 'react-bootstrap/Dropdown'

import { PlusIcon, TrashSquareIcon } from '../../../components/Icons';
import Toastr from '../../Toastr';
import MapSidebar from '../map_sidebar'

function arrayMove(array, from, to) {
  const slicedArray = array.slice();
  slicedArray.splice(
    to < 0 ? array.length + to : to,
    0,
    slicedArray.splice(from, 1)[0]
  );
  return slicedArray;
}

const SortableMultiValue = SortableElement(
  (props) => {
    // this prevents the menu from being opened/closed when the user clicks
    // on a value to begin dragging it. ideally, detecting a click (instead of
    // a drag) would still focus the control and toggle the menu, but that
    // requires some magic with refs that are out of scope for this example
    const onMouseDown = (e) => {
      e.preventDefault();
      e.stopPropagation();
    };
    const innerProps = { ...props.innerProps, onMouseDown };
    return <components.MultiValue {...props} innerProps={innerProps} />;
  }
);

const SortableMultiValueLabel = SortableHandle(
  (props) => <components.MultiValueLabel {...props} />
);

const SortableSelect = SortableContainer(Select);

export default function FieldMultiSelect({ businessPartnerRef, fieldsRef, geometriesRef }) {
  const [fields, setFields] = useState([])
  const [points, setPoints] = useState([])
  const [selected, setSelected] = useState([])
  const pointRef = useRef({});
  const [selectedIds, setSelectedIds] = useState([])
  const [drawing, setDrawing] = useState(false)
  const fieldRef = useRef({ name: '', poly_points: [], business_partner_id: businessPartnerRef.current })
  const [selectedField, setSelectedField] = useState()
  const { t, i18n } = useTranslation()
  const [geometryAreas, setGeometryAreas] = useState([])
  const [selectedFigureId, setSelectedFigureId] = useState()
  const [targetFigure, setTargetFigure] = useState()
  const [showMeasureDistance, setShowMeasureDistance] = useState(false)
  const [showMeasureArea, setShowMeasureArea] = useState(false)
  const [drawingPoint, setDrawingPoint] = useState(false)
  const [trigger, setTrigger] = useState()
  const [editGeometryMode, setEditGeometryMode] = useState(false)
  const [drawingArea, setDrawingArea] = useState(false)
  const [drawingLine, setDrawingLine] = useState(false)
  const [selectedPoint, setSelectedPoint] = useState(false)
  const [editMode, setEditMode] = useState(false)
  const [geometryPoints, setGeometryPoints] = useState([])
  const newFieldRef = useRef({ name: '', comment: '', poly_points: [], business_partner_id: businessPartnerRef.current });
  const [triggerPoints, setTriggerPoints] = useState(Math.floor(Date.now() / 1000))
  const [editPointMode, setEditPointMode] = useState(false)
  const newAreaRef = useRef({ name: '', geometry_type: 'Polygon', geometry_points: [] });

  const onPointClick = (point) => {
    if (!point) return
    pointRef.current = point
    setSelectedPoint(point)
    setEditPointMode(false)
    setEditMode(false)
    setSelectedFigureId(false)
    setEditGeometryMode(false)
  }

  const onGeometryClick = (figure) => {
    if (!figure) return
    setSelectedFigureId(figure.id)
    setTargetFigure(figure)
    setEditPointMode(false)
    setDrawingArea(false)
    setDrawingLine(false)
  }

  const enableDrawingArea = () => {
    setDrawingArea(!drawingArea)
    setDrawingLine(false)
  }

  const enableDrawingLine = () => {
    setDrawingArea(false)
    setDrawingLine(!drawingLine)
  }
  const onUpdateField = (id, attrs) => {
    let params = {
      name: selectedField?.name,
      business_partner_id: selectedField.business_partner_id,
      comment: selectedField?.comment,
      poly_points: selectedField.poly_points,
    }

    if (attrs) { params = { ...params, ...attrs } }
    axiosInstance.put(routes.field(selectedField.id), params).then(response => {
      setTrigger(Math.floor(Date.now() / 1000))
      setEditMode(false)
      setSelectedField()
      selectedField = response.data.data.attributes
    }).catch(error => {
      console.error("Error saving field:", error);
    });
  };

  const onFieldClick = (field) => {
    setTargetFigure(false)
    setSelected((prevState) => {
      const exists = prevState.find((item) => item.value === field.id);
      if (exists) {
        return prevState.filter((item) => item.value !== field.id);
      } else {
        return [
          ...prevState,
          {
            value: field.id,
            label: field.name_with_hectare,
            hectare: field.hectare,
            name: field.name
          }
        ];
      }
    });

    setSelectedIds((prevState) => {
      if (prevState.includes(field.id)) {
        return prevState.filter((id) => id !== field.id);
      } else {
        return [...prevState, field.id];
      }
    });

    setSelectedField()
    setSelectedPoint(false)
    setDrawing(false)
    setEditMode(false)
    setEditPointMode(false)
    setSelectedField(false)
    setEditGeometryMode(false)
  }

  const onCancelUpdatePoint = () => {
    setEditPointMode(false)
  };

  const onRightClick = (field) => {
    setSelectedField(field)
  }

  useEffect(() => {
    fieldsRef.current = selected
  }, [selected])

  useEffect(() => {
    setEditMode(false)
    setDrawing(false)
  }, [drawingPoint])

  useEffect(() => {
    geometriesRef.current = geometryAreas
  }, [geometryAreas])

  useEffect(() => {
    setEditMode(false)
    setDrawingPoint(false)
    setSelectedPoint(false)
    setDrawingArea(false)
    setDrawingLine(false)
    setEditGeometryMode(false)
    setSelectedFigureId(false)
    setEditGeometryMode(false)
    setTargetFigure(false)
  }, [drawing])

  useEffect(() => {
    setShowMeasureArea(false)
    setShowMeasureDistance(false)
    setSelectedPoint(false)
    setDrawingPoint(false)
    setEditPointMode(false)
    setEditMode(false)
    setDrawing(false)
    setEditPointMode(false)
  }, [drawingArea, drawingLine]);

  useEffect(() => {
    getPoints({ pageIndex: 0, pageSize: 10000 }, businessPartnerRef.current).then(result => {
      setPoints(result.rows.map(row => row.attributes))
    })
  }, [triggerPoints]);

  const onUpdatePoint = (point, newPosition) => {
    pointRef.current = {
      ...pointRef.current,
      latitude: newPosition.lat(),
      longitude: newPosition.lng(),
    };
  };

  const getPoints = async (pagination, businessPartnerId) => {
    const { pageIndex, pageSize } = pagination
    const params = { page: pageIndex + 1, limit: pageSize, business_partner_id: businessPartnerId }
    const res = await axiosInstance.get(routes.pois(), { params: params })

    return (
      { rows: res.data.data, pageCount: res.data.meta.total_pages, rowCount: res.data.meta.total_count }
    )
  }

  const fieldData = useMemo(() => fields.map(field => ({ value: field.id, label: field.name_with_hectare, hectare: field.hectare, name: field.name })), [fields])

  const getFields = async (pagination, businessPartnerId) => {
    const { pageIndex, pageSize } = pagination
    const params = { page: pageIndex + 1, limit: pageSize, business_partner_id: businessPartnerId }
    const res = await axiosInstance.get(
      routes.fields(),
      {
        params: params
      }
    )

    return (
      { rows: res.data.data, pageCount: res.data.meta.total_pages, rowCount: res.data.meta.total_count }
    )
  }

  useEffect(() => {
    if (!businessPartnerRef.current) return

    getFields({ pageIndex: 0, pageSize: 10000 }, businessPartnerRef.current).then(result => {
      setFields(result.rows.map(row => row.attributes))
    })
    setSelected([])
    setSelectedIds([])
    fieldRef.current.business_partner_id = businessPartnerRef.current
  }, [businessPartnerRef.current, trigger]);

  const onChange = (selectedOptions) => {
    setSelected(selectedOptions)
    setSelectedIds(selectedOptions.map(option => option.value))
  }

  const onSortEnd = ({ oldIndex, newIndex }) => {
    const newValue = arrayMove(selected, oldIndex, newIndex);
    setSelected(newValue)
  };

  const onCancelUpdateGeometry = () => {
    setEditGeometryMode(false)
    setTargetFigure(false)
  }

  const onSaveAreaDrawing = (attr) => {
    const newObject = {
      name: newAreaRef.current.name,
      geometry_type: drawingArea ? 'Polygon' : 'Polyline',
      geometry_points: geometryPoints
    };

    setGeometryAreas((prevAreas) => [...prevAreas, newObject]);
    newAreaRef.current = {}
    Toastr({ message: t('success'), options: { showDuration: 2000 } })
    setDrawingArea(false)
    setDrawingLine(false)
  };

  const onSaveDrawing = () => {
    newFieldRef.current.business_partner_id = businessPartnerRef.current
    axiosInstance.post(routes.fields(), newFieldRef.current).then(response => {
      setDrawing(false);
      setTrigger(Math.floor(Date.now() / 1000))
    }).catch(error => {
      console.error("Error saving field:", error);
    });
  };

  const onAddPoint = (point) => {
    const params = { ...point, business_partner_id: businessPartnerRef.current }
    axiosInstance.post(routes.pois(), params).then(response => {
      Toastr({ message: t('success'), options: { showDuration: 2000 } })
      setTriggerPoints(Math.floor(Date.now() / 1000))
      pointRef.current = response.data.data.attributes
      setDrawingPoint(false)
    }).catch(error => {
      console.error("Error saving field:", error);
    });
  };

  const deleteSelectedField = (id) => {
    axiosInstance.delete(routes.field(id))
      .then(resp => {
        Toastr({ message: t('success'), options: { showDuration: 2000 } })
        setTrigger(Math.floor(Date.now() / 1000))
        setDrawing(false);
        setEditMode(false);
      })
      .catch(error => {
        Toastr({
          type: 'error',
          message: error.response?.data?.error || t('critical_error')
        })
      })
  }

  const deleteSelectedGeometry = (id) => {
    setGeometryAreas((prevGeometryAreas) =>
      prevGeometryAreas.filter((area) => area !== targetFigure)
    );
    setTargetFigure(false)
    setDrawingArea(false)
    setDrawingLine(false)
    setEditGeometryMode(false)
  }

  const deleteSelectedPoint = (id) => {
    axiosInstance.delete(routes.poi(id))
      .then(resp => {
        Toastr({ message: t('success'), options: { showDuration: 2000 } })
        setTriggerPoints(Math.floor(Date.now() / 1000))
        setSelectedPoint()
        setEditPointMode(false)
      })
      .catch(error => {
        Toastr({
          type: 'error',
          message: error.response?.data?.error || t('critical_error')
        })
      })
  }

  const enableMeasureArea = () => {
    setShowMeasureArea(!showMeasureArea)
    setShowMeasureDistance(false)
  }
  const enableMeasureDistance = () => {
    setShowMeasureDistance(!showMeasureDistance)
    setShowMeasureArea(false)
  }
  const onCancelUpdateField = () => {
    setEditMode(false)
  };

  const onSavePoint = (id, attrs) => {
    let params = { ...pointRef.current, ...attrs }

    axiosInstance.put(routes.poi(pointRef.current.id), params).then(response => {
      Toastr({ message: t('success'), options: { showDuration: 2000 } })
      pointRef.curent = response.data.data.attributes
      setEditPointMode(false)
      setSelectedPoint(response.data.data.attributes)
      setTriggerPoints(Math.floor(Date.now() / 1000))
    }).catch(error => {
      console.error("Error saving field:", error);
      Toastr({
        type: 'error',
        message: error.response?.data?.error || t('critical_error')
      })
    });
  };

  const onUpdateGeometry = (id, attrs) => {
    setGeometryAreas((prevGeometryAreas) => {
      return prevGeometryAreas.map((area) => {
        if (area === targetFigure) {
          if (attrs) {
            const updatedArea = { ...area, ...attrs };
            setTargetFigure(updatedArea);
            return updatedArea;
          } else {
            targetFigure.geometry_points = geometryPoints
            return targetFigure
          }
        }
        return area;
      });
    });
    setEditGeometryMode(false)
  };

  const onUpdatePolygon = (polys) => {
    fieldRef.current.poly_points = polys;
    newFieldRef.current.poly_points = polys;
    setGeometryPoints(polys)
    setSelectedField((prev) => ({
      ...prev,
      poly_points: polys,
    }));
  };

  return (
    <>
      <div className="row">
        <div className="col-9">
          {!drawing && (
            <SortableSelect
              useDragHandle
              // react-sortable-hoc props:
              axis="xy"
              onSortEnd={onSortEnd}
              distance={4}
              // small fix for https://github.com/clauderic/react-sortable-hoc/pull/352:
              getHelperDimensions={({ node }) => node.getBoundingClientRect()}
              // react-select props:
              isMulti
              options={fieldData}
              value={selected}
              onChange={onChange}
              components={{
                // @ts-ignore We're failing to provide a required index prop to SortableElement
                MultiValue: SortableMultiValue,
                MultiValueLabel: SortableMultiValueLabel,
              }}
              closeMenuOnSelect={false}
            />
          )}

          <div className="d-flex justify-content-end mt-6 gap-3">
            <TableButton
              className={classNames(
                showMeasureDistance ? "btn-success" : "",
                "btn btn-light-warning",
                drawing && "disabled"
              )}
              title={t("measure_distance")}
              onClick={() => enableMeasureDistance()}
            />
            <TableButton
              className={classNames(
                showMeasureArea ? "btn-success" : "",
                "btn btn-light-warning",
                drawing && "disabled"
              )}
              title={t("measure_area")}
              onClick={() => enableMeasureArea()}
            />
            <Dropdown className="d-inline-flex">
              <Dropdown.Toggle className="btn-warning" id="dropdown-creation">
                <PlusIcon />
                {t('shared.add_to')}
              </Dropdown.Toggle>
              <Dropdown.Menu className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-warning fw-semibold py-4 w-250px fs-6">
                <div className="menu-item px-5">
                  <div className="menu-content text-muted pb-2 px-5 fs-7 text-uppercase">
                    {t("create_new")}
                  </div>
                </div>
                <div className="separator border-gray-200" />
                <Dropdown.Item className="menu-item px-5">
                  <span className="menu-link px-5" onClick={() => setDrawing(!drawing)}>
                    {t("field")}
                  </span>
                </Dropdown.Item>
                <Dropdown.Item className="menu-item px-5">
                  <span
                    onClick={() => setDrawingPoint(!drawingPoint)}
                    className="menu-link px-5"
                  >
                    {t("point")}
                  </span>
                </Dropdown.Item>
                <div className="separator border-gray-200" />
                <Dropdown.Item className="menu-item px-5">
                  <span
                    className="menu-link px-5"
                    onClick={() => enableDrawingArea()}
                  >
                    {t('area')}
                  </span>
                </Dropdown.Item>
                <Dropdown.Item className="menu-item px-5">
                  <span
                    className="menu-link px-5"
                    onClick={() => enableDrawingLine()}
                  >
                    {t('line')}
                  </span>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <Map
            geometryAreas={geometryAreas}
            businessPartnerRef={businessPartnerRef}
            drawPolygonEnabled={drawing}
            drawing={drawing}
            drawingArea={drawingArea}
            editGeometryMode={editGeometryMode}
            targetFigure={targetFigure}
            onGeometryClick={onGeometryClick}
            drawingLine={drawingLine}
            drawingPoint={drawingPoint}
            editMode={editMode}
            editPointMode={editPointMode}
            fieldId={selectedField?.id}
            fields={fields}
            onAddPoint={onAddPoint}
            onCancelUpdateField={onCancelUpdateField}
            onClick={onFieldClick}
            onPointClick={onPointClick}
            onRightClick={onRightClick}
            onUpdatePoint={onUpdatePoint}
            onUpdatePolygon={onUpdatePolygon}
            points={points}
            selectedFieldIds={selectedIds}
            selectedId={selectedField?.id}
            selectedPoint={selectedPoint}
            showAllFields={true}
            showMeasureArea={showMeasureArea}
            showMeasureDistance={showMeasureDistance}
            trigger={trigger}
            visibleFields
          />
        </div>
        <MapSidebar
          newAreaRef={newAreaRef}
          onSaveAreaDrawing={onSaveAreaDrawing}
          setEditGeometryMode={setEditGeometryMode}
          editGeometryMode={editGeometryMode}
          targetFigure={targetFigure}
          onCancelUpdateGeometry={onCancelUpdateGeometry}
          onUpdateGeometry={onUpdateGeometry}
          deleteSelectedGeometry={deleteSelectedGeometry}
          selectedFigureId={selectedFigureId}
          drawingArea={drawingArea}
          drawingLine={drawingLine}
          selectedPoint={selectedPoint}
          deleteSelectedPoint={deleteSelectedPoint}
          onSavePoint={onSavePoint}
          editPointMode={editPointMode}
          drawingPoint={drawingPoint}
          setEditPointMode={setEditPointMode}
          drawing={drawing}
          newFieldRef={newFieldRef}
          onSaveDrawing={onSaveDrawing}
          selectedField={selectedField}
          onUpdateField={onUpdateField}
          onCancelUpdatePoint={onCancelUpdatePoint}
          onCancelUpdateField={onCancelUpdateField}
          editMode={editMode}
          setEditMode={setEditMode}
          selected={selected}
          deleteSelectedField={deleteSelectedField}
        />
      </div>
    </>
  );

}


