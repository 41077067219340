import React, { useMemo, useState, useEffect } from 'react';
import BaseTable from './Table';
import { axiosInstance, routes } from '../../utils/api_base';
import { useTranslation } from 'react-i18next';

const OrderBookingsTable = ({ client_id }) => {
  const { t, i18n } = useTranslation();
  const [data, setData] = useState([]);

  const getOrderBookings = async () => {
    const res = await axiosInstance.get(routes.orders(), { params: { active_bookings: true } })

    return (res.data.data)
  }

  useEffect(() => {
    const fetchData = async () => {
      const orderData = await getOrderBookings();
      setData(orderData);
    };

    fetchData();
  }, []);

  const staffBookings = (bookings) => {
    return (
      <ul className='list-unstyled d-flex justify-content-between'>
        {bookings.map(booking => (
          <li className='p-3 rounded' key={booking.id} style={{ backgroundColor: booking.code_color }}>
            <a className="d-block" key={booking.id} href={booking.staff_position_link}>{booking.staff_name}</a>
            <small className="d-block text-wrap">
              {booking.service}
            </small>
          </li>
        ))}
      </ul>
    )
  };

  const columns = useMemo(
    () => [
      {
        id: 'order',
        header: t('order'),
        size: 10,
        minSize: 10,
        enableSorting: false,
        cell: ({ row }) => {
          const attributes = row?.original?.attributes

          return <a key={attributes?.id} href={attributes?.link}>{attributes?.name}</a>
        },
      },
      {
        id: 'staff_bookings',
        header: t('staff'),
        accessorFn: row => staffBookings(row?.attributes?.inprocess_bookings_data),
        enableSorting: false,
        cell: row => row.getValue()
      },
    ],
    [i18n.language]
  )

  return (
    <>
      <div className="d-flex flex-stack fs-4 py-3">
        <div className="fw-bold rotate collapsible" data-bs-toggle="collapse" href="#order-bookings" role="button">
          {t('order_bookings')}
          <span className="ms-2 rotate-180">
            <i className="ki-duotone ki-down fs-3" />
          </span>
        </div>
      </div>
      <div className="separator my-3"></div>
      <div id="order-bookings" className="collapse show">
        <BaseTable
          columns={columns}
          data={data}
          subscription={{
            channel: "OrderBookingsChannel",
            client_id: client_id
          }}
        />
      </div >
    </>
  )
};

export default OrderBookingsTable;
