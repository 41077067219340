import React from 'react';
import { useTranslation } from 'react-i18next';
import Toastr from '../../Toastr';
import { axiosInstance, routes } from '../../../utils/api_base';
import { Form, Field } from 'react-final-form'
import TableButton from '../../Table/TableButton';
import ErrorField from '../../Form/ErrorField';
import { mapValues } from 'lodash';
import { FORM_ERROR } from 'final-form'
import moment from "moment";
import DatePicker from '../../../components/DatePicker';


const CreateQualification = ({ onClose, setTrigger, staff }) => {
  const { t, i18n } = useTranslation();
  const currentDate = moment().toDate();

  const onSubmit = async values => {
    const data = {
      staff_id: staff.id,
      staff_qualification: {
        qualification: values.qualification,
        date: values.date,
      }
    };

    axiosInstance.post(routes.staffQualifications(), data)
      .then(response => {
        Toastr({
          title: 'Success!',
          message: response.data.message,
          options: { closeButton: true }
        })

        onClose()
        setTrigger(true)
      })
      .catch(error => {
        const errorData = error.response.data

        if (errorData instanceof Array) {
          return mapValues(errorData.error, (val) => (val.join(', ')))
        } else {
          return { [FORM_ERROR]: error.message }
        }
      })
  }


  return (
    <Form
      onSubmit={onSubmit}
      initialValues={{
        qualification: '',
        date: currentDate,
      }}
      render={({ submitError, handleSubmit, submitting, pristine }) => (
        <>
          <form onSubmit={handleSubmit}>
            <div className="row px-7 mb-3">
              <div className="col">
                <label>{t('qualification')}</label>
                <Field
                  name="qualification"
                  component="input"
                  type="text"
                  className="form-control"
                />
              </div>
            </div>
            <div className="px-7 mb-3">
              <label>{t('date')}</label>
              <Field
                name="date"
                render={({ input }) => (
                  <DatePicker
                    options={{
                      defaultDate: currentDate,
                      dateFormat: (i18n.language === "de" ? 'd.m.Y' : 'd-m-Y'),
                      onChange: input.onChange
                    }}
                  />
                )}
              />
              <ErrorField name="startDate" />
            </div>
            {submitError && <div className="form-error-message">{submitError}</div>}
            <div className="modal-footer">
              <TableButton
                className="btn btn-light btn-active-light-primary fw-semibold me-2 px-6"
                type="button"
                onClick={onClose}
                title={t('cancel')}
              />
              <TableButton
                className="btn btn-warning text-dark fw-semibold px-6"
                type="submit"
                title={t('create')}
                disabled={submitting || pristine}
              />
            </div>
          </form>
        </>
      )}
    />
  )
}

export default CreateQualification;
